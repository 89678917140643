// vars
$images: '../images/';
$fonts: '../fonts/';
$fa-font-path: '../fonts/fontawesome';

// fonts
$iconfont: 'FontAwesome';
$proximaNova: 'ProximaNova', sans-serif;

// bootstrap grid
$grid-columns: 12;
$grid-gutter-width: 34px;
$grid-gutter-width-mobile: $grid-gutter-width;

$grid-breakpoints: (
	xs: 0,
	sm: 375px,
	md: 768px,
	lg: 992px,
	xl: 1340px,
	xxl: 1640px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1302px
);

// colors
$white: #fff;
$black: #000;
$blue: #249dbd;
$light-blue: #158098;
$light-blue2: #bad9e0;
$xlight-gray: #f4f4f4;
$xlight-gray2: #f8f8f8;
$xlight-gray3: #efefef;
$xlight-gray4: #dedede;
$xlight-gray5: #ddd;
$dark-blue: #094d69;
$dark-blue2: #024d69;
$dark-blue3: #198099;

$gray: #4e5a5a;
$purple: #417a8f;
$yellow: #fddd19;
$deep-blue: #335aa6;
$brown: #9e6831;
$green: #5eb454;
$middle-gray: #6e7979;
$light-green: #b6e43b;
$middle-green: #3fd055;
$dark-green: #006f3f;
$light-gray: #bebebc;
$red: #d62c2d;
$orange: #fba614;
$dark-gray: #2f3939;
$blue-2: #2859bc;
$brown-2: $brown;
$gray-2: #6d7878;
$red-2: $red;
$green-light: #b7e53b;
$orange-2: #fca614;
$gray-3: #464646;
$blue-3: #198098;
$orange-3: $orange-2;
$dark-green-2: $dark-green;
$blue-4: #6ecde6;

// base vars
$text-color-base: $black;
$body-bg: $dark-blue2;
$font-family-base: $proximaNova;
$font-size-base: 1.8rem;
$font-size-secondary: 2.4rem;
$font-weight-base: 100;
$line-height-base: 1.6666666667;
$line-height-title: normal;
$links-color-base: inherit;
$headings-font-family: $proximaNova;
$headings-font-weight: 400;

$transition: all .3s ease;

$font-title: $headings-font-family;
$fs-h1-title: 6rem;
$fs-h2-title: 4.5rem;
$fs-h3-title: 2.3rem;
$fs-h4-title: 2rem;
$fs-h5-title: 1.8rem;
$fs-h6-title: 1.4rem;

// dev2
// dev3
// dev4
