/* zoom-menu  */
.zoom-menu {
	position: fixed;
	bottom: 4.5rem;
	right: 3.5rem;
	z-index: 20;
	background-color: $white;
	display: flex;
	border-radius: 99rem;
	padding: 0.2rem;

	@include media-breakpoint-down(md) {
		display: none;
	}

	.btn {
		width: 5.4rem;
		height: 5.4rem;
		border-radius: 50%;
		position: relative;
		text-indent: -9999px;
		overflow: hidden;
		padding: 0;
		margin: 0.3rem;

		&::after,
		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-bottom: 0.3rem solid;
			height: 0;
			width: 2.6rem;
			border-radius: 0.3rem;
		}

		&.zoom-out {
			&::after {
				display: none;
			}
		}

		&.zoom-in {
			&::after {
				transform: translate(-50%, -50%) rotate(90deg);
			}
		}
	}

	.menu-opened & {
		filter: blur(0.5rem);
	}
}
// dev2
// dev3
// dev4
