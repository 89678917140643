html {
	font-size: 10px;

	@include media-breakpoint-down(xl) {
		font-size: 9px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 8px;
	}

	@include media-breakpoint-down(md) {
		font-size: 10px;
	}

	@include media-breakpoint-down(sm) {
		font-size: 9px;
	}
}

body {
	margin: 0;
	min-width: 320px;
	background: $body-bg;
	color: $text-color-base;
	font: $font-weight-base #{$font-size-base}/$line-height-base $font-family-base;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	flex-direction: column;

	&.menu-opened {
		overflow: hidden;
	}
}

a {
	text-decoration: none;
	color: $links-color-base;
	transition: $transition;
}

a:hover {
	text-decoration: none;
	color: $dark-blue;
}

button,
input[type="search"],
input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: none;
	cursor: pointer;
	transition: $transition;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
	border: none;
	padding: 0;
}

.wrapper {
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
	display: flex;
	flex-direction: column;
}

#main {
	flex-grow: 1;

	.menu-opened & {
		filter: blur(0.5rem);
	}
}

h1, h2, h3, h4, h5, h6{
	font-family: $font-title;
	color: $gray;
	position: relative;
	line-height: $line-height-title;
}
h1 {
	font-size: $fs-h1-title;
}

h2 {
	font-size: $fs-h2-title;
}

h3 {
	font-size: $fs-h3-title;
}
h4 {
	font-size: $fs-h4-title;
}
h5 {
	font-size: $fs-h5-title;
}
h6 {
	font-size: $fs-h6-title;
}

p {
	margin: 0;
}

input[type="submit"],
input[type="button"],
button,
.button,
.btn {
	display: inline-block;
	border: none;
	color: $blue;
	transition: all .3s ease-in;
	padding: 1.2rem 2rem;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: capitalize;
}
input[type="submit"]:hover,
input[type="button"],
button:hover,
.button:hover,
.btn:hover {
	background-color: darken($xlight-gray, 10%);
	color: $white;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
textarea,
select {
	transition: all .3s ease-in;
}

*, *::before, *::after {
	box-sizing: border-box;
}

img {
	max-width: 100%;
	height: auto;
}

.col-md-20 {
	@include media-breakpoint-up(md) {
		flex: 0 0 20%;
		width: 20%;
	}
}

.row-16 {
	@include grid-gutter(16px);

	@include media-breakpoint-down(md) {
		@include grid-gutter(10px);
	}
}

.hidden-header-mobile {
	@include media-breakpoint-down(md) {
		#header {
			display: none;
		}
	}
}

.container {
	@include media-breakpoint-down(md) {
		padding-left: 20px;
		padding-right: 20px;
	}
}
// dev2
// dev3
// dev4
