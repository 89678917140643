@import "variables";

@font-face {
	font-family: '#{$icomoon-font-family}';
	src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?upzwh2') format('truetype'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?upzwh2') format('woff'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?upzwh2##{$icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icomoon-font-family}' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-reuse {
	&:before {
		content: $icon-reuse;
		color: #198098;
	}
}

.icon-fix {
	&:before {
		content: $icon-fix;
		color: #198098;
	}
}

.icon-upload {
	&:before {
		content: $icon-upload;
		color: #249dbd;
	}
}

.icon-layers .path1 {
	&:before {
		content: $icon-layers-path1;
		color: rgb(255, 255, 255);
	}
}

.icon-layers .path2 {
	&:before {
		content: $icon-layers-path2;
		margin-left: -0.74609375em;
		color: rgb(36, 157, 189);
	}
}

.icon-layers .path3 {
	&:before {
		content: $icon-layers-path3;
		margin-left: -0.74609375em;
		color: rgb(36, 157, 189);
	}
}

.icon-arrow-down {
	&:before {
		content: $icon-arrow-down;
		color: #094d69;
	}
}

.icon-home {
	&:before {
		content: $icon-home;
		color: #094d69;
	}
}

.icon-target {
	&:before {
		content: $icon-target;
		color: #249dbd;
	}
}

.icon-world {
	&:before {
		content: $icon-world;
		color: #094d69;
	}
}

.icon-question-mark {
	&:before {
		content: $icon-question-mark;
		color: #249dbd;
	}
}

.icon-gear {
	&:before {
		content: $icon-gear;
		color: #094d69;
	}
}

.icon-map-pin {
	&:before {
		content: $icon-map-pin;
		color: #094d69;
	}
}

.icon-gasstaions .path1 {
	&:before {
		content: $icon-gasstaions-path1;
		color: rgb(158, 104, 49);
	}
}

.icon-gasstaions .path2 {
	&:before {
		content: $icon-gasstaions-path2;
		margin-left: -1.6318359375em;
		color: rgb(255, 255, 255);
	}
}

.icon-gasstaions .path3 {
	&:before {
		content: $icon-gasstaions-path3;
		margin-left: -1.6318359375em;
		color: rgb(40, 89, 188);
	}
}

.icon-gasstaions .path4 {
	&:before {
		content: $icon-gasstaions-path4;
		margin-left: -1.6318359375em;
		color: rgb(109, 120, 120);
	}
}

.icon-gasstaions .path5 {
	&:before {
		content: $icon-gasstaions-path5;
		margin-left: -1.6318359375em;
		color: rgb(63, 208, 85);
	}
}

.icon-gasstaions .path6 {
	&:before {
		content: $icon-gasstaions-path6;
		margin-left: -1.6318359375em;
		color: rgb(255, 255, 255);
	}
}

.icon-gasstaions .path7 {
	&:before {
		content: $icon-gasstaions-path7;
		margin-left: -1.6318359375em;
		color: rgb(253, 221, 25);
	}
}

.icon-search {
	&:before {
		content: $icon-search;
		color: #249dbd;
	}
}