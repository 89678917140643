/* details */
.details {
	padding-bottom: 3rem;

	@include media-breakpoint-down(md) {
		margin-left: -20px;
		margin-right: -20px;
	}

	.block {
		display: flex;
		position: relative;

		@include media-breakpoint-down(md) {
			display: block;
			max-width: 420px;
			margin-left: auto;
			margin-right: auto;
		}

		& + .block {
			margin-top: 31px;

			@include media-breakpoint-down(md) {
				margin-top: 0;
			}
		}

		.sub-bg {
			position: absolute;
			z-index: -1;
			left: 2px;
			top: 5px;
			bottom: 5px;
			right: 2px;
			transform: skewY(-21.5deg);
			transform-origin: 0 0;
			border-radius: 10px;

			@include media-breakpoint-down(xl) {
				transform: skewY(-20deg);
			}

			@include media-breakpoint-down(lg) {
				transform: skewY(-21.3deg);
				//right: 10px;
			}

			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		.cell {
			min-height: 22.2rem;
			border-radius: 10px;
			flex-shrink: 0;
			width: 100%;
			position: relative;
			z-index: 1;

			@include media-breakpoint-down(lg) {
				min-height: 23.6rem;
			}

			@include media-breakpoint-down(md) {
				min-height: 1px;
			}
			
			.bg {
				position: absolute;
				z-index: -1;
				top: 0;
				bottom: 0;

				@include media-breakpoint-down(md) {
					left: 0;
					right: 0;
				}
			}
		}

		&-1 {
			.cell {
				&-1 {
					width: 60%;

					@include media-breakpoint-down(md) {
						width: auto;
						padding: 0 0 40px;
					}
					
					.bg-1 {
						left: 0;
						right: 50px;
						border-radius: 10px 0 0 10px;

						@include media-breakpoint-down(md) {
							right: 0;
							bottom: 70px;
							border-radius: 20px 20px 0 0;
						}
					}

					.bg-2 {
						right: 0;
						width: 50px;
						mask-image: url('#{$images}bg-cell-01.svg');
						mask-position: 100% 50%;
						mask-size: auto 100%;
						mask-repeat: no-repeat;

						@include media-breakpoint-down(md) {
							width: auto;
							height: 70px;
							bottom: 0;
							top: auto;
							mask-image: url('#{$images}bg-cell-03.svg');
							mask-position: 50% 100%;
							mask-size: 100% auto;
						}
					}
				}

				&-2 {
					width: 40%;

					@include media-breakpoint-down(md) {
						width: auto;
					}

					.headline {
						@include media-breakpoint-down(md) {
							top: 57px;
						}
					}

					.bg-1 {
						right: 0;
						left: 40px;
						border-radius: 0 10px 10px 0;

						@include media-breakpoint-down(md) {
							left: 0;
							top: 30px;
							right: 0;
							bottom: 0;
							border-radius: 0;
						}
					}

					.bg-2 {
						left: -15px;
						width: 55px;
						mask-image: url('#{$images}bg-cell-02.svg');
						mask-position: 0 50%;
						mask-size: auto 100%;
						mask-repeat: no-repeat;

						@include media-breakpoint-down(md) {
							display: none;
						}
					}

					.bg-3 {
						left: 0;
						top: -30px;
						bottom: auto;
						right: 0;
						height: 60px;
						mask-image: url('#{$images}bg-cell-04.svg');
						mask-position: 50% 0;
						mask-size: 100% auto;
					}
				}
			}
		}

		&-2 {
			.cell {
				@include media-breakpoint-down(md) {
					background: none !important;
					padding-bottom: 40px;
				}

				.headline {
					left: -1.6rem;

					@include media-breakpoint-down(md) {
						left: 0;
					}
				}

				.bg-1 {
					@include media-breakpoint-down(md) {
						right: 0;
						left: 0;
						top: 0;
						bottom: 70px;
					}
				}

				.bg-2 {
					@include media-breakpoint-down(md) {
						height: 70px;
						bottom: 0;
						top: auto;
						mask-image: url('#{$images}bg-cell-03.svg');
						mask-position: 50% 100%;
						mask-size: 100% auto;
						mask-repeat: no-repeat;
					}
				}
			}
		}

		&-3 {
			.cell {
				&-1 {
					width: 28%;
					z-index: 5;

					@include media-breakpoint-down(md) {
						width: auto;
					}

					.headline {
						left: -1.6rem;
						right: auto;
						white-space: nowrap;

						@include media-breakpoint-down(md) {
							left: 0;
							top: 50px;
						}
					}
					
					.bg-1 {
						left: 0;
						right: 50px;
						border-radius: 10px 0 0 10px;

						@include media-breakpoint-down(md) {
							display: none;
						}
					}

					.bg-2 {
						right: 0;
						width: 50px;
						mask-image: url('#{$images}bg-cell-01.svg');
						mask-position: 100% 50%;
						mask-size: auto 100%;
						mask-repeat: no-repeat;

						@include media-breakpoint-down(md) {
							display: none;
						}
					}
				}

				&-2 {
					width: 72%;

					@include media-breakpoint-down(md) {
						width: auto;
						padding-top: 118px;
						padding-bottom: 60px;
					}

					.bg-1 {
						right: 40px;
						left: 40px;

						@include media-breakpoint-down(md) {
							left: 0;
							right: 0;
							top: 30px;
							bottom: 70px;
						}
					}

					.bg-2 {
						left: -15px;
						width: 55px;
						mask-image: url('#{$images}bg-cell-02.svg');
						mask-position: 0 50%;
						mask-size: auto 100%;
						mask-repeat: no-repeat;

						@include media-breakpoint-down(md) {
							left: 0;
							top: -30px;
							bottom: auto;
							right: 0;
							height: 60px;
							mask-image: url('#{$images}bg-cell-04.svg');
							mask-position: 50% 0;
							mask-size: 100% auto;
							width: auto;
						}
					}

					.bg-3 {
						right: -10px;
						width: 50px;
						mask-image: url('#{$images}bg-cell-01.svg');
						mask-position: 100% 50%;
						mask-size: auto 100%;
						mask-repeat: no-repeat;

						@include media-breakpoint-down(md) {
							height: 70px;
							bottom: 0;
							right: 0;
							left: 0;
							width: auto;
							top: auto;
							mask-image: url('#{$images}bg-cell-03.svg');
							mask-position: 50% 100%;
							mask-size: 100% auto;
							mask-repeat: no-repeat;
						}
					}
				}
			}
		}
	}

	// item-info
	.item-info {
		margin: 0;
		padding: 1.1rem;
		display: flex;
		color: $white;

		@include media-breakpoint-down(md) {
			padding: 28px 20px 20px;
			//flex-direction: column;
			flex-wrap: wrap;
		}

		.img {
			flex-shrink: 0;
			width: 13.1rem;
			margin: 0 1.3rem 0 0;
			position: relative;
			align-self: flex-start;

			@include media-breakpoint-down(md) {
				width: 162px;
				margin: 0;
			}

			@include media-breakpoint-down(sm) {
				width: 130px;
			}

			> img {
				display: block;
				width: 100%;
				height: auto;
				border-radius: 5px;
			}

			.lbl {
				position: absolute;
				bottom: 10px;
				right: 10px;
				max-width: 43px;
				z-index: 5;

				img {
					max-width: 100%;
					display: block;
					height: auto;
				}
			}
		}

		h3 {
			font-size: 2.2rem;
			line-height: 1;
			font-weight: bold;
			color: inherit;

			@include media-breakpoint-down(md) {
				font-size: 32px;
				margin-bottom: 19px;
				flex: 0 0 100%;
				max-width: 100%;
				padding-right: 70px;
				min-height: 64px;
			}
		}

		figcaption {
			flex-grow: 1;
			width: 1%;
			min-width: 0;
			display: flex;
			flex-direction: column;
			padding-right: 20px;

			@include media-breakpoint-down(md) {
				//width: auto;
				//flex: 0 0 auto;
				//order: -1;
				padding-left: 16px;
				padding-right: 0;
			}

			h3 {
				@include media-breakpoint-down(md) {
					display: none;
				}
			}

			p {
				margin: auto 0 8px;
				font-weight: 600;
				font-size: 1.8rem;
				line-height: 1.112;

				@include media-breakpoint-down(md) {
					margin-top: 0;
					font-size: 1.8rem;
					line-height: 20px;
				}
			}

			.btn-location {
				margin-top: 12px;
			}
		}
	}

	// headline
	.headline {
		position: absolute;
		font-size: 1.8rem;
		line-height: 2rem;
		padding: 5px 9px;
		background: $white;
		border-radius: 99px;
		text-transform: uppercase;
		top: 2rem;
		right: -1.6rem;
		font-weight: bold;
		text-align: center;

		@include media-breakpoint-down(md) {
			border-radius: 0 99px 99px 0;
			left: 0;
			right: auto;
			padding: 15px 18px 15px 11px;
		}
	}

	// plus
	.plus {
		position: absolute;
		width: 4.3rem;
		height: 4.3rem;
		border-radius: 50%;
		border: 3px solid;
		top: 50%;
		right: 0;
		transform: translate(50%, -50%);
		text-decoration: none !important;

		i:nth-child(2),
		i:nth-child(1) {
			position: absolute;
			content: '';
			width: 18px;
			height: 3px;
			left: 50%;
			top: 50%;
			margin: -1.5px 0 0 -9px;
			border-radius: 4px;
		}

		i:nth-child(2) {
			transform: rotate(90deg);
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	// category
	.category {
		padding: 2.5rem 2rem 1rem;
		text-align: center;
		position: relative;

		@include media-breakpoint-down(md) {
			position: relative;
			padding: 13rem 20px 10px;
			text-align: left;
			font-size: 16px;
			line-height: 20px;
		}

		.ico {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 120px;
			height: 120px;
			border-radius: 50%;
			font-size: 7rem;
			margin: 0 auto 1.2rem;
			position: relative;
			transition: all 0.3s ease-out;

			@include media-breakpoint-down(lg) {
				width: 110px;
				height: 110px;
			}

			@include media-breakpoint-down(md) {
				width: 98px;
				height: 98px;
				font-size: 5.2rem;
				position: absolute;
				top: 14px;
				right: 24px;
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 80%;
			}

			span {
				&::before {
					display: block;
					margin: 0;
					color: inherit;
				}
			}
		}

		h3 {
			display: block;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 2.2rem;
			color: inherit;
			margin: 0;
			line-height: 1.2;
			transition: all 0.3s ease-out;

			@include media-breakpoint-down(md) {
				font-size: 2.4rem;
				text-transform: none;
				margin: 0 0 10px;
			}
		}

		.text {
			font-size: 1.4rem;
			line-height: 1.3;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: all 0.3s ease-out;
			opacity: 0;
			visibility: hidden;
			padding: 4rem 3rem 0;
			cursor: default;

			@include media-breakpoint-down(md) {
				font-size: 16px;
				line-height: 20px;
				display: block;
				position: static;
				opacity: 1;
				visibility: visible;
				padding: 0;
			}
		}

		&.hover {
			@include media-breakpoint-up(md) {
				h3,
				.ico {
					opacity: 0.2;
					filter: blur(5px);
				}

				.text {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	// process
	.process {
		margin: 0 -18px;
		padding: 5.8rem 0 0.8rem;
		list-style: none;
		display: flex;
		justify-content: center;
		text-align: center;

		@include media-breakpoint-down(md) {
			display: block;
			margin: 0;
			padding: 80px 20px 2rem;
			text-align: left;
		}

		> li {
			flex-shrink: 0;
			width: 50%;
			padding: 0 30px;
			position: relative;
			display: flex;
			flex-direction: column;

			@include media-breakpoint-down(md) {
				width: auto;
				padding: 0;
			}

			& + li {
				@include media-breakpoint-down(md) {
					padding-top: 3.2rem;
				}

				.arrow {
					position: absolute;
					width: 54px;
					height: 54px;
					top: 3.8rem;
					left: 0;
					transform: translate(-50%, 0);

					mask-image: url('#{$images}arrow-01.svg');
					mask-position: 50% 50%;
					mask-size: 54px 54px;
					mask-repeat: no-repeat;

					@include media-breakpoint-down(md) {
						display: none;
					}
				}
			}

			figure {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 0;
				transition: all 0.3s ease-out;

				@include media-breakpoint-down(md) {
					display: block;
				}

				.img {
					margin: 0 0 1rem;
					position: relative;

					.plus {
						margin-top: 8px;
					}

					img {
						display: inline-block;
						vertical-align: top;
						max-width: 100%;
						height: auto;
					}
				}

				figcaption {
					color: inherit;
					
					h3 {
						font-weight: bold;
						line-height: 1.2;
						color: inherit;
						font-size: inherit;

						@include media-breakpoint-down(md) {
							font-size: 24px;
							padding-top: 2px;
						}

						br {
							@include media-breakpoint-down(md) {
								display: none;
							}
						}
					}

					p {
						font-size: 16px;
						line-height: 20px;
						margin: 0;
					}
				}
			}

			.text {
				font-size: 1.4rem;
				line-height: 1.2857;
				padding: 0 6rem;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transition: all 0.3s ease-out;
				opacity: 0;
				visibility: hidden;

				@include media-breakpoint-down(md) {
					padding: 0 0 20px;
					font-size: 16px;
					line-height: 20px;
					position: static;
					opacity: 1;
					visibility: visible;
					display: block;
				}

				p {
					margin: 0;
				}
			}

			&.hover {
				@include media-breakpoint-up(md) {
					> figure {
						opacity: 0.2;
						filter: blur(5px);
					}
	
					.text {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	// transform
	.transform-items {
		margin: 0;
		padding: 6rem 2rem 3rem;
		list-style: none;
		display: flex;
		justify-content: center;

		@include media-breakpoint-down(md) {
			display: block;
			padding-top: 0;
			padding-bottom: 18px;
		}

		> li {
			flex-shrink: 0;
			width: 50%;
			padding: 0 15px;

			@include media-breakpoint-down(md) {
				width: auto;
				padding: 0;
			}
		}
	}

	.transform {
		margin: 0;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		@include media-breakpoint-down(md) {
			text-align: left;
			display: block;
		}

		.img {
			margin: 0 0 1rem;
			position: relative;
			transition: all 0.3s ease-out;

			img {
				display: inline-block;
				vertical-align: top;
				max-width: 100px;
				height: auto;

				@include media-breakpoint-down(md) {
					min-width: 163px;
					max-width: 100%;
				}
			}

			.plus {
				margin-top: 10px;
			}
		}

		h3 {
			color: inherit;
			font-weight: bold;
			line-height: 1.2;
			font-size: inherit;
			margin: 0;
			font-family: inherit;
			transition: all 0.3s ease-out;

			@include media-breakpoint-down(md) {
				font-size: 2.4rem;
				margin-bottom: 6px;
			}
		}

		.text {
			font-size: 1.4rem;
			line-height: 1.2857;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: all 0.3s ease-out;
			opacity: 0;
			visibility: hidden;

			@include media-breakpoint-down(md) {
				font-size: 16px;
				line-height: 20px;
				display: block;
				position: static;
				opacity: 1;
				visibility: visible;
			}
		}

		p {
			margin: 0;
		}

		&.hover {
			@include media-breakpoint-up(md) {
				h3,
				.img {
					opacity: 0.2;
					filter: blur(5px);
				}

				.text {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	// type2
	&.type2 {
		.item-info {
			align-items: center;
			padding: 2.8rem;
			color: inherit;
			margin-top: 6px;

			@include media-breakpoint-down(md) {
				display: block;
				padding: 70px 20px 10px;
			}

			.img {
				width: 20.9rem;
				margin-right: 1.5rem;
				padding-top: 10px;

				@include media-breakpoint-down(md) {
					width: 196px;
					padding-top: 0;
					margin: 0;
				}
			}

			figcaption {
				@include media-breakpoint-down(md) {
					width: auto;
					padding-left: 0;
					padding-right: 0;
				}

				h3 {
					font-size: 2.6rem;
					line-height: 1.1;

					@include media-breakpoint-down(md) {
						display: block;
						padding-right: 0;
					}
				}
			}
		}

		.block-1 {
			.cell-1 {
				width: 90%;
				display: flex;
				align-items: center;

				@include media-breakpoint-down(md) {
					width: auto;
				}
			}

			.cell-2 {
				width: 10%;

				@include media-breakpoint-down(md) {
					width: auto;
					min-height: 40px;
				}
			}

			.headline {
				right: auto;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 10;
				background: $blue-3;
				top: 0;
				padding-left: 20px;
				padding-right: 20px;

				@include media-breakpoint-down(md) {
					transform: none;
					left: 0;
					top: 25px;
					padding-left: 13px;
				}
			}
		}

		.block-2 {
			.headline {
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				right: auto;
				padding-left: 40px;
				padding-right: 40px;

				@include media-breakpoint-down(md) {
					left: 0;
					top: 58px;
					transform: none;
					padding-left: 13px;
					padding-right: 20px;
				}
			}

			.item-info {
				padding: 4.6rem 4rem;

				@include media-breakpoint-down(lg) {
					padding: 3.6rem 4rem;
				}

				@include media-breakpoint-down(md) {
					position: relative;
					padding: 123px 20px 15px;
					margin: 0;
				}

				figcaption {
					order: -1;
					width: 50%;
					padding-left: 2rem;
					padding-right: 0;

					@include media-breakpoint-down(md)  {
						padding-left: 0;
						width: auto;
					}

					h3 {
						font-size: 1.8rem;
						line-height: 1.25;
					}
				}

				.img {
					width: 50%;
					margin: 0;
					text-align: center;
					padding-top: 0;

					@include media-breakpoint-down(md)  {
						position: absolute;
						top: -6px;
						right: 70px;
						width: auto;
					}

					img {
						width: auto;
						display: inline-block;
						vertical-align: top;
					}
				}
			}
		}

		.block-3 {
			.cell-1 {
				width: 13%;

				@include media-breakpoint-down(md) {
					width: auto;
				}
			}

			.cell-2 {
				width: 87%;

				@include media-breakpoint-down(md) {
					width: auto;
				}
			}

			.headline {
				right: auto;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 10;
				background: $blue-3;
				top: 0;
				padding-left: 20px;
				padding-right: 20px;

				@include media-breakpoint-down(md) {
					left: 0;
					top: 88px;
					transform: none;
					padding-left: 13px;
					padding-right: 20px;
				}
			}

			.item-info {
				padding: 5.5rem 3rem 4rem 5px;

				@include media-breakpoint-down(md) {
					position: relative;
					padding: 38px 20px 8px;
					margin: 0;
				}

				figcaption {
					width: 50%;
					padding-left: 0;
					padding-right: 0;

					@include media-breakpoint-down(md) {
						width: auto;
						max-width: 242px;
					}

					h3 {
						font-size: 1.8rem;
						line-height: 1.25;

						@include media-breakpoint-down(md) {
							margin-bottom: 0;
						}
					}
				}

				.img {
					width: 50%;
					margin: 0;
					text-align: center;
					padding-top: 0;
					padding-left: 10px;

					@include media-breakpoint-down(md)  {
						position: absolute;
						top: -87px;
						right: 40px;
						width: auto;
					}

					img {
						width: auto;
						display: inline-block;
						vertical-align: top;
					}
				}
			}
		}
	}
}