$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon/" !default;

$icon-reuse: "\e90f";
$icon-fix: "\e910";
$icon-upload: "\e914";
$icon-layers-path1: "\e911";
$icon-layers-path2: "\e912";
$icon-layers-path3: "\e913";
$icon-arrow-down: "\e908";
$icon-home: "\e909";
$icon-target: "\e90a";
$icon-world: "\e90b";
$icon-question-mark: "\e90c";
$icon-gear: "\e90d";
$icon-map-pin: "\e90e";
$icon-gasstaions-path1: "\e900";
$icon-gasstaions-path2: "\e901";
$icon-gasstaions-path3: "\e902";
$icon-gasstaions-path4: "\e903";
$icon-gasstaions-path5: "\e904";
$icon-gasstaions-path6: "\e905";
$icon-gasstaions-path7: "\e906";
$icon-search: "\e907";
