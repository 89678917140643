@charset "UTF-8";
/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
	--bs-blue: #249dbd;
	--bs-indigo: #6610f2;
	--bs-purple: #417a8f;
	--bs-pink: #d63384;
	--bs-red: #d62c2d;
	--bs-orange: #fba614;
	--bs-yellow: #fddd19;
	--bs-green: #5eb454;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-primary: #249dbd;
	--bs-secondary: #6c757d;
	--bs-success: #5eb454;
	--bs-info: #0dcaf0;
	--bs-warning: #fddd19;
	--bs-danger: #d62c2d;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;
	--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: smooth;
	}
}

body {
	margin: 0;
	font-family: "ProximaNova", sans-serif;
	font-size: calc(1.305rem + 0.66vw);
	font-weight: 100;
	line-height: 1.6666666667;
	color: #212529;
	background-color: #024d69;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media (min-width: 1200px) {
	body {
		font-size: 1.8rem;
	}
}

hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: 0.25;
}

hr:not([size]) {
	height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-family: "ProximaNova", sans-serif;
	font-weight: 400;
	line-height: 1.2;
}

h1, .h1 {
	font-size: calc(1.575rem + 3.9vw);
}

@media (min-width: 1200px) {
	h1, .h1 {
		font-size: 4.5rem;
	}
}

h2, .h2 {
	font-size: calc(1.485rem + 2.82vw);
}

@media (min-width: 1200px) {
	h2, .h2 {
		font-size: 3.6rem;
	}
}

h3, .h3 {
	font-size: calc(1.44rem + 2.28vw);
}

@media (min-width: 1200px) {
	h3, .h3 {
		font-size: 3.15rem;
	}
}

h4, .h4 {
	font-size: calc(1.395rem + 1.74vw);
}

@media (min-width: 1200px) {
	h4, .h4 {
		font-size: 2.7rem;
	}
}

h5, .h5 {
	font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
	h5, .h5 {
		font-size: 2.25rem;
	}
}

h6, .h6 {
	font-size: calc(1.305rem + 0.66vw);
}

@media (min-width: 1200px) {
	h6, .h6 {
		font-size: 1.8rem;
	}
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
	text-decoration: underline dotted;
	cursor: help;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol,
ul {
	padding-left: 2rem;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: .5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

b,
strong {
	font-weight: bolder;
}

small, .small {
	font-size: 0.875em;
}

mark, .mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

sub,
sup {
	position: relative;
	font-size: 0.75em;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

a {
	color: #249dbd;
	text-decoration: underline;
}

a:hover {
	color: #1d7e97;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

pre,
code,
kbd,
samp {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr /* rtl:ignore */;
	unicode-bidi: bidi-override;
}

pre {
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: 0.875em;
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

code {
	font-size: 0.875em;
	color: #d63384;
	word-wrap: break-word;
}

a > code {
	color: inherit;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 0.875em;
	color: #fff;
	background-color: #212529;
	border-radius: 0.2rem;
}

kbd kbd {
	padding: 0;
	font-size: 1em;
	font-weight: 700;
}

figure {
	margin: 0 0 1rem;
}

img,
svg {
	vertical-align: middle;
}

table {
	caption-side: bottom;
	border-collapse: collapse;
}

caption {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #6c757d;
	text-align: left;
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

label {
	display: inline-block;
}

button {
	border-radius: 0;
}

button:focus:not(:focus-visible) {
	outline: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
select {
	text-transform: none;
}

[role="button"] {
	cursor: pointer;
}

select {
	word-wrap: normal;
}

select:disabled {
	opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
	display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

textarea {
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit;
}

@media (min-width: 1200px) {
	legend {
		font-size: 1.5rem;
	}
}

legend + * {
	clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
	padding: 0;
}

::-webkit-inner-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
	padding: 0;
}

::file-selector-button {
	font: inherit;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

iframe {
	border: 0;
}

summary {
	display: list-item;
	cursor: pointer;
}

progress {
	vertical-align: baseline;
}

[hidden] {
	display: none !important;
}

.lead {
	font-size: calc(1.35rem + 1.2vw);
	font-weight: 300;
}

@media (min-width: 1200px) {
	.lead {
		font-size: 2.25rem;
	}
}

.display-1 {
	font-size: calc(1.625rem + 4.5vw);
	font-weight: 300;
	line-height: 1.2;
}

@media (min-width: 1200px) {
	.display-1 {
		font-size: 5rem;
	}
}

.display-2 {
	font-size: calc(1.575rem + 3.9vw);
	font-weight: 300;
	line-height: 1.2;
}

@media (min-width: 1200px) {
	.display-2 {
		font-size: 4.5rem;
	}
}

.display-3 {
	font-size: calc(1.525rem + 3.3vw);
	font-weight: 300;
	line-height: 1.2;
}

@media (min-width: 1200px) {
	.display-3 {
		font-size: 4rem;
	}
}

.display-4 {
	font-size: calc(1.475rem + 2.7vw);
	font-weight: 300;
	line-height: 1.2;
}

@media (min-width: 1200px) {
	.display-4 {
		font-size: 3.5rem;
	}
}

.display-5 {
	font-size: calc(1.425rem + 2.1vw);
	font-weight: 300;
	line-height: 1.2;
}

@media (min-width: 1200px) {
	.display-5 {
		font-size: 3rem;
	}
}

.display-6 {
	font-size: calc(1.375rem + 1.5vw);
	font-weight: 300;
	line-height: 1.2;
}

@media (min-width: 1200px) {
	.display-6 {
		font-size: 2.5rem;
	}
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
}

.list-inline-item:not(:last-child) {
	margin-right: 0.5rem;
}

.initialism {
	font-size: 0.875em;
	text-transform: uppercase;
}

.blockquote {
	margin-bottom: 1rem;
	font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
	.blockquote {
		font-size: 2.25rem;
	}
}

.blockquote > :last-child {
	margin-bottom: 0;
}

.blockquote-footer {
	margin-top: -1rem;
	margin-bottom: 1rem;
	font-size: 0.875em;
	color: #6c757d;
}

.blockquote-footer::before {
	content: "\2014\00A0";
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.img-thumbnail {
	padding: 0.25rem;
	background-color: #024d69;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	max-width: 100%;
	height: auto;
}

.figure {
	display: inline-block;
}

.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}

.figure-caption {
	font-size: 0.875em;
	color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
	width: 100%;
	padding-right: var(--bs-gutter-x, 17px);
	padding-left: var(--bs-gutter-x, 17px);
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 375px) {
	.container, .container-sm {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container, .container-sm, .container-md {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container, .container-sm, .container-md, .container-lg {
		max-width: 960px;
	}
}

@media (min-width: 1340px) {
	.container, .container-sm, .container-md, .container-lg, .container-xl {
		max-width: 1302px;
	}
}

.row {
	--bs-gutter-x: 34px;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) / -2);
	margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) / 2);
	padding-left: calc(var(--bs-gutter-x) / 2);
	margin-top: var(--bs-gutter-y);
}

.col {
	flex: 1 0 0%;
}

.row-cols-auto > * {
	flex: 0 0 auto;
	width: auto;
}

.row-cols-1 > * {
	flex: 0 0 auto;
	width: 100%;
}

.row-cols-2 > * {
	flex: 0 0 auto;
	width: 50%;
}

.row-cols-3 > * {
	flex: 0 0 auto;
	width: 33.3333333333%;
}

.row-cols-4 > * {
	flex: 0 0 auto;
	width: 25%;
}

.row-cols-5 > * {
	flex: 0 0 auto;
	width: 20%;
}

.row-cols-6 > * {
	flex: 0 0 auto;
	width: 16.6666666667%;
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
}

.col-1 {
	flex: 0 0 auto;
	width: 8.3333333333%;
}

.col-2 {
	flex: 0 0 auto;
	width: 16.6666666667%;
}

.col-3 {
	flex: 0 0 auto;
	width: 25%;
}

.col-4 {
	flex: 0 0 auto;
	width: 33.3333333333%;
}

.col-5 {
	flex: 0 0 auto;
	width: 41.6666666667%;
}

.col-6 {
	flex: 0 0 auto;
	width: 50%;
}

.col-7 {
	flex: 0 0 auto;
	width: 58.3333333333%;
}

.col-8 {
	flex: 0 0 auto;
	width: 66.6666666667%;
}

.col-9 {
	flex: 0 0 auto;
	width: 75%;
}

.col-10 {
	flex: 0 0 auto;
	width: 83.3333333333%;
}

.col-11 {
	flex: 0 0 auto;
	width: 91.6666666667%;
}

.col-12 {
	flex: 0 0 auto;
	width: 100%;
}

.offset-1 {
	margin-left: 8.3333333333%;
}

.offset-2 {
	margin-left: 16.6666666667%;
}

.offset-3 {
	margin-left: 25%;
}

.offset-4 {
	margin-left: 33.3333333333%;
}

.offset-5 {
	margin-left: 41.6666666667%;
}

.offset-6 {
	margin-left: 50%;
}

.offset-7 {
	margin-left: 58.3333333333%;
}

.offset-8 {
	margin-left: 66.6666666667%;
}

.offset-9 {
	margin-left: 75%;
}

.offset-10 {
	margin-left: 83.3333333333%;
}

.offset-11 {
	margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
	--bs-gutter-x: 0;
}

.g-0,
.gy-0 {
	--bs-gutter-y: 0;
}

.g-1,
.gx-1 {
	--bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
	--bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
	--bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
	--bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
	--bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
	--bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
	--bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
	--bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
	--bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
	--bs-gutter-y: 3rem;
}

@media (min-width: 375px) {
	.col-sm {
		flex: 1 0 0%;
	}
	.row-cols-sm-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-sm-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-sm-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-sm-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-sm-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-sm-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-sm-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-sm-1 {
		flex: 0 0 auto;
		width: 8.3333333333%;
	}
	.col-sm-2 {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-sm-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-sm-4 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.col-sm-5 {
		flex: 0 0 auto;
		width: 41.6666666667%;
	}
	.col-sm-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-sm-7 {
		flex: 0 0 auto;
		width: 58.3333333333%;
	}
	.col-sm-8 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}
	.col-sm-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-sm-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}
	.col-sm-11 {
		flex: 0 0 auto;
		width: 91.6666666667%;
	}
	.col-sm-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-sm-0 {
		margin-left: 0;
	}
	.offset-sm-1 {
		margin-left: 8.3333333333%;
	}
	.offset-sm-2 {
		margin-left: 16.6666666667%;
	}
	.offset-sm-3 {
		margin-left: 25%;
	}
	.offset-sm-4 {
		margin-left: 33.3333333333%;
	}
	.offset-sm-5 {
		margin-left: 41.6666666667%;
	}
	.offset-sm-6 {
		margin-left: 50%;
	}
	.offset-sm-7 {
		margin-left: 58.3333333333%;
	}
	.offset-sm-8 {
		margin-left: 66.6666666667%;
	}
	.offset-sm-9 {
		margin-left: 75%;
	}
	.offset-sm-10 {
		margin-left: 83.3333333333%;
	}
	.offset-sm-11 {
		margin-left: 91.6666666667%;
	}
	.g-sm-0,
	.gx-sm-0 {
		--bs-gutter-x: 0;
	}
	.g-sm-0,
	.gy-sm-0 {
		--bs-gutter-y: 0;
	}
	.g-sm-1,
	.gx-sm-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-sm-1,
	.gy-sm-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-sm-2,
	.gx-sm-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-sm-2,
	.gy-sm-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-sm-3,
	.gx-sm-3 {
		--bs-gutter-x: 1rem;
	}
	.g-sm-3,
	.gy-sm-3 {
		--bs-gutter-y: 1rem;
	}
	.g-sm-4,
	.gx-sm-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-sm-4,
	.gy-sm-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-sm-5,
	.gx-sm-5 {
		--bs-gutter-x: 3rem;
	}
	.g-sm-5,
	.gy-sm-5 {
		--bs-gutter-y: 3rem;
	}
}

@media (min-width: 768px) {
	.col-md {
		flex: 1 0 0%;
	}
	.row-cols-md-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-md-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-md-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-md-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-md-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-md-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-md-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-md-1 {
		flex: 0 0 auto;
		width: 8.3333333333%;
	}
	.col-md-2 {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-md-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-md-4 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.col-md-5 {
		flex: 0 0 auto;
		width: 41.6666666667%;
	}
	.col-md-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-md-7 {
		flex: 0 0 auto;
		width: 58.3333333333%;
	}
	.col-md-8 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}
	.col-md-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-md-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}
	.col-md-11 {
		flex: 0 0 auto;
		width: 91.6666666667%;
	}
	.col-md-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-md-0 {
		margin-left: 0;
	}
	.offset-md-1 {
		margin-left: 8.3333333333%;
	}
	.offset-md-2 {
		margin-left: 16.6666666667%;
	}
	.offset-md-3 {
		margin-left: 25%;
	}
	.offset-md-4 {
		margin-left: 33.3333333333%;
	}
	.offset-md-5 {
		margin-left: 41.6666666667%;
	}
	.offset-md-6 {
		margin-left: 50%;
	}
	.offset-md-7 {
		margin-left: 58.3333333333%;
	}
	.offset-md-8 {
		margin-left: 66.6666666667%;
	}
	.offset-md-9 {
		margin-left: 75%;
	}
	.offset-md-10 {
		margin-left: 83.3333333333%;
	}
	.offset-md-11 {
		margin-left: 91.6666666667%;
	}
	.g-md-0,
	.gx-md-0 {
		--bs-gutter-x: 0;
	}
	.g-md-0,
	.gy-md-0 {
		--bs-gutter-y: 0;
	}
	.g-md-1,
	.gx-md-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-md-1,
	.gy-md-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-md-2,
	.gx-md-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-md-2,
	.gy-md-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-md-3,
	.gx-md-3 {
		--bs-gutter-x: 1rem;
	}
	.g-md-3,
	.gy-md-3 {
		--bs-gutter-y: 1rem;
	}
	.g-md-4,
	.gx-md-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-md-4,
	.gy-md-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-md-5,
	.gx-md-5 {
		--bs-gutter-x: 3rem;
	}
	.g-md-5,
	.gy-md-5 {
		--bs-gutter-y: 3rem;
	}
}

@media (min-width: 992px) {
	.col-lg {
		flex: 1 0 0%;
	}
	.row-cols-lg-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-lg-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-lg-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-lg-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-lg-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-lg-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-lg-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-lg-1 {
		flex: 0 0 auto;
		width: 8.3333333333%;
	}
	.col-lg-2 {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-lg-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-lg-4 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.col-lg-5 {
		flex: 0 0 auto;
		width: 41.6666666667%;
	}
	.col-lg-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-lg-7 {
		flex: 0 0 auto;
		width: 58.3333333333%;
	}
	.col-lg-8 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-lg-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}
	.col-lg-11 {
		flex: 0 0 auto;
		width: 91.6666666667%;
	}
	.col-lg-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-lg-0 {
		margin-left: 0;
	}
	.offset-lg-1 {
		margin-left: 8.3333333333%;
	}
	.offset-lg-2 {
		margin-left: 16.6666666667%;
	}
	.offset-lg-3 {
		margin-left: 25%;
	}
	.offset-lg-4 {
		margin-left: 33.3333333333%;
	}
	.offset-lg-5 {
		margin-left: 41.6666666667%;
	}
	.offset-lg-6 {
		margin-left: 50%;
	}
	.offset-lg-7 {
		margin-left: 58.3333333333%;
	}
	.offset-lg-8 {
		margin-left: 66.6666666667%;
	}
	.offset-lg-9 {
		margin-left: 75%;
	}
	.offset-lg-10 {
		margin-left: 83.3333333333%;
	}
	.offset-lg-11 {
		margin-left: 91.6666666667%;
	}
	.g-lg-0,
	.gx-lg-0 {
		--bs-gutter-x: 0;
	}
	.g-lg-0,
	.gy-lg-0 {
		--bs-gutter-y: 0;
	}
	.g-lg-1,
	.gx-lg-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-lg-1,
	.gy-lg-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-lg-2,
	.gx-lg-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-lg-2,
	.gy-lg-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-lg-3,
	.gx-lg-3 {
		--bs-gutter-x: 1rem;
	}
	.g-lg-3,
	.gy-lg-3 {
		--bs-gutter-y: 1rem;
	}
	.g-lg-4,
	.gx-lg-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-lg-4,
	.gy-lg-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-lg-5,
	.gx-lg-5 {
		--bs-gutter-x: 3rem;
	}
	.g-lg-5,
	.gy-lg-5 {
		--bs-gutter-y: 3rem;
	}
}

@media (min-width: 1340px) {
	.col-xl {
		flex: 1 0 0%;
	}
	.row-cols-xl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-xl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-xl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-xl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-xl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-xl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-xl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-xl-1 {
		flex: 0 0 auto;
		width: 8.3333333333%;
	}
	.col-xl-2 {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-xl-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-xl-4 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.col-xl-5 {
		flex: 0 0 auto;
		width: 41.6666666667%;
	}
	.col-xl-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-xl-7 {
		flex: 0 0 auto;
		width: 58.3333333333%;
	}
	.col-xl-8 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}
	.col-xl-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-xl-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}
	.col-xl-11 {
		flex: 0 0 auto;
		width: 91.6666666667%;
	}
	.col-xl-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-xl-0 {
		margin-left: 0;
	}
	.offset-xl-1 {
		margin-left: 8.3333333333%;
	}
	.offset-xl-2 {
		margin-left: 16.6666666667%;
	}
	.offset-xl-3 {
		margin-left: 25%;
	}
	.offset-xl-4 {
		margin-left: 33.3333333333%;
	}
	.offset-xl-5 {
		margin-left: 41.6666666667%;
	}
	.offset-xl-6 {
		margin-left: 50%;
	}
	.offset-xl-7 {
		margin-left: 58.3333333333%;
	}
	.offset-xl-8 {
		margin-left: 66.6666666667%;
	}
	.offset-xl-9 {
		margin-left: 75%;
	}
	.offset-xl-10 {
		margin-left: 83.3333333333%;
	}
	.offset-xl-11 {
		margin-left: 91.6666666667%;
	}
	.g-xl-0,
	.gx-xl-0 {
		--bs-gutter-x: 0;
	}
	.g-xl-0,
	.gy-xl-0 {
		--bs-gutter-y: 0;
	}
	.g-xl-1,
	.gx-xl-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-xl-1,
	.gy-xl-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-xl-2,
	.gx-xl-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-xl-2,
	.gy-xl-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-xl-3,
	.gx-xl-3 {
		--bs-gutter-x: 1rem;
	}
	.g-xl-3,
	.gy-xl-3 {
		--bs-gutter-y: 1rem;
	}
	.g-xl-4,
	.gx-xl-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-xl-4,
	.gy-xl-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-xl-5,
	.gx-xl-5 {
		--bs-gutter-x: 3rem;
	}
	.g-xl-5,
	.gy-xl-5 {
		--bs-gutter-y: 3rem;
	}
}

@media (min-width: 1640px) {
	.col-xxl {
		flex: 1 0 0%;
	}
	.row-cols-xxl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-xxl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-xxl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-xxl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-xxl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-xxl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-xxl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-xxl-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-xxl-1 {
		flex: 0 0 auto;
		width: 8.3333333333%;
	}
	.col-xxl-2 {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-xxl-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-xxl-4 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.col-xxl-5 {
		flex: 0 0 auto;
		width: 41.6666666667%;
	}
	.col-xxl-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-xxl-7 {
		flex: 0 0 auto;
		width: 58.3333333333%;
	}
	.col-xxl-8 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}
	.col-xxl-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-xxl-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}
	.col-xxl-11 {
		flex: 0 0 auto;
		width: 91.6666666667%;
	}
	.col-xxl-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-xxl-0 {
		margin-left: 0;
	}
	.offset-xxl-1 {
		margin-left: 8.3333333333%;
	}
	.offset-xxl-2 {
		margin-left: 16.6666666667%;
	}
	.offset-xxl-3 {
		margin-left: 25%;
	}
	.offset-xxl-4 {
		margin-left: 33.3333333333%;
	}
	.offset-xxl-5 {
		margin-left: 41.6666666667%;
	}
	.offset-xxl-6 {
		margin-left: 50%;
	}
	.offset-xxl-7 {
		margin-left: 58.3333333333%;
	}
	.offset-xxl-8 {
		margin-left: 66.6666666667%;
	}
	.offset-xxl-9 {
		margin-left: 75%;
	}
	.offset-xxl-10 {
		margin-left: 83.3333333333%;
	}
	.offset-xxl-11 {
		margin-left: 91.6666666667%;
	}
	.g-xxl-0,
	.gx-xxl-0 {
		--bs-gutter-x: 0;
	}
	.g-xxl-0,
	.gy-xxl-0 {
		--bs-gutter-y: 0;
	}
	.g-xxl-1,
	.gx-xxl-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-xxl-1,
	.gy-xxl-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-xxl-2,
	.gx-xxl-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-xxl-2,
	.gy-xxl-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-xxl-3,
	.gx-xxl-3 {
		--bs-gutter-x: 1rem;
	}
	.g-xxl-3,
	.gy-xxl-3 {
		--bs-gutter-y: 1rem;
	}
	.g-xxl-4,
	.gx-xxl-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-xxl-4,
	.gy-xxl-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-xxl-5,
	.gx-xxl-5 {
		--bs-gutter-x: 3rem;
	}
	.g-xxl-5,
	.gy-xxl-5 {
		--bs-gutter-y: 3rem;
	}
}

.table {
	--bs-table-bg: transparent;
	--bs-table-accent-bg: transparent;
	--bs-table-striped-color: #212529;
	--bs-table-striped-bg: rgba(0, 0, 0, 0.05);
	--bs-table-active-color: #212529;
	--bs-table-active-bg: rgba(0, 0, 0, 0.1);
	--bs-table-hover-color: #212529;
	--bs-table-hover-bg: rgba(0, 0, 0, 0.075);
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	vertical-align: top;
	border-color: #dee2e6;
}

.table > :not(caption) > * > * {
	padding: 0.5rem 0.5rem;
	background-color: var(--bs-table-bg);
	border-bottom-width: 1px;
	box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
	vertical-align: inherit;
}

.table > thead {
	vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
	border-bottom-color: currentColor;
}

.caption-top {
	caption-side: top;
}

.table-sm > :not(caption) > * > * {
	padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
	border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
	border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
	border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
	--bs-table-accent-bg: var(--bs-table-striped-bg);
	color: var(--bs-table-striped-color);
}

.table-active {
	--bs-table-accent-bg: var(--bs-table-active-bg);
	color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
	--bs-table-accent-bg: var(--bs-table-hover-bg);
	color: var(--bs-table-hover-color);
}

.table-primary {
	--bs-table-bg: #d3ebf2;
	--bs-table-striped-bg: #c8dfe6;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #bed4da;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #c3d9e0;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #bed4da;
}

.table-secondary {
	--bs-table-bg: #e2e3e5;
	--bs-table-striped-bg: #d7d8da;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #cbccce;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #d1d2d4;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #cbccce;
}

.table-success {
	--bs-table-bg: #dff0dd;
	--bs-table-striped-bg: #d4e4d2;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #c9d8c7;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #cedecc;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #c9d8c7;
}

.table-info {
	--bs-table-bg: #cff4fc;
	--bs-table-striped-bg: #c5e8ef;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #badce3;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #bfe2e9;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #badce3;
}

.table-warning {
	--bs-table-bg: #fff8d1;
	--bs-table-striped-bg: #f2ecc7;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #e6dfbc;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ece5c1;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #e6dfbc;
}

.table-danger {
	--bs-table-bg: #f7d5d5;
	--bs-table-striped-bg: #ebcaca;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #dec0c0;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #e4c5c5;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #dec0c0;
}

.table-light {
	--bs-table-bg: #f8f9fa;
	--bs-table-striped-bg: #ecedee;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #dfe0e1;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #e5e6e7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #dfe0e1;
}

.table-dark {
	--bs-table-bg: #212529;
	--bs-table-striped-bg: #2c3034;
	--bs-table-striped-color: #fff;
	--bs-table-active-bg: #373b3e;
	--bs-table-active-color: #fff;
	--bs-table-hover-bg: #323539;
	--bs-table-hover-color: #fff;
	color: #fff;
	border-color: #373b3e;
}

.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

@media (max-width: 374.98px) {
	.table-responsive-sm {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 767.98px) {
	.table-responsive-md {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 991.98px) {
	.table-responsive-lg {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 1339.98px) {
	.table-responsive-xl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 1639.98px) {
	.table-responsive-xxl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

.form-label {
	margin-bottom: 0.5rem;
}

.col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.6666666667;
}

.col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
	.col-form-label-lg {
		font-size: 2.25rem;
	}
}

.col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: calc(1.2825rem + 0.39vw);
}

@media (min-width: 1200px) {
	.col-form-label-sm {
		font-size: 1.575rem;
	}
}

.form-text {
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #6c757d;
}

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: calc(1.305rem + 0.66vw);
	font-weight: 100;
	line-height: 1.6666666667;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	appearance: none;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (min-width: 1200px) {
	.form-control {
		font-size: 1.8rem;
	}
}

@media (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
}

.form-control[type="file"] {
	overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
	cursor: pointer;
}

.form-control:focus {
	color: #212529;
	background-color: #fff;
	border-color: #92cede;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(36, 157, 189, 0.25);
}

.form-control::-webkit-date-and-time-value {
	height: 1.6666666667em;
}

.form-control::placeholder {
	color: #6c757d;
	opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1;
}

.form-control::file-selector-button {
	padding: 0.375rem 0.75rem;
	margin: -0.375rem -0.75rem;
	margin-inline-end: 0.75rem;
	color: #212529;
	background-color: #e9ecef;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.form-control::file-selector-button {
		transition: none;
	}
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
	background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
	padding: 0.375rem 0.75rem;
	margin: -0.375rem -0.75rem;
	margin-inline-end: 0.75rem;
	color: #212529;
	background-color: #e9ecef;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.form-control::-webkit-file-upload-button {
		transition: none;
	}
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
	background-color: #dde0e3;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 0.375rem 0;
	margin-bottom: 0;
	line-height: 1.6666666667;
	color: #212529;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}

.form-control-sm {
	min-height: calc(1.6666666667em + 0.5rem + 2px);
	padding: 0.25rem 0.5rem;
	font-size: calc(1.2825rem + 0.39vw);
	border-radius: 0.2rem;
}

@media (min-width: 1200px) {
	.form-control-sm {
		font-size: 1.575rem;
	}
}

.form-control-sm::file-selector-button {
	padding: 0.25rem 0.5rem;
	margin: -0.25rem -0.5rem;
	margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
	padding: 0.25rem 0.5rem;
	margin: -0.25rem -0.5rem;
	margin-inline-end: 0.5rem;
}

.form-control-lg {
	min-height: calc(1.6666666667em + 1rem + 2px);
	padding: 0.5rem 1rem;
	font-size: calc(1.35rem + 1.2vw);
	border-radius: 0.3rem;
}

@media (min-width: 1200px) {
	.form-control-lg {
		font-size: 2.25rem;
	}
}

.form-control-lg::file-selector-button {
	padding: 0.5rem 1rem;
	margin: -0.5rem -1rem;
	margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
	padding: 0.5rem 1rem;
	margin: -0.5rem -1rem;
	margin-inline-end: 1rem;
}

textarea.form-control {
	min-height: calc(1.6666666667em + 0.75rem + 2px);
}

textarea.form-control-sm {
	min-height: calc(1.6666666667em + 0.5rem + 2px);
}

textarea.form-control-lg {
	min-height: calc(1.6666666667em + 1rem + 2px);
}

.form-control-color {
	max-width: 3rem;
	height: auto;
	padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
	cursor: pointer;
}

.form-control-color::-moz-color-swatch {
	height: 1.6666666667em;
	border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
	height: 1.6666666667em;
	border-radius: 0.25rem;
}

.form-select {
	display: block;
	width: 100%;
	padding: 0.375rem 2.25rem 0.375rem 0.75rem;
	font-size: calc(1.305rem + 0.66vw);
	font-weight: 100;
	line-height: 1.6666666667;
	color: #212529;
	background-color: #fff;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 16px 12px;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	appearance: none;
}

@media (min-width: 1200px) {
	.form-select {
		font-size: 1.8rem;
	}
}

.form-select:focus {
	border-color: #92cede;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(36, 157, 189, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
	padding-right: 0.75rem;
	background-image: none;
}

.form-select:disabled {
	background-color: #e9ecef;
}

.form-select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #212529;
}

.form-select-sm {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: calc(1.2825rem + 0.39vw);
}

@media (min-width: 1200px) {
	.form-select-sm {
		font-size: 1.575rem;
	}
}

.form-select-lg {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
	.form-select-lg {
		font-size: 2.25rem;
	}
}

.form-check {
	display: block;
	min-height: 3.0000000001rem;
	padding-left: 1.5em;
	margin-bottom: 0.125rem;
}

.form-check .form-check-input {
	float: left;
	margin-left: -1.5em;
}

.form-check-input {
	width: 1em;
	height: 1em;
	margin-top: 0.3333333334em;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid rgba(0, 0, 0, 0.25);
	appearance: none;
	color-adjust: exact;
}

.form-check-input[type="checkbox"] {
	border-radius: 0.25em;
}

.form-check-input[type="radio"] {
	border-radius: 50%;
}

.form-check-input:active {
	filter: brightness(90%);
}

.form-check-input:focus {
	border-color: #92cede;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(36, 157, 189, 0.25);
}

.form-check-input:checked {
	background-color: #249dbd;
	border-color: #249dbd;
}

.form-check-input:checked[type="checkbox"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
	background-color: #249dbd;
	border-color: #249dbd;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
	pointer-events: none;
	filter: none;
	opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
	opacity: 0.5;
}

.form-switch {
	padding-left: 2.5em;
}

.form-switch .form-check-input {
	width: 2em;
	margin-left: -2.5em;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
	background-position: left center;
	border-radius: 2em;
	transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.form-switch .form-check-input {
		transition: none;
	}
}

.form-switch .form-check-input:focus {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2392cede'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
	background-position: right center;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
	display: inline-block;
	margin-right: 1rem;
}

.btn-check {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
	pointer-events: none;
	filter: none;
	opacity: 0.65;
}

.form-range {
	width: 100%;
	height: 1.5rem;
	padding: 0;
	background-color: transparent;
	appearance: none;
}

.form-range:focus {
	outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 1px #024d69, 0 0 0 0.25rem rgba(36, 157, 189, 0.25);
}

.form-range:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #024d69, 0 0 0 0.25rem rgba(36, 157, 189, 0.25);
}

.form-range::-moz-focus-outer {
	border: 0;
}

.form-range::-webkit-slider-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: -0.25rem;
	background-color: #249dbd;
	border: 0;
	border-radius: 1rem;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	appearance: none;
}

@media (prefers-reduced-motion: reduce) {
	.form-range::-webkit-slider-thumb {
		transition: none;
	}
}

.form-range::-webkit-slider-thumb:active {
	background-color: #bde2eb;
}

.form-range::-webkit-slider-runnable-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}

.form-range::-moz-range-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #249dbd;
	border: 0;
	border-radius: 1rem;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	appearance: none;
}

@media (prefers-reduced-motion: reduce) {
	.form-range::-moz-range-thumb {
		transition: none;
	}
}

.form-range::-moz-range-thumb:active {
	background-color: #bde2eb;
}

.form-range::-moz-range-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}

.form-range:disabled {
	pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
	background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
	background-color: #adb5bd;
}

.form-floating {
	position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
	height: calc(3.5rem + 2px);
	padding: 1rem 0.75rem;
}

.form-floating > label {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding: 1rem 0.75rem;
	pointer-events: none;
	border: 1px solid transparent;
	transform-origin: 0 0;
	transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.form-floating > label {
		transition: none;
	}
}

.form-floating > .form-control::placeholder {
	color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}

.form-floating > .form-select {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
	z-index: 3;
}

.input-group .btn {
	position: relative;
	z-index: 2;
}

.input-group .btn:focus {
	z-index: 3;
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: 0.375rem 0.75rem;
	font-size: calc(1.305rem + 0.66vw);
	font-weight: 100;
	line-height: 1.6666666667;
	color: #212529;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

@media (min-width: 1200px) {
	.input-group-text {
		font-size: 1.8rem;
	}
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
	padding: 0.5rem 1rem;
	font-size: calc(1.35rem + 1.2vw);
	border-radius: 0.3rem;
}

@media (min-width: 1200px) {
	.input-group-lg > .form-control,
	.input-group-lg > .form-select,
	.input-group-lg > .input-group-text,
	.input-group-lg > .btn {
		font-size: 2.25rem;
	}
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
	padding: 0.25rem 0.5rem;
	font-size: calc(1.2825rem + 0.39vw);
	border-radius: 0.2rem;
}

@media (min-width: 1200px) {
	.input-group-sm > .form-control,
	.input-group-sm > .form-select,
	.input-group-sm > .input-group-text,
	.input-group-sm > .btn {
		font-size: 1.575rem;
	}
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
	padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #5eb454;
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: .1rem;
	font-size: calc(1.2825rem + 0.39vw);
	color: #000;
	background-color: rgba(94, 180, 84, 0.9);
	border-radius: 0.25rem;
}

@media (min-width: 1200px) {
	.valid-tooltip {
		font-size: 1.575rem;
	}
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
	border-color: #5eb454;
	padding-right: calc(1.6666666667em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235eb454' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.4166666667em + 0.1875rem) center;
	background-size: calc(0.8333333334em + 0.375rem) calc(0.8333333334em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
	border-color: #5eb454;
	box-shadow: 0 0 0 0.25rem rgba(94, 180, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
	padding-right: calc(1.6666666667em + 0.75rem);
	background-position: top calc(0.4166666667em + 0.1875rem) right calc(0.4166666667em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
	border-color: #5eb454;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235eb454' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.8333333334em + 0.375rem) calc(0.8333333334em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
	border-color: #5eb454;
	box-shadow: 0 0 0 0.25rem rgba(94, 180, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
	border-color: #5eb454;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
	background-color: #5eb454;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
	box-shadow: 0 0 0 0.25rem rgba(94, 180, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
	color: #5eb454;
}

.form-check-inline .form-check-input ~ .valid-feedback {
	margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
	z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
.input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
	z-index: 3;
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #d62c2d;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: .1rem;
	font-size: calc(1.2825rem + 0.39vw);
	color: #fff;
	background-color: rgba(214, 44, 45, 0.9);
	border-radius: 0.25rem;
}

@media (min-width: 1200px) {
	.invalid-tooltip {
		font-size: 1.575rem;
	}
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
	border-color: #d62c2d;
	padding-right: calc(1.6666666667em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d62c2d'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d62c2d' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.4166666667em + 0.1875rem) center;
	background-size: calc(0.8333333334em + 0.375rem) calc(0.8333333334em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
	border-color: #d62c2d;
	box-shadow: 0 0 0 0.25rem rgba(214, 44, 45, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
	padding-right: calc(1.6666666667em + 0.75rem);
	background-position: top calc(0.4166666667em + 0.1875rem) right calc(0.4166666667em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
	border-color: #d62c2d;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d62c2d'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d62c2d' stroke='none'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.8333333334em + 0.375rem) calc(0.8333333334em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
	border-color: #d62c2d;
	box-shadow: 0 0 0 0.25rem rgba(214, 44, 45, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
	border-color: #d62c2d;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
	background-color: #d62c2d;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
	box-shadow: 0 0 0 0.25rem rgba(214, 44, 45, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
	color: #d62c2d;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
	margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
	z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
.input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
	z-index: 3;
}

.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1.6666666667;
	color: #212529;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: calc(1.305rem + 0.66vw);
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (min-width: 1200px) {
	.btn {
		font-size: 1.8rem;
	}
}

@media (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}

.btn:hover {
	color: #212529;
}

.btn-check:focus + .btn, .btn:focus {
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(36, 157, 189, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
	pointer-events: none;
	opacity: 0.65;
}

.btn-primary {
	color: #000;
	background-color: #249dbd;
	border-color: #249dbd;
}

.btn-primary:hover {
	color: #000;
	background-color: #45acc7;
	border-color: #3aa7c4;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
	color: #000;
	background-color: #45acc7;
	border-color: #3aa7c4;
	box-shadow: 0 0 0 0.25rem rgba(31, 133, 161, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
	color: #000;
	background-color: #50b1ca;
	border-color: #3aa7c4;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(31, 133, 161, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
	color: #000;
	background-color: #249dbd;
	border-color: #249dbd;
}

.btn-secondary {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}

.btn-secondary:hover {
	color: #fff;
	background-color: #5c636a;
	border-color: #565e64;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
	color: #fff;
	background-color: #5c636a;
	border-color: #565e64;
	box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #565e64;
	border-color: #51585e;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}

.btn-success {
	color: #000;
	background-color: #5eb454;
	border-color: #5eb454;
}

.btn-success:hover {
	color: #000;
	background-color: #76bf6e;
	border-color: #6ebc65;
}

.btn-check:focus + .btn-success, .btn-success:focus {
	color: #000;
	background-color: #76bf6e;
	border-color: #6ebc65;
	box-shadow: 0 0 0 0.25rem rgba(80, 153, 71, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
	color: #000;
	background-color: #7ec376;
	border-color: #6ebc65;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(80, 153, 71, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
	color: #000;
	background-color: #5eb454;
	border-color: #5eb454;
}

.btn-info {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0;
}

.btn-info:hover {
	color: #000;
	background-color: #31d2f2;
	border-color: #25cff2;
}

.btn-check:focus + .btn-info, .btn-info:focus {
	color: #000;
	background-color: #31d2f2;
	border-color: #25cff2;
	box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
	color: #000;
	background-color: #3dd5f3;
	border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0;
}

.btn-warning {
	color: #000;
	background-color: #fddd19;
	border-color: #fddd19;
}

.btn-warning:hover {
	color: #000;
	background-color: #fde23c;
	border-color: #fde030;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
	color: #000;
	background-color: #fde23c;
	border-color: #fde030;
	box-shadow: 0 0 0 0.25rem rgba(215, 188, 21, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
	color: #000;
	background-color: #fde447;
	border-color: #fde030;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(215, 188, 21, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
	color: #000;
	background-color: #fddd19;
	border-color: #fddd19;
}

.btn-danger {
	color: #fff;
	background-color: #d62c2d;
	border-color: #d62c2d;
}

.btn-danger:hover {
	color: #fff;
	background-color: #b62526;
	border-color: #ab2324;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
	color: #fff;
	background-color: #b62526;
	border-color: #ab2324;
	box-shadow: 0 0 0 0.25rem rgba(220, 76, 77, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
	color: #fff;
	background-color: #ab2324;
	border-color: #a12122;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(220, 76, 77, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
	color: #fff;
	background-color: #d62c2d;
	border-color: #d62c2d;
}

.btn-light {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-light:hover {
	color: #000;
	background-color: #f9fafb;
	border-color: #f9fafb;
}

.btn-check:focus + .btn-light, .btn-light:focus {
	color: #000;
	background-color: #f9fafb;
	border-color: #f9fafb;
	box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
	color: #000;
	background-color: #f9fafb;
	border-color: #f9fafb;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-dark {
	color: #fff;
	background-color: #212529;
	border-color: #212529;
}

.btn-dark:hover {
	color: #fff;
	background-color: #1c1f23;
	border-color: #1a1e21;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
	color: #fff;
	background-color: #1c1f23;
	border-color: #1a1e21;
	box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
	color: #fff;
	background-color: #1a1e21;
	border-color: #191c1f;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
	color: #fff;
	background-color: #212529;
	border-color: #212529;
}

.btn-outline-primary {
	color: #249dbd;
	border-color: #249dbd;
}

.btn-outline-primary:hover {
	color: #000;
	background-color: #249dbd;
	border-color: #249dbd;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
	box-shadow: 0 0 0 0.25rem rgba(36, 157, 189, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
	color: #000;
	background-color: #249dbd;
	border-color: #249dbd;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(36, 157, 189, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
	color: #249dbd;
	background-color: transparent;
}

.btn-outline-secondary {
	color: #6c757d;
	border-color: #6c757d;
}

.btn-outline-secondary:hover {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
	box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
	color: #6c757d;
	background-color: transparent;
}

.btn-outline-success {
	color: #5eb454;
	border-color: #5eb454;
}

.btn-outline-success:hover {
	color: #000;
	background-color: #5eb454;
	border-color: #5eb454;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
	box-shadow: 0 0 0 0.25rem rgba(94, 180, 84, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
	color: #000;
	background-color: #5eb454;
	border-color: #5eb454;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(94, 180, 84, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
	color: #5eb454;
	background-color: transparent;
}

.btn-outline-info {
	color: #0dcaf0;
	border-color: #0dcaf0;
}

.btn-outline-info:hover {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
	box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
	color: #0dcaf0;
	background-color: transparent;
}

.btn-outline-warning {
	color: #fddd19;
	border-color: #fddd19;
}

.btn-outline-warning:hover {
	color: #000;
	background-color: #fddd19;
	border-color: #fddd19;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
	box-shadow: 0 0 0 0.25rem rgba(253, 221, 25, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
	color: #000;
	background-color: #fddd19;
	border-color: #fddd19;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(253, 221, 25, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
	color: #fddd19;
	background-color: transparent;
}

.btn-outline-danger {
	color: #d62c2d;
	border-color: #d62c2d;
}

.btn-outline-danger:hover {
	color: #fff;
	background-color: #d62c2d;
	border-color: #d62c2d;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
	box-shadow: 0 0 0 0.25rem rgba(214, 44, 45, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
	color: #fff;
	background-color: #d62c2d;
	border-color: #d62c2d;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(214, 44, 45, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
	color: #d62c2d;
	background-color: transparent;
}

.btn-outline-light {
	color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-outline-light:hover {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
	box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
	color: #f8f9fa;
	background-color: transparent;
}

.btn-outline-dark {
	color: #212529;
	border-color: #212529;
}

.btn-outline-dark:hover {
	color: #fff;
	background-color: #212529;
	border-color: #212529;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
	box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
	color: #fff;
	background-color: #212529;
	border-color: #212529;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
	color: #212529;
	background-color: transparent;
}

.btn-link {
	font-weight: 400;
	color: #249dbd;
	text-decoration: underline;
}

.btn-link:hover {
	color: #1d7e97;
}

.btn-link:disabled, .btn-link.disabled {
	color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
	padding: 0.5rem 1rem;
	font-size: calc(1.35rem + 1.2vw);
	border-radius: 0.3rem;
}

@media (min-width: 1200px) {
	.btn-lg, .btn-group-lg > .btn {
		font-size: 2.25rem;
	}
}

.btn-sm, .btn-group-sm > .btn {
	padding: 0.25rem 0.5rem;
	font-size: calc(1.2825rem + 0.39vw);
	border-radius: 0.2rem;
}

@media (min-width: 1200px) {
	.btn-sm, .btn-group-sm > .btn {
		font-size: 1.575rem;
	}
}

.fade {
	transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}

.fade:not(.show) {
	opacity: 0;
}

.collapse:not(.show) {
	display: none;
}

.collapsing {
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}

.dropup,
.dropend,
.dropdown,
.dropstart {
	position: relative;
}

.dropdown-toggle {
	white-space: nowrap;
}

.dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropdown-menu {
	position: absolute;
	z-index: 1000;
	display: none;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0;
	font-size: calc(1.305rem + 0.66vw);
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}

@media (min-width: 1200px) {
	.dropdown-menu {
		font-size: 1.8rem;
	}
}

.dropdown-menu[data-bs-popper] {
	top: 100%;
	left: 0;
	margin-top: 0.125rem;
}

.dropdown-menu-start {
	--bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
	right: auto /* rtl:ignore */;
	left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
	--bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
	right: 0 /* rtl:ignore */;
	left: auto /* rtl:ignore */;
}

@media (min-width: 375px) {
	.dropdown-menu-sm-start {
		--bs-position: start;
	}
	.dropdown-menu-sm-start[data-bs-popper] {
		right: auto /* rtl:ignore */;
		left: 0 /* rtl:ignore */;
	}
	.dropdown-menu-sm-end {
		--bs-position: end;
	}
	.dropdown-menu-sm-end[data-bs-popper] {
		right: 0 /* rtl:ignore */;
		left: auto /* rtl:ignore */;
	}
}

@media (min-width: 768px) {
	.dropdown-menu-md-start {
		--bs-position: start;
	}
	.dropdown-menu-md-start[data-bs-popper] {
		right: auto /* rtl:ignore */;
		left: 0 /* rtl:ignore */;
	}
	.dropdown-menu-md-end {
		--bs-position: end;
	}
	.dropdown-menu-md-end[data-bs-popper] {
		right: 0 /* rtl:ignore */;
		left: auto /* rtl:ignore */;
	}
}

@media (min-width: 992px) {
	.dropdown-menu-lg-start {
		--bs-position: start;
	}
	.dropdown-menu-lg-start[data-bs-popper] {
		right: auto /* rtl:ignore */;
		left: 0 /* rtl:ignore */;
	}
	.dropdown-menu-lg-end {
		--bs-position: end;
	}
	.dropdown-menu-lg-end[data-bs-popper] {
		right: 0 /* rtl:ignore */;
		left: auto /* rtl:ignore */;
	}
}

@media (min-width: 1340px) {
	.dropdown-menu-xl-start {
		--bs-position: start;
	}
	.dropdown-menu-xl-start[data-bs-popper] {
		right: auto /* rtl:ignore */;
		left: 0 /* rtl:ignore */;
	}
	.dropdown-menu-xl-end {
		--bs-position: end;
	}
	.dropdown-menu-xl-end[data-bs-popper] {
		right: 0 /* rtl:ignore */;
		left: auto /* rtl:ignore */;
	}
}

@media (min-width: 1640px) {
	.dropdown-menu-xxl-start {
		--bs-position: start;
	}
	.dropdown-menu-xxl-start[data-bs-popper] {
		right: auto /* rtl:ignore */;
		left: 0 /* rtl:ignore */;
	}
	.dropdown-menu-xxl-end {
		--bs-position: end;
	}
	.dropdown-menu-xxl-end[data-bs-popper] {
		right: 0 /* rtl:ignore */;
		left: auto /* rtl:ignore */;
	}
}

.dropup .dropdown-menu[data-bs-popper] {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0;
	border-right: 0.3em solid transparent;
	border-bottom: 0.3em solid;
	border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0;
	border-bottom: 0.3em solid transparent;
	border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropend .dropdown-toggle::after {
	vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
}

.dropstart .dropdown-toggle::after {
	display: none;
}

.dropstart .dropdown-toggle::before {
	display: inline-block;
	margin-right: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0.3em solid;
	border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropstart .dropdown-toggle::before {
	vertical-align: 0;
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
	color: #1e2125;
	background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #249dbd;
}

.dropdown-item.disabled, .dropdown-item:disabled {
	color: #adb5bd;
	pointer-events: none;
	background-color: transparent;
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: calc(1.2825rem + 0.39vw);
	color: #6c757d;
	white-space: nowrap;
}

@media (min-width: 1200px) {
	.dropdown-header {
		font-size: 1.575rem;
	}
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1rem;
	color: #212529;
}

.dropdown-menu-dark {
	color: #dee2e6;
	background-color: #343a40;
	border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
	color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
	color: #fff;
	background-color: #249dbd;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
	color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
	border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
	color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
	color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
	position: relative;
	flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
	z-index: 1;
}

.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.btn-toolbar .input-group {
	width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
	margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
	margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
	margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}

.btn-group-vertical {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
	width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
	margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-link {
	display: block;
	padding: 0.5rem 1rem;
	color: #249dbd;
	text-decoration: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.nav-link {
		transition: none;
	}
}

.nav-link:hover, .nav-link:focus {
	color: #1d7e97;
}

.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default;
}

.nav-tabs {
	border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
	margin-bottom: -1px;
	background: none;
	border: 1px solid transparent;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
	border-color: #e9ecef #e9ecef #dee2e6;
	isolation: isolate;
}

.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: transparent;
	border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #495057;
	background-color: #024d69;
	border-color: #dee2e6 #dee2e6 #024d69;
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav-pills .nav-link {
	background: none;
	border: 0;
	border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #249dbd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
	flex: 1 1 auto;
	text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
	width: 100%;
}

.tab-content > .tab-pane {
	display: none;
}

.tab-content > .active {
	display: block;
}

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

.navbar-brand {
	padding-top: 0.125rem;
	padding-bottom: 0.125rem;
	margin-right: 1rem;
	font-size: calc(1.35rem + 1.2vw);
	text-decoration: none;
	white-space: nowrap;
}

@media (min-width: 1200px) {
	.navbar-brand {
		font-size: 2.25rem;
	}
}

.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}

.navbar-nav .dropdown-menu {
	position: static;
}

.navbar-text {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
}

.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: calc(1.35rem + 1.2vw);
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: box-shadow 0.15s ease-in-out;
}

@media (min-width: 1200px) {
	.navbar-toggler {
		font-size: 2.25rem;
	}
}

@media (prefers-reduced-motion: reduce) {
	.navbar-toggler {
		transition: none;
	}
}

.navbar-toggler:hover {
	text-decoration: none;
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}

.navbar-nav-scroll {
	max-height: var(--bs-scroll-height, 75vh);
	overflow-y: auto;
}

@media (min-width: 375px) {
	.navbar-expand-sm {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-sm .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-sm .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-sm .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-sm .navbar-toggler {
		display: none;
	}
}

@media (min-width: 768px) {
	.navbar-expand-md {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-md .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-md .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-md .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-md .navbar-toggler {
		display: none;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-lg .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-lg .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-lg .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-lg .navbar-toggler {
		display: none;
	}
}

@media (min-width: 1340px) {
	.navbar-expand-xl {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-xl .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-xl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xl .navbar-toggler {
		display: none;
	}
}

@media (min-width: 1640px) {
	.navbar-expand-xxl {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xxl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xxl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xxl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-xxl .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-xxl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xxl .navbar-toggler {
		display: none;
	}
}

.navbar-expand {
	flex-wrap: nowrap;
	justify-content: flex-start;
}

.navbar-expand .navbar-nav {
	flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
	overflow: visible;
}

.navbar-expand .navbar-collapse {
	display: flex !important;
	flex-basis: auto;
}

.navbar-expand .navbar-toggler {
	display: none;
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.55);
	border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
	color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
	color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
	color: #fff;
}

.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.55);
	border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
	color: #fff;
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}

.card > hr {
	margin-right: 0;
	margin-left: 0;
}

.card > .list-group {
	border-top: inherit;
	border-bottom: inherit;
}

.card > .list-group:first-child {
	border-top-width: 0;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
	border-bottom-width: 0;
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
	border-top: 0;
}

.card-body {
	flex: 1 1 auto;
	padding: 1rem 1rem;
}

.card-title {
	margin-bottom: 0.5rem;
}

.card-subtitle {
	margin-top: -0.25rem;
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

.card-link:hover {
	text-decoration: none;
}

.card-link + .card-link {
	margin-left: 1rem;
}

.card-header {
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
	padding: 0.5rem 1rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
	border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
	margin-right: -0.5rem;
	margin-bottom: -0.5rem;
	margin-left: -0.5rem;
	border-bottom: 0;
}

.card-header-tabs .nav-link.active {
	background-color: #fff;
	border-bottom-color: #fff;
}

.card-header-pills {
	margin-right: -0.5rem;
	margin-left: -0.5rem;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1rem;
	border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
	width: 100%;
}

.card-img,
.card-img-top {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
	margin-bottom: 17px;
}

@media (min-width: 375px) {
	.card-group {
		display: flex;
		flex-flow: row wrap;
	}
	.card-group > .card {
		flex: 1 0 0%;
		margin-bottom: 0;
	}
	.card-group > .card + .card {
		margin-left: 0;
		border-left: 0;
	}
	.card-group > .card:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.card-group > .card:not(:last-child) .card-img-top,
	.card-group > .card:not(:last-child) .card-header {
		border-top-right-radius: 0;
	}
	.card-group > .card:not(:last-child) .card-img-bottom,
	.card-group > .card:not(:last-child) .card-footer {
		border-bottom-right-radius: 0;
	}
	.card-group > .card:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.card-group > .card:not(:first-child) .card-img-top,
	.card-group > .card:not(:first-child) .card-header {
		border-top-left-radius: 0;
	}
	.card-group > .card:not(:first-child) .card-img-bottom,
	.card-group > .card:not(:first-child) .card-footer {
		border-bottom-left-radius: 0;
	}
}

.accordion-button {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: calc(1.305rem + 0.66vw);
	color: #212529;
	text-align: left;
	background-color: #024d69;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (min-width: 1200px) {
	.accordion-button {
		font-size: 1.8rem;
	}
}

@media (prefers-reduced-motion: reduce) {
	.accordion-button {
		transition: none;
	}
}

.accordion-button:not(.collapsed) {
	color: #208daa;
	background-color: #e9f5f8;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23208daa'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	transform: rotate(-180deg);
}

.accordion-button::after {
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: auto;
	content: "";
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 1.25rem;
	transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.accordion-button::after {
		transition: none;
	}
}

.accordion-button:hover {
	z-index: 2;
}

.accordion-button:focus {
	z-index: 3;
	border-color: #92cede;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(36, 157, 189, 0.25);
}

.accordion-header {
	margin-bottom: 0;
}

.accordion-item {
	background-color: #024d69;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
	border-top: 0;
}

.accordion-item:last-of-type {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.accordion-body {
	padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
	border-width: 0;
}

.accordion-flush .accordion-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
	border-top: 0;
}

.accordion-flush .accordion-item:last-child {
	border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
	border-radius: 0;
}

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0;
	margin-bottom: 1rem;
	list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
	padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
	float: left;
	padding-right: 0.5rem;
	color: #6c757d;
	content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
	color: #6c757d;
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
}

.page-link {
	position: relative;
	display: block;
	color: #249dbd;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #dee2e6;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.page-link {
		transition: none;
	}
}

.page-link:hover {
	z-index: 2;
	color: #1d7e97;
	background-color: #e9ecef;
	border-color: #dee2e6;
}

.page-link:focus {
	z-index: 3;
	color: #1d7e97;
	background-color: #e9ecef;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(36, 157, 189, 0.25);
}

.page-item:not(:first-child) .page-link {
	margin-left: -1px;
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #249dbd;
	border-color: #249dbd;
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
	border-color: #dee2e6;
}

.page-link {
	padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
	.pagination-lg .page-link {
		font-size: 2.25rem;
	}
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: calc(1.2825rem + 0.39vw);
}

@media (min-width: 1200px) {
	.pagination-sm .page-link {
		font-size: 1.575rem;
	}
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.badge {
	display: inline-block;
	padding: 0.35em 0.65em;
	font-size: 0.75em;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
}

.badge:empty {
	display: none;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.alert {
	position: relative;
	padding: 1rem 1rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 700;
}

.alert-dismissible {
	padding-right: 3rem;
}

.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 1.25rem 1rem;
}

.alert-primary {
	color: #165e71;
	background-color: #d3ebf2;
	border-color: #bde2eb;
}

.alert-primary .alert-link {
	color: #124b5a;
}

.alert-secondary {
	color: #41464b;
	background-color: #e2e3e5;
	border-color: #d3d6d8;
}

.alert-secondary .alert-link {
	color: #34383c;
}

.alert-success {
	color: #386c32;
	background-color: #dff0dd;
	border-color: #cfe9cc;
}

.alert-success .alert-link {
	color: #2d5628;
}

.alert-info {
	color: #055160;
	background-color: #cff4fc;
	border-color: #b6effb;
}

.alert-info .alert-link {
	color: #04414d;
}

.alert-warning {
	color: #65580a;
	background-color: #fff8d1;
	border-color: #fef5ba;
}

.alert-warning .alert-link {
	color: #514608;
}

.alert-danger {
	color: #801a1b;
	background-color: #f7d5d5;
	border-color: #f3c0c0;
}

.alert-danger .alert-link {
	color: #661516;
}

.alert-light {
	color: #636464;
	background-color: #fefefe;
	border-color: #fdfdfe;
}

.alert-light .alert-link {
	color: #4f5050;
}

.alert-dark {
	color: #141619;
	background-color: #d3d3d4;
	border-color: #bcbebf;
}

.alert-dark .alert-link {
	color: #101214;
}

@keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem;
	}
}

.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: calc(1.26rem + 0.12vw);
	background-color: #e9ecef;
	border-radius: 0.25rem;
}

@media (min-width: 1200px) {
	.progress {
		font-size: 1.35rem;
	}
}

.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #249dbd;
	transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
	.progress-bar {
		transition: none;
	}
}

.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
	.progress-bar-animated {
		animation: none;
	}
}

.list-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border-radius: 0.25rem;
}

.list-group-numbered {
	list-style-type: none;
	counter-reset: section;
}

.list-group-numbered > li::before {
	content: counters(section, ".") ". ";
	counter-increment: section;
}

.list-group-item-action {
	width: 100%;
	color: #495057;
	text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
	z-index: 1;
	color: #495057;
	text-decoration: none;
	background-color: #f8f9fa;
}

.list-group-item-action:active {
	color: #212529;
	background-color: #e9ecef;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 0.5rem 1rem;
	color: #212529;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}

.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #249dbd;
	border-color: #249dbd;
}

.list-group-item + .list-group-item {
	border-top-width: 0;
}

.list-group-item + .list-group-item.active {
	margin-top: -1px;
	border-top-width: 1px;
}

.list-group-horizontal {
	flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
	border-bottom-left-radius: 0.25rem;
	border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
	border-top-right-radius: 0.25rem;
	border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
	margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
	border-top-width: 1px;
	border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
	margin-left: -1px;
	border-left-width: 1px;
}

@media (min-width: 375px) {
	.list-group-horizontal-sm {
		flex-direction: row;
	}
	.list-group-horizontal-sm > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-sm > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-sm > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-sm > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-sm > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 768px) {
	.list-group-horizontal-md {
		flex-direction: row;
	}
	.list-group-horizontal-md > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-md > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-md > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-md > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-md > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 992px) {
	.list-group-horizontal-lg {
		flex-direction: row;
	}
	.list-group-horizontal-lg > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-lg > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-lg > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-lg > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-lg > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 1340px) {
	.list-group-horizontal-xl {
		flex-direction: row;
	}
	.list-group-horizontal-xl > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-xl > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-xl > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-xl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-xl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

@media (min-width: 1640px) {
	.list-group-horizontal-xxl {
		flex-direction: row;
	}
	.list-group-horizontal-xxl > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-xxl > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-xxl > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-xxl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}

.list-group-flush {
	border-radius: 0;
}

.list-group-flush > .list-group-item {
	border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
	border-bottom-width: 0;
}

.list-group-item-primary {
	color: #165e71;
	background-color: #d3ebf2;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
	color: #165e71;
	background-color: #bed4da;
}

.list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #165e71;
	border-color: #165e71;
}

.list-group-item-secondary {
	color: #41464b;
	background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
	color: #41464b;
	background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #41464b;
	border-color: #41464b;
}

.list-group-item-success {
	color: #386c32;
	background-color: #dff0dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
	color: #386c32;
	background-color: #c9d8c7;
}

.list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #386c32;
	border-color: #386c32;
}

.list-group-item-info {
	color: #055160;
	background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
	color: #055160;
	background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #055160;
	border-color: #055160;
}

.list-group-item-warning {
	color: #65580a;
	background-color: #fff8d1;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
	color: #65580a;
	background-color: #e6dfbc;
}

.list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #65580a;
	border-color: #65580a;
}

.list-group-item-danger {
	color: #801a1b;
	background-color: #f7d5d5;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
	color: #801a1b;
	background-color: #dec0c0;
}

.list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #801a1b;
	border-color: #801a1b;
}

.list-group-item-light {
	color: #636464;
	background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
	color: #636464;
	background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #636464;
	border-color: #636464;
}

.list-group-item-dark {
	color: #141619;
	background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
	color: #141619;
	background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #141619;
	border-color: #141619;
}

.btn-close {
	box-sizing: content-box;
	width: 1em;
	height: 1em;
	padding: 0.25em 0.25em;
	color: #000;
	background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
	border: 0;
	border-radius: 0.25rem;
	opacity: 0.5;
}

.btn-close:hover {
	color: #000;
	text-decoration: none;
	opacity: 0.75;
}

.btn-close:focus {
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(36, 157, 189, 0.25);
	opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
	pointer-events: none;
	user-select: none;
	opacity: 0.25;
}

.btn-close-white {
	filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
	width: 350px;
	max-width: 100%;
	font-size: 0.875rem;
	pointer-events: auto;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
	opacity: 0;
}

.toast.hide {
	display: none;
}

.toast-container {
	width: max-content;
	max-width: 100%;
	pointer-events: none;
}

.toast-container > :not(:last-child) {
	margin-bottom: 17px;
}

.toast-header {
	display: flex;
	align-items: center;
	padding: 0.5rem 0.75rem;
	color: #6c757d;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
	margin-right: -0.375rem;
	margin-left: 0.75rem;
}

.toast-body {
	padding: 0.75rem;
	word-wrap: break-word;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1060;
	display: none;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}

.modal.fade .modal-dialog {
	transition: transform 0.3s ease-out;
	transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		transition: none;
	}
}

.modal.show .modal-dialog {
	transform: none;
}

.modal.modal-static .modal-dialog {
	transform: scale(1.02);
}

.modal-dialog-scrollable {
	height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
	max-height: 100%;
	overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
	overflow-y: auto;
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem);
}

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	outline: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}

.modal-backdrop.fade {
	opacity: 0;
}

.modal-backdrop.show {
	opacity: 0.5;
}

.modal-header {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px solid #dee2e6;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
	padding: 0.5rem 0.5rem;
	margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.6666666667;
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem;
}

.modal-footer {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: calc(0.3rem - 1px);
	border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
	margin: 0.25rem;
}

@media (min-width: 375px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}
	.modal-dialog-scrollable {
		height: calc(100% - 3.5rem);
	}
	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem);
	}
	.modal-sm {
		max-width: 300px;
	}
}

@media (min-width: 992px) {
	.modal-lg,
	.modal-xl {
		max-width: 800px;
	}
}

@media (min-width: 1340px) {
	.modal-xl {
		max-width: 1140px;
	}
}

.modal-fullscreen {
	width: 100vw;
	max-width: none;
	height: 100%;
	margin: 0;
}

.modal-fullscreen .modal-content {
	height: 100%;
	border: 0;
	border-radius: 0;
}

.modal-fullscreen .modal-header {
	border-radius: 0;
}

.modal-fullscreen .modal-body {
	overflow-y: auto;
}

.modal-fullscreen .modal-footer {
	border-radius: 0;
}

@media (max-width: 374.98px) {
	.modal-fullscreen-sm-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-sm-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-sm-down .modal-header {
		border-radius: 0;
	}
	.modal-fullscreen-sm-down .modal-body {
		overflow-y: auto;
	}
	.modal-fullscreen-sm-down .modal-footer {
		border-radius: 0;
	}
}

@media (max-width: 767.98px) {
	.modal-fullscreen-md-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-md-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-md-down .modal-header {
		border-radius: 0;
	}
	.modal-fullscreen-md-down .modal-body {
		overflow-y: auto;
	}
	.modal-fullscreen-md-down .modal-footer {
		border-radius: 0;
	}
}

@media (max-width: 991.98px) {
	.modal-fullscreen-lg-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-lg-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-lg-down .modal-header {
		border-radius: 0;
	}
	.modal-fullscreen-lg-down .modal-body {
		overflow-y: auto;
	}
	.modal-fullscreen-lg-down .modal-footer {
		border-radius: 0;
	}
}

@media (max-width: 1339.98px) {
	.modal-fullscreen-xl-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-xl-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-xl-down .modal-header {
		border-radius: 0;
	}
	.modal-fullscreen-xl-down .modal-body {
		overflow-y: auto;
	}
	.modal-fullscreen-xl-down .modal-footer {
		border-radius: 0;
	}
}

@media (max-width: 1639.98px) {
	.modal-fullscreen-xxl-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-xxl-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-xxl-down .modal-header {
		border-radius: 0;
	}
	.modal-fullscreen-xxl-down .modal-body {
		overflow-y: auto;
	}
	.modal-fullscreen-xxl-down .modal-footer {
		border-radius: 0;
	}
}

.tooltip {
	position: absolute;
	z-index: 1080;
	display: block;
	margin: 0;
	font-family: "ProximaNova", sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.6666666667;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: calc(1.2825rem + 0.39vw);
	word-wrap: break-word;
	opacity: 0;
}

@media (min-width: 1200px) {
	.tooltip {
		font-size: 1.575rem;
	}
}

.tooltip.show {
	opacity: 0.9;
}

.tooltip .tooltip-arrow {
	position: absolute;
	display: block;
	width: 0.8rem;
	height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
	padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
	bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
	top: -1px;
	border-width: 0.4rem 0.4rem 0;
	border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
	padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
	left: 0;
	width: 0.4rem;
	height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
	right: -1px;
	border-width: 0.4rem 0.4rem 0.4rem 0;
	border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
	padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
	top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
	bottom: -1px;
	border-width: 0 0.4rem 0.4rem;
	border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
	padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
	right: 0;
	width: 0.4rem;
	height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
	left: -1px;
	border-width: 0.4rem 0 0.4rem 0.4rem;
	border-left-color: #000;
}

.tooltip-inner {
	max-width: 200px;
	padding: 0.25rem 0.5rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 0.25rem;
}

.popover {
	position: absolute;
	top: 0;
	left: 0 /* rtl:ignore */;
	z-index: 1070;
	display: block;
	max-width: 276px;
	font-family: "ProximaNova", sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.6666666667;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: calc(1.2825rem + 0.39vw);
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
}

@media (min-width: 1200px) {
	.popover {
		font-size: 1.575rem;
	}
}

.popover .popover-arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
	bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
	bottom: 0;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
	bottom: 1px;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
	left: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
	left: 0;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
	left: 1px;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
	top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
	top: 0;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
	top: 1px;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -0.5rem;
	content: "";
	border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
	right: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
	right: 0;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
	right: 1px;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: #fff;
}

.popover-header {
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: calc(1.305rem + 0.66vw);
	background-color: #f0f0f0;
	border-bottom: 1px solid #d8d8d8;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}

@media (min-width: 1200px) {
	.popover-header {
		font-size: 1.8rem;
	}
}

.popover-header:empty {
	display: none;
}

.popover-body {
	padding: 1rem 1rem;
	color: #212529;
}

.carousel {
	position: relative;
}

.carousel.pointer-event {
	touch-action: pan-y;
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.carousel-inner::after {
	display: block;
	clear: both;
	content: "";
}

.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	backface-visibility: hidden;
	transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.carousel-item {
		transition: none;
	}
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
	display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
	transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
	transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
	opacity: 0;
	transition-property: opacity;
	transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
	z-index: 1;
	opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
	z-index: 0;
	opacity: 0;
	transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
	.carousel-fade .active.carousel-item-start,
	.carousel-fade .active.carousel-item-end {
		transition: none;
	}
}

.carousel-control-prev,
.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	padding: 0;
	color: #fff;
	text-align: center;
	background: none;
	border: 0;
	opacity: 0.5;
	transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
	.carousel-control-prev,
	.carousel-control-next {
		transition: none;
	}
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: 0.9;
}

.carousel-control-prev {
	left: 0;
}

.carousel-control-next {
	right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	padding: 0;
	margin-right: 15%;
	margin-bottom: 1rem;
	margin-left: 15%;
	list-style: none;
}

.carousel-indicators [data-bs-target] {
	box-sizing: content-box;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	padding: 0;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	opacity: 0.5;
	transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
	.carousel-indicators [data-bs-target] {
		transition: none;
	}
}

.carousel-indicators .active {
	opacity: 1;
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 1.25rem;
	left: 15%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: #fff;
	text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
	filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
	background-color: #000;
}

.carousel-dark .carousel-caption {
	color: #000;
}

@keyframes spinner-border {
	to {
		transform: rotate(360deg) /* rtl:ignore */;
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
}

@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
		transform: none;
	}
}

.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
	width: 1rem;
	height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
	.spinner-border,
	.spinner-grow {
		animation-duration: 1.5s;
	}
}

.offcanvas {
	position: fixed;
	bottom: 0;
	z-index: 1050;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	visibility: hidden;
	background-color: #fff;
	background-clip: padding-box;
	outline: 0;
	transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.offcanvas {
		transition: none;
	}
}

.offcanvas-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
	padding: 0.5rem 0.5rem;
	margin: -0.5rem -0.5rem -0.5rem auto;
}

.offcanvas-title {
	margin-bottom: 0;
	line-height: 1.6666666667;
}

.offcanvas-body {
	flex-grow: 1;
	padding: 1rem 1rem;
	overflow-y: auto;
}

.offcanvas-start {
	top: 0;
	left: 0;
	width: 400px;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateX(-100%);
}

.offcanvas-end {
	top: 0;
	right: 0;
	width: 400px;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateX(100%);
}

.offcanvas-top {
	top: 0;
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateY(-100%);
}

.offcanvas-bottom {
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateY(100%);
}

.offcanvas.show {
	transform: none;
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.link-primary {
	color: #249dbd;
}

.link-primary:hover, .link-primary:focus {
	color: #50b1ca;
}

.link-secondary {
	color: #6c757d;
}

.link-secondary:hover, .link-secondary:focus {
	color: #565e64;
}

.link-success {
	color: #5eb454;
}

.link-success:hover, .link-success:focus {
	color: #7ec376;
}

.link-info {
	color: #0dcaf0;
}

.link-info:hover, .link-info:focus {
	color: #3dd5f3;
}

.link-warning {
	color: #fddd19;
}

.link-warning:hover, .link-warning:focus {
	color: #fde447;
}

.link-danger {
	color: #d62c2d;
}

.link-danger:hover, .link-danger:focus {
	color: #ab2324;
}

.link-light {
	color: #f8f9fa;
}

.link-light:hover, .link-light:focus {
	color: #f9fafb;
}

.link-dark {
	color: #212529;
}

.link-dark:hover, .link-dark:focus {
	color: #1a1e21;
}

.ratio {
	position: relative;
	width: 100%;
}

.ratio::before {
	display: block;
	padding-top: var(--bs-aspect-ratio);
	content: "";
}

.ratio > * {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ratio-1x1 {
	--bs-aspect-ratio: 100%;
}

.ratio-4x3 {
	--bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
	--bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
	--bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

.sticky-top {
	position: sticky;
	top: 0;
	z-index: 1020;
}

@media (min-width: 375px) {
	.sticky-sm-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 768px) {
	.sticky-md-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 992px) {
	.sticky-lg-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 1340px) {
	.sticky-xl-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

@media (min-width: 1640px) {
	.sticky-xxl-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	content: "";
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.float-start {
	float: left !important;
}

.float-end {
	float: right !important;
}

.float-none {
	float: none !important;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-visible {
	overflow: visible !important;
}

.overflow-scroll {
	overflow: scroll !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-grid {
	display: grid !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

.d-none {
	display: none !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: sticky !important;
}

.top-0 {
	top: 0 !important;
}

.top-50 {
	top: 50% !important;
}

.top-100 {
	top: 100% !important;
}

.bottom-0 {
	bottom: 0 !important;
}

.bottom-50 {
	bottom: 50% !important;
}

.bottom-100 {
	bottom: 100% !important;
}

.start-0 {
	left: 0 !important;
}

.start-50 {
	left: 50% !important;
}

.start-100 {
	left: 100% !important;
}

.end-0 {
	right: 0 !important;
}

.end-50 {
	right: 50% !important;
}

.end-100 {
	right: 100% !important;
}

.translate-middle {
	transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
	transform: translateX(-50%) !important;
}

.translate-middle-y {
	transform: translateY(-50%) !important;
}

.border {
	border: 1px solid #dee2e6 !important;
}

.border-0 {
	border: 0 !important;
}

.border-top {
	border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-end {
	border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
	border-right: 0 !important;
}

.border-bottom {
	border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-start {
	border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
	border-left: 0 !important;
}

.border-primary {
	border-color: #249dbd !important;
}

.border-secondary {
	border-color: #6c757d !important;
}

.border-success {
	border-color: #5eb454 !important;
}

.border-info {
	border-color: #0dcaf0 !important;
}

.border-warning {
	border-color: #fddd19 !important;
}

.border-danger {
	border-color: #d62c2d !important;
}

.border-light {
	border-color: #f8f9fa !important;
}

.border-dark {
	border-color: #212529 !important;
}

.border-white {
	border-color: #fff !important;
}

.border-1 {
	border-width: 1px !important;
}

.border-2 {
	border-width: 2px !important;
}

.border-3 {
	border-width: 3px !important;
}

.border-4 {
	border-width: 4px !important;
}

.border-5 {
	border-width: 5px !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.vw-100 {
	width: 100vw !important;
}

.min-vw-100 {
	min-width: 100vw !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mh-100 {
	max-height: 100% !important;
}

.vh-100 {
	height: 100vh !important;
}

.min-vh-100 {
	min-height: 100vh !important;
}

.flex-fill {
	flex: 1 1 auto !important;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-grow-0 {
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	flex-shrink: 1 !important;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.gap-0 {
	gap: 0 !important;
}

.gap-1 {
	gap: 0.25rem !important;
}

.gap-2 {
	gap: 0.5rem !important;
}

.gap-3 {
	gap: 1rem !important;
}

.gap-4 {
	gap: 1.5rem !important;
}

.gap-5 {
	gap: 3rem !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.justify-content-evenly {
	justify-content: space-evenly !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.align-content-start {
	align-content: flex-start !important;
}

.align-content-end {
	align-content: flex-end !important;
}

.align-content-center {
	align-content: center !important;
}

.align-content-between {
	align-content: space-between !important;
}

.align-content-around {
	align-content: space-around !important;
}

.align-content-stretch {
	align-content: stretch !important;
}

.align-self-auto {
	align-self: auto !important;
}

.align-self-start {
	align-self: flex-start !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.align-self-baseline {
	align-self: baseline !important;
}

.align-self-stretch {
	align-self: stretch !important;
}

.order-first {
	order: -1 !important;
}

.order-0 {
	order: 0 !important;
}

.order-1 {
	order: 1 !important;
}

.order-2 {
	order: 2 !important;
}

.order-3 {
	order: 3 !important;
}

.order-4 {
	order: 4 !important;
}

.order-5 {
	order: 5 !important;
}

.order-last {
	order: 6 !important;
}

.m-0 {
	margin: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important;
}

.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}

.me-0 {
	margin-right: 0 !important;
}

.me-1 {
	margin-right: 0.25rem !important;
}

.me-2 {
	margin-right: 0.5rem !important;
}

.me-3 {
	margin-right: 1rem !important;
}

.me-4 {
	margin-right: 1.5rem !important;
}

.me-5 {
	margin-right: 3rem !important;
}

.me-auto {
	margin-right: auto !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ms-0 {
	margin-left: 0 !important;
}

.ms-1 {
	margin-left: 0.25rem !important;
}

.ms-2 {
	margin-left: 0.5rem !important;
}

.ms-3 {
	margin-left: 1rem !important;
}

.ms-4 {
	margin-left: 1.5rem !important;
}

.ms-5 {
	margin-left: 3rem !important;
}

.ms-auto {
	margin-left: auto !important;
}

.p-0 {
	padding: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}

.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}

.pe-0 {
	padding-right: 0 !important;
}

.pe-1 {
	padding-right: 0.25rem !important;
}

.pe-2 {
	padding-right: 0.5rem !important;
}

.pe-3 {
	padding-right: 1rem !important;
}

.pe-4 {
	padding-right: 1.5rem !important;
}

.pe-5 {
	padding-right: 3rem !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pb-1 {
	padding-bottom: 0.25rem !important;
}

.pb-2 {
	padding-bottom: 0.5rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}

.ps-0 {
	padding-left: 0 !important;
}

.ps-1 {
	padding-left: 0.25rem !important;
}

.ps-2 {
	padding-left: 0.5rem !important;
}

.ps-3 {
	padding-left: 1rem !important;
}

.ps-4 {
	padding-left: 1.5rem !important;
}

.ps-5 {
	padding-left: 3rem !important;
}

.font-monospace {
	font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
	font-size: calc(1.575rem + 3.9vw) !important;
}

.fs-2 {
	font-size: calc(1.485rem + 2.82vw) !important;
}

.fs-3 {
	font-size: calc(1.44rem + 2.28vw) !important;
}

.fs-4 {
	font-size: calc(1.395rem + 1.74vw) !important;
}

.fs-5 {
	font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-6 {
	font-size: calc(1.305rem + 0.66vw) !important;
}

.fst-italic {
	font-style: italic !important;
}

.fst-normal {
	font-style: normal !important;
}

.fw-light {
	font-weight: 300 !important;
}

.fw-lighter {
	font-weight: lighter !important;
}

.fw-normal {
	font-weight: 400 !important;
}

.fw-bold {
	font-weight: 700 !important;
}

.fw-bolder {
	font-weight: bolder !important;
}

.lh-1 {
	line-height: 1 !important;
}

.lh-sm {
	line-height: 1.25 !important;
}

.lh-base {
	line-height: 1.6666666667 !important;
}

.lh-lg {
	line-height: 2 !important;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-decoration-underline {
	text-decoration: underline !important;
}

.text-decoration-line-through {
	text-decoration: line-through !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
	word-wrap: break-word !important;
	word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
	color: #249dbd !important;
}

.text-secondary {
	color: #6c757d !important;
}

.text-success {
	color: #5eb454 !important;
}

.text-info {
	color: #0dcaf0 !important;
}

.text-warning {
	color: #fddd19 !important;
}

.text-danger {
	color: #d62c2d !important;
}

.text-light {
	color: #f8f9fa !important;
}

.text-dark {
	color: #212529 !important;
}

.text-white {
	color: #fff !important;
}

.text-body {
	color: #212529 !important;
}

.text-muted {
	color: #6c757d !important;
}

.text-black-50 {
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
	color: inherit !important;
}

.bg-primary {
	background-color: #249dbd !important;
}

.bg-secondary {
	background-color: #6c757d !important;
}

.bg-success {
	background-color: #5eb454 !important;
}

.bg-info {
	background-color: #0dcaf0 !important;
}

.bg-warning {
	background-color: #fddd19 !important;
}

.bg-danger {
	background-color: #d62c2d !important;
}

.bg-light {
	background-color: #f8f9fa !important;
}

.bg-dark {
	background-color: #212529 !important;
}

.bg-body {
	background-color: #024d69 !important;
}

.bg-white {
	background-color: #fff !important;
}

.bg-transparent {
	background-color: transparent !important;
}

.bg-gradient {
	background-image: var(--bs-gradient) !important;
}

.user-select-all {
	user-select: all !important;
}

.user-select-auto {
	user-select: auto !important;
}

.user-select-none {
	user-select: none !important;
}

.pe-none {
	pointer-events: none !important;
}

.pe-auto {
	pointer-events: auto !important;
}

.rounded {
	border-radius: 0.25rem !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.rounded-1 {
	border-radius: 0.2rem !important;
}

.rounded-2 {
	border-radius: 0.25rem !important;
}

.rounded-3 {
	border-radius: 0.3rem !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-pill {
	border-radius: 50rem !important;
}

.rounded-top {
	border-top-left-radius: 0.25rem !important;
	border-top-right-radius: 0.25rem !important;
}

.rounded-end {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
	border-bottom-right-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
	border-bottom-left-radius: 0.25rem !important;
	border-top-left-radius: 0.25rem !important;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}

@media (min-width: 375px) {
	.float-sm-start {
		float: left !important;
	}
	.float-sm-end {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-grid {
		display: grid !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: inline-flex !important;
	}
	.d-sm-none {
		display: none !important;
	}
	.flex-sm-fill {
		flex: 1 1 auto !important;
	}
	.flex-sm-row {
		flex-direction: row !important;
	}
	.flex-sm-column {
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-sm-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-sm-wrap {
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.gap-sm-0 {
		gap: 0 !important;
	}
	.gap-sm-1 {
		gap: 0.25rem !important;
	}
	.gap-sm-2 {
		gap: 0.5rem !important;
	}
	.gap-sm-3 {
		gap: 1rem !important;
	}
	.gap-sm-4 {
		gap: 1.5rem !important;
	}
	.gap-sm-5 {
		gap: 3rem !important;
	}
	.justify-content-sm-start {
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		justify-content: center !important;
	}
	.justify-content-sm-between {
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		justify-content: space-around !important;
	}
	.justify-content-sm-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-sm-start {
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		align-items: center !important;
	}
	.align-items-sm-baseline {
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		align-items: stretch !important;
	}
	.align-content-sm-start {
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		align-content: center !important;
	}
	.align-content-sm-between {
		align-content: space-between !important;
	}
	.align-content-sm-around {
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		align-self: auto !important;
	}
	.align-self-sm-start {
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		align-self: center !important;
	}
	.align-self-sm-baseline {
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		align-self: stretch !important;
	}
	.order-sm-first {
		order: -1 !important;
	}
	.order-sm-0 {
		order: 0 !important;
	}
	.order-sm-1 {
		order: 1 !important;
	}
	.order-sm-2 {
		order: 2 !important;
	}
	.order-sm-3 {
		order: 3 !important;
	}
	.order-sm-4 {
		order: 4 !important;
	}
	.order-sm-5 {
		order: 5 !important;
	}
	.order-sm-last {
		order: 6 !important;
	}
	.m-sm-0 {
		margin: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 1rem !important;
	}
	.m-sm-4 {
		margin: 1.5rem !important;
	}
	.m-sm-5 {
		margin: 3rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-sm-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-sm-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-sm-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-sm-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-sm-0 {
		margin-top: 0 !important;
	}
	.mt-sm-1 {
		margin-top: 0.25rem !important;
	}
	.mt-sm-2 {
		margin-top: 0.5rem !important;
	}
	.mt-sm-3 {
		margin-top: 1rem !important;
	}
	.mt-sm-4 {
		margin-top: 1.5rem !important;
	}
	.mt-sm-5 {
		margin-top: 3rem !important;
	}
	.mt-sm-auto {
		margin-top: auto !important;
	}
	.me-sm-0 {
		margin-right: 0 !important;
	}
	.me-sm-1 {
		margin-right: 0.25rem !important;
	}
	.me-sm-2 {
		margin-right: 0.5rem !important;
	}
	.me-sm-3 {
		margin-right: 1rem !important;
	}
	.me-sm-4 {
		margin-right: 1.5rem !important;
	}
	.me-sm-5 {
		margin-right: 3rem !important;
	}
	.me-sm-auto {
		margin-right: auto !important;
	}
	.mb-sm-0 {
		margin-bottom: 0 !important;
	}
	.mb-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-sm-3 {
		margin-bottom: 1rem !important;
	}
	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-sm-5 {
		margin-bottom: 3rem !important;
	}
	.mb-sm-auto {
		margin-bottom: auto !important;
	}
	.ms-sm-0 {
		margin-left: 0 !important;
	}
	.ms-sm-1 {
		margin-left: 0.25rem !important;
	}
	.ms-sm-2 {
		margin-left: 0.5rem !important;
	}
	.ms-sm-3 {
		margin-left: 1rem !important;
	}
	.ms-sm-4 {
		margin-left: 1.5rem !important;
	}
	.ms-sm-5 {
		margin-left: 3rem !important;
	}
	.ms-sm-auto {
		margin-left: auto !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 1rem !important;
	}
	.p-sm-4 {
		padding: 1.5rem !important;
	}
	.p-sm-5 {
		padding: 3rem !important;
	}
	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-sm-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-sm-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-sm-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-sm-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-sm-0 {
		padding-top: 0 !important;
	}
	.pt-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pt-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pt-sm-3 {
		padding-top: 1rem !important;
	}
	.pt-sm-4 {
		padding-top: 1.5rem !important;
	}
	.pt-sm-5 {
		padding-top: 3rem !important;
	}
	.pe-sm-0 {
		padding-right: 0 !important;
	}
	.pe-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pe-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pe-sm-3 {
		padding-right: 1rem !important;
	}
	.pe-sm-4 {
		padding-right: 1.5rem !important;
	}
	.pe-sm-5 {
		padding-right: 3rem !important;
	}
	.pb-sm-0 {
		padding-bottom: 0 !important;
	}
	.pb-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-sm-3 {
		padding-bottom: 1rem !important;
	}
	.pb-sm-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-sm-5 {
		padding-bottom: 3rem !important;
	}
	.ps-sm-0 {
		padding-left: 0 !important;
	}
	.ps-sm-1 {
		padding-left: 0.25rem !important;
	}
	.ps-sm-2 {
		padding-left: 0.5rem !important;
	}
	.ps-sm-3 {
		padding-left: 1rem !important;
	}
	.ps-sm-4 {
		padding-left: 1.5rem !important;
	}
	.ps-sm-5 {
		padding-left: 3rem !important;
	}
	.text-sm-start {
		text-align: left !important;
	}
	.text-sm-end {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.float-md-start {
		float: left !important;
	}
	.float-md-end {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-grid {
		display: grid !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: flex !important;
	}
	.d-md-inline-flex {
		display: inline-flex !important;
	}
	.d-md-none {
		display: none !important;
	}
	.flex-md-fill {
		flex: 1 1 auto !important;
	}
	.flex-md-row {
		flex-direction: row !important;
	}
	.flex-md-column {
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-md-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-md-wrap {
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.gap-md-0 {
		gap: 0 !important;
	}
	.gap-md-1 {
		gap: 0.25rem !important;
	}
	.gap-md-2 {
		gap: 0.5rem !important;
	}
	.gap-md-3 {
		gap: 1rem !important;
	}
	.gap-md-4 {
		gap: 1.5rem !important;
	}
	.gap-md-5 {
		gap: 3rem !important;
	}
	.justify-content-md-start {
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		justify-content: center !important;
	}
	.justify-content-md-between {
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		justify-content: space-around !important;
	}
	.justify-content-md-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-md-start {
		align-items: flex-start !important;
	}
	.align-items-md-end {
		align-items: flex-end !important;
	}
	.align-items-md-center {
		align-items: center !important;
	}
	.align-items-md-baseline {
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		align-items: stretch !important;
	}
	.align-content-md-start {
		align-content: flex-start !important;
	}
	.align-content-md-end {
		align-content: flex-end !important;
	}
	.align-content-md-center {
		align-content: center !important;
	}
	.align-content-md-between {
		align-content: space-between !important;
	}
	.align-content-md-around {
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		align-content: stretch !important;
	}
	.align-self-md-auto {
		align-self: auto !important;
	}
	.align-self-md-start {
		align-self: flex-start !important;
	}
	.align-self-md-end {
		align-self: flex-end !important;
	}
	.align-self-md-center {
		align-self: center !important;
	}
	.align-self-md-baseline {
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		align-self: stretch !important;
	}
	.order-md-first {
		order: -1 !important;
	}
	.order-md-0 {
		order: 0 !important;
	}
	.order-md-1 {
		order: 1 !important;
	}
	.order-md-2 {
		order: 2 !important;
	}
	.order-md-3 {
		order: 3 !important;
	}
	.order-md-4 {
		order: 4 !important;
	}
	.order-md-5 {
		order: 5 !important;
	}
	.order-md-last {
		order: 6 !important;
	}
	.m-md-0 {
		margin: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem !important;
	}
	.m-md-3 {
		margin: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-md-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-md-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-md-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-md-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-md-0 {
		margin-top: 0 !important;
	}
	.mt-md-1 {
		margin-top: 0.25rem !important;
	}
	.mt-md-2 {
		margin-top: 0.5rem !important;
	}
	.mt-md-3 {
		margin-top: 1rem !important;
	}
	.mt-md-4 {
		margin-top: 1.5rem !important;
	}
	.mt-md-5 {
		margin-top: 3rem !important;
	}
	.mt-md-auto {
		margin-top: auto !important;
	}
	.me-md-0 {
		margin-right: 0 !important;
	}
	.me-md-1 {
		margin-right: 0.25rem !important;
	}
	.me-md-2 {
		margin-right: 0.5rem !important;
	}
	.me-md-3 {
		margin-right: 1rem !important;
	}
	.me-md-4 {
		margin-right: 1.5rem !important;
	}
	.me-md-5 {
		margin-right: 3rem !important;
	}
	.me-md-auto {
		margin-right: auto !important;
	}
	.mb-md-0 {
		margin-bottom: 0 !important;
	}
	.mb-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-md-3 {
		margin-bottom: 1rem !important;
	}
	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-md-5 {
		margin-bottom: 3rem !important;
	}
	.mb-md-auto {
		margin-bottom: auto !important;
	}
	.ms-md-0 {
		margin-left: 0 !important;
	}
	.ms-md-1 {
		margin-left: 0.25rem !important;
	}
	.ms-md-2 {
		margin-left: 0.5rem !important;
	}
	.ms-md-3 {
		margin-left: 1rem !important;
	}
	.ms-md-4 {
		margin-left: 1.5rem !important;
	}
	.ms-md-5 {
		margin-left: 3rem !important;
	}
	.ms-md-auto {
		margin-left: auto !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.p-md-3 {
		padding: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-md-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-md-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-md-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-md-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-md-0 {
		padding-top: 0 !important;
	}
	.pt-md-1 {
		padding-top: 0.25rem !important;
	}
	.pt-md-2 {
		padding-top: 0.5rem !important;
	}
	.pt-md-3 {
		padding-top: 1rem !important;
	}
	.pt-md-4 {
		padding-top: 1.5rem !important;
	}
	.pt-md-5 {
		padding-top: 3rem !important;
	}
	.pe-md-0 {
		padding-right: 0 !important;
	}
	.pe-md-1 {
		padding-right: 0.25rem !important;
	}
	.pe-md-2 {
		padding-right: 0.5rem !important;
	}
	.pe-md-3 {
		padding-right: 1rem !important;
	}
	.pe-md-4 {
		padding-right: 1.5rem !important;
	}
	.pe-md-5 {
		padding-right: 3rem !important;
	}
	.pb-md-0 {
		padding-bottom: 0 !important;
	}
	.pb-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-md-3 {
		padding-bottom: 1rem !important;
	}
	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-md-5 {
		padding-bottom: 3rem !important;
	}
	.ps-md-0 {
		padding-left: 0 !important;
	}
	.ps-md-1 {
		padding-left: 0.25rem !important;
	}
	.ps-md-2 {
		padding-left: 0.5rem !important;
	}
	.ps-md-3 {
		padding-left: 1rem !important;
	}
	.ps-md-4 {
		padding-left: 1.5rem !important;
	}
	.ps-md-5 {
		padding-left: 3rem !important;
	}
	.text-md-start {
		text-align: left !important;
	}
	.text-md-end {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.float-lg-start {
		float: left !important;
	}
	.float-lg-end {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-grid {
		display: grid !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: inline-flex !important;
	}
	.d-lg-none {
		display: none !important;
	}
	.flex-lg-fill {
		flex: 1 1 auto !important;
	}
	.flex-lg-row {
		flex-direction: row !important;
	}
	.flex-lg-column {
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-lg-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.gap-lg-0 {
		gap: 0 !important;
	}
	.gap-lg-1 {
		gap: 0.25rem !important;
	}
	.gap-lg-2 {
		gap: 0.5rem !important;
	}
	.gap-lg-3 {
		gap: 1rem !important;
	}
	.gap-lg-4 {
		gap: 1.5rem !important;
	}
	.gap-lg-5 {
		gap: 3rem !important;
	}
	.justify-content-lg-start {
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		justify-content: center !important;
	}
	.justify-content-lg-between {
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		justify-content: space-around !important;
	}
	.justify-content-lg-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-lg-start {
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		align-items: center !important;
	}
	.align-items-lg-baseline {
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		align-items: stretch !important;
	}
	.align-content-lg-start {
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		align-content: center !important;
	}
	.align-content-lg-between {
		align-content: space-between !important;
	}
	.align-content-lg-around {
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		align-self: auto !important;
	}
	.align-self-lg-start {
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		align-self: center !important;
	}
	.align-self-lg-baseline {
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		align-self: stretch !important;
	}
	.order-lg-first {
		order: -1 !important;
	}
	.order-lg-0 {
		order: 0 !important;
	}
	.order-lg-1 {
		order: 1 !important;
	}
	.order-lg-2 {
		order: 2 !important;
	}
	.order-lg-3 {
		order: 3 !important;
	}
	.order-lg-4 {
		order: 4 !important;
	}
	.order-lg-5 {
		order: 5 !important;
	}
	.order-lg-last {
		order: 6 !important;
	}
	.m-lg-0 {
		margin: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-lg-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-lg-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-lg-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-lg-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-lg-0 {
		margin-top: 0 !important;
	}
	.mt-lg-1 {
		margin-top: 0.25rem !important;
	}
	.mt-lg-2 {
		margin-top: 0.5rem !important;
	}
	.mt-lg-3 {
		margin-top: 1rem !important;
	}
	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}
	.mt-lg-5 {
		margin-top: 3rem !important;
	}
	.mt-lg-auto {
		margin-top: auto !important;
	}
	.me-lg-0 {
		margin-right: 0 !important;
	}
	.me-lg-1 {
		margin-right: 0.25rem !important;
	}
	.me-lg-2 {
		margin-right: 0.5rem !important;
	}
	.me-lg-3 {
		margin-right: 1rem !important;
	}
	.me-lg-4 {
		margin-right: 1.5rem !important;
	}
	.me-lg-5 {
		margin-right: 3rem !important;
	}
	.me-lg-auto {
		margin-right: auto !important;
	}
	.mb-lg-0 {
		margin-bottom: 0 !important;
	}
	.mb-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}
	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}
	.mb-lg-auto {
		margin-bottom: auto !important;
	}
	.ms-lg-0 {
		margin-left: 0 !important;
	}
	.ms-lg-1 {
		margin-left: 0.25rem !important;
	}
	.ms-lg-2 {
		margin-left: 0.5rem !important;
	}
	.ms-lg-3 {
		margin-left: 1rem !important;
	}
	.ms-lg-4 {
		margin-left: 1.5rem !important;
	}
	.ms-lg-5 {
		margin-left: 3rem !important;
	}
	.ms-lg-auto {
		margin-left: auto !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-lg-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-lg-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-lg-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-lg-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-lg-0 {
		padding-top: 0 !important;
	}
	.pt-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pt-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pt-lg-3 {
		padding-top: 1rem !important;
	}
	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}
	.pt-lg-5 {
		padding-top: 3rem !important;
	}
	.pe-lg-0 {
		padding-right: 0 !important;
	}
	.pe-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pe-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pe-lg-3 {
		padding-right: 1rem !important;
	}
	.pe-lg-4 {
		padding-right: 1.5rem !important;
	}
	.pe-lg-5 {
		padding-right: 3rem !important;
	}
	.pb-lg-0 {
		padding-bottom: 0 !important;
	}
	.pb-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}
	.ps-lg-0 {
		padding-left: 0 !important;
	}
	.ps-lg-1 {
		padding-left: 0.25rem !important;
	}
	.ps-lg-2 {
		padding-left: 0.5rem !important;
	}
	.ps-lg-3 {
		padding-left: 1rem !important;
	}
	.ps-lg-4 {
		padding-left: 1.5rem !important;
	}
	.ps-lg-5 {
		padding-left: 3rem !important;
	}
	.text-lg-start {
		text-align: left !important;
	}
	.text-lg-end {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1340px) {
	.float-xl-start {
		float: left !important;
	}
	.float-xl-end {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-grid {
		display: grid !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: inline-flex !important;
	}
	.d-xl-none {
		display: none !important;
	}
	.flex-xl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xl-row {
		flex-direction: row !important;
	}
	.flex-xl-column {
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-xl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.gap-xl-0 {
		gap: 0 !important;
	}
	.gap-xl-1 {
		gap: 0.25rem !important;
	}
	.gap-xl-2 {
		gap: 0.5rem !important;
	}
	.gap-xl-3 {
		gap: 1rem !important;
	}
	.gap-xl-4 {
		gap: 1.5rem !important;
	}
	.gap-xl-5 {
		gap: 3rem !important;
	}
	.justify-content-xl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		justify-content: center !important;
	}
	.justify-content-xl-between {
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		justify-content: space-around !important;
	}
	.justify-content-xl-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-xl-start {
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		align-items: center !important;
	}
	.align-items-xl-baseline {
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		align-items: stretch !important;
	}
	.align-content-xl-start {
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		align-content: center !important;
	}
	.align-content-xl-between {
		align-content: space-between !important;
	}
	.align-content-xl-around {
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		align-self: auto !important;
	}
	.align-self-xl-start {
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		align-self: center !important;
	}
	.align-self-xl-baseline {
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		align-self: stretch !important;
	}
	.order-xl-first {
		order: -1 !important;
	}
	.order-xl-0 {
		order: 0 !important;
	}
	.order-xl-1 {
		order: 1 !important;
	}
	.order-xl-2 {
		order: 2 !important;
	}
	.order-xl-3 {
		order: 3 !important;
	}
	.order-xl-4 {
		order: 4 !important;
	}
	.order-xl-5 {
		order: 5 !important;
	}
	.order-xl-last {
		order: 6 !important;
	}
	.m-xl-0 {
		margin: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-xl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-xl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-xl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-xl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-xl-0 {
		margin-top: 0 !important;
	}
	.mt-xl-1 {
		margin-top: 0.25rem !important;
	}
	.mt-xl-2 {
		margin-top: 0.5rem !important;
	}
	.mt-xl-3 {
		margin-top: 1rem !important;
	}
	.mt-xl-4 {
		margin-top: 1.5rem !important;
	}
	.mt-xl-5 {
		margin-top: 3rem !important;
	}
	.mt-xl-auto {
		margin-top: auto !important;
	}
	.me-xl-0 {
		margin-right: 0 !important;
	}
	.me-xl-1 {
		margin-right: 0.25rem !important;
	}
	.me-xl-2 {
		margin-right: 0.5rem !important;
	}
	.me-xl-3 {
		margin-right: 1rem !important;
	}
	.me-xl-4 {
		margin-right: 1.5rem !important;
	}
	.me-xl-5 {
		margin-right: 3rem !important;
	}
	.me-xl-auto {
		margin-right: auto !important;
	}
	.mb-xl-0 {
		margin-bottom: 0 !important;
	}
	.mb-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-xl-3 {
		margin-bottom: 1rem !important;
	}
	.mb-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-xl-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xl-auto {
		margin-bottom: auto !important;
	}
	.ms-xl-0 {
		margin-left: 0 !important;
	}
	.ms-xl-1 {
		margin-left: 0.25rem !important;
	}
	.ms-xl-2 {
		margin-left: 0.5rem !important;
	}
	.ms-xl-3 {
		margin-left: 1rem !important;
	}
	.ms-xl-4 {
		margin-left: 1.5rem !important;
	}
	.ms-xl-5 {
		margin-left: 3rem !important;
	}
	.ms-xl-auto {
		margin-left: auto !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem !important;
	}
	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-xl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-xl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-xl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-xl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-xl-0 {
		padding-top: 0 !important;
	}
	.pt-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pt-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pt-xl-3 {
		padding-top: 1rem !important;
	}
	.pt-xl-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xl-5 {
		padding-top: 3rem !important;
	}
	.pe-xl-0 {
		padding-right: 0 !important;
	}
	.pe-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pe-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pe-xl-3 {
		padding-right: 1rem !important;
	}
	.pe-xl-4 {
		padding-right: 1.5rem !important;
	}
	.pe-xl-5 {
		padding-right: 3rem !important;
	}
	.pb-xl-0 {
		padding-bottom: 0 !important;
	}
	.pb-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pb-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-xl-5 {
		padding-bottom: 3rem !important;
	}
	.ps-xl-0 {
		padding-left: 0 !important;
	}
	.ps-xl-1 {
		padding-left: 0.25rem !important;
	}
	.ps-xl-2 {
		padding-left: 0.5rem !important;
	}
	.ps-xl-3 {
		padding-left: 1rem !important;
	}
	.ps-xl-4 {
		padding-left: 1.5rem !important;
	}
	.ps-xl-5 {
		padding-left: 3rem !important;
	}
	.text-xl-start {
		text-align: left !important;
	}
	.text-xl-end {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

@media (min-width: 1640px) {
	.float-xxl-start {
		float: left !important;
	}
	.float-xxl-end {
		float: right !important;
	}
	.float-xxl-none {
		float: none !important;
	}
	.d-xxl-inline {
		display: inline !important;
	}
	.d-xxl-inline-block {
		display: inline-block !important;
	}
	.d-xxl-block {
		display: block !important;
	}
	.d-xxl-grid {
		display: grid !important;
	}
	.d-xxl-table {
		display: table !important;
	}
	.d-xxl-table-row {
		display: table-row !important;
	}
	.d-xxl-table-cell {
		display: table-cell !important;
	}
	.d-xxl-flex {
		display: flex !important;
	}
	.d-xxl-inline-flex {
		display: inline-flex !important;
	}
	.d-xxl-none {
		display: none !important;
	}
	.flex-xxl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xxl-row {
		flex-direction: row !important;
	}
	.flex-xxl-column {
		flex-direction: column !important;
	}
	.flex-xxl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xxl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xxl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xxl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xxl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xxl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-xxl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xxl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xxl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.gap-xxl-0 {
		gap: 0 !important;
	}
	.gap-xxl-1 {
		gap: 0.25rem !important;
	}
	.gap-xxl-2 {
		gap: 0.5rem !important;
	}
	.gap-xxl-3 {
		gap: 1rem !important;
	}
	.gap-xxl-4 {
		gap: 1.5rem !important;
	}
	.gap-xxl-5 {
		gap: 3rem !important;
	}
	.justify-content-xxl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xxl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xxl-center {
		justify-content: center !important;
	}
	.justify-content-xxl-between {
		justify-content: space-between !important;
	}
	.justify-content-xxl-around {
		justify-content: space-around !important;
	}
	.justify-content-xxl-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-xxl-start {
		align-items: flex-start !important;
	}
	.align-items-xxl-end {
		align-items: flex-end !important;
	}
	.align-items-xxl-center {
		align-items: center !important;
	}
	.align-items-xxl-baseline {
		align-items: baseline !important;
	}
	.align-items-xxl-stretch {
		align-items: stretch !important;
	}
	.align-content-xxl-start {
		align-content: flex-start !important;
	}
	.align-content-xxl-end {
		align-content: flex-end !important;
	}
	.align-content-xxl-center {
		align-content: center !important;
	}
	.align-content-xxl-between {
		align-content: space-between !important;
	}
	.align-content-xxl-around {
		align-content: space-around !important;
	}
	.align-content-xxl-stretch {
		align-content: stretch !important;
	}
	.align-self-xxl-auto {
		align-self: auto !important;
	}
	.align-self-xxl-start {
		align-self: flex-start !important;
	}
	.align-self-xxl-end {
		align-self: flex-end !important;
	}
	.align-self-xxl-center {
		align-self: center !important;
	}
	.align-self-xxl-baseline {
		align-self: baseline !important;
	}
	.align-self-xxl-stretch {
		align-self: stretch !important;
	}
	.order-xxl-first {
		order: -1 !important;
	}
	.order-xxl-0 {
		order: 0 !important;
	}
	.order-xxl-1 {
		order: 1 !important;
	}
	.order-xxl-2 {
		order: 2 !important;
	}
	.order-xxl-3 {
		order: 3 !important;
	}
	.order-xxl-4 {
		order: 4 !important;
	}
	.order-xxl-5 {
		order: 5 !important;
	}
	.order-xxl-last {
		order: 6 !important;
	}
	.m-xxl-0 {
		margin: 0 !important;
	}
	.m-xxl-1 {
		margin: 0.25rem !important;
	}
	.m-xxl-2 {
		margin: 0.5rem !important;
	}
	.m-xxl-3 {
		margin: 1rem !important;
	}
	.m-xxl-4 {
		margin: 1.5rem !important;
	}
	.m-xxl-5 {
		margin: 3rem !important;
	}
	.m-xxl-auto {
		margin: auto !important;
	}
	.mx-xxl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-xxl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-xxl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-xxl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xxl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-xxl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xxl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-xxl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-xxl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-xxl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xxl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-xxl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-xxl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-xxl-0 {
		margin-top: 0 !important;
	}
	.mt-xxl-1 {
		margin-top: 0.25rem !important;
	}
	.mt-xxl-2 {
		margin-top: 0.5rem !important;
	}
	.mt-xxl-3 {
		margin-top: 1rem !important;
	}
	.mt-xxl-4 {
		margin-top: 1.5rem !important;
	}
	.mt-xxl-5 {
		margin-top: 3rem !important;
	}
	.mt-xxl-auto {
		margin-top: auto !important;
	}
	.me-xxl-0 {
		margin-right: 0 !important;
	}
	.me-xxl-1 {
		margin-right: 0.25rem !important;
	}
	.me-xxl-2 {
		margin-right: 0.5rem !important;
	}
	.me-xxl-3 {
		margin-right: 1rem !important;
	}
	.me-xxl-4 {
		margin-right: 1.5rem !important;
	}
	.me-xxl-5 {
		margin-right: 3rem !important;
	}
	.me-xxl-auto {
		margin-right: auto !important;
	}
	.mb-xxl-0 {
		margin-bottom: 0 !important;
	}
	.mb-xxl-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-xxl-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-xxl-3 {
		margin-bottom: 1rem !important;
	}
	.mb-xxl-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-xxl-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xxl-auto {
		margin-bottom: auto !important;
	}
	.ms-xxl-0 {
		margin-left: 0 !important;
	}
	.ms-xxl-1 {
		margin-left: 0.25rem !important;
	}
	.ms-xxl-2 {
		margin-left: 0.5rem !important;
	}
	.ms-xxl-3 {
		margin-left: 1rem !important;
	}
	.ms-xxl-4 {
		margin-left: 1.5rem !important;
	}
	.ms-xxl-5 {
		margin-left: 3rem !important;
	}
	.ms-xxl-auto {
		margin-left: auto !important;
	}
	.p-xxl-0 {
		padding: 0 !important;
	}
	.p-xxl-1 {
		padding: 0.25rem !important;
	}
	.p-xxl-2 {
		padding: 0.5rem !important;
	}
	.p-xxl-3 {
		padding: 1rem !important;
	}
	.p-xxl-4 {
		padding: 1.5rem !important;
	}
	.p-xxl-5 {
		padding: 3rem !important;
	}
	.px-xxl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-xxl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-xxl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-xxl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xxl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-xxl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-xxl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-xxl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-xxl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-xxl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xxl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-xxl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-xxl-0 {
		padding-top: 0 !important;
	}
	.pt-xxl-1 {
		padding-top: 0.25rem !important;
	}
	.pt-xxl-2 {
		padding-top: 0.5rem !important;
	}
	.pt-xxl-3 {
		padding-top: 1rem !important;
	}
	.pt-xxl-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xxl-5 {
		padding-top: 3rem !important;
	}
	.pe-xxl-0 {
		padding-right: 0 !important;
	}
	.pe-xxl-1 {
		padding-right: 0.25rem !important;
	}
	.pe-xxl-2 {
		padding-right: 0.5rem !important;
	}
	.pe-xxl-3 {
		padding-right: 1rem !important;
	}
	.pe-xxl-4 {
		padding-right: 1.5rem !important;
	}
	.pe-xxl-5 {
		padding-right: 3rem !important;
	}
	.pb-xxl-0 {
		padding-bottom: 0 !important;
	}
	.pb-xxl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-xxl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-xxl-3 {
		padding-bottom: 1rem !important;
	}
	.pb-xxl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-xxl-5 {
		padding-bottom: 3rem !important;
	}
	.ps-xxl-0 {
		padding-left: 0 !important;
	}
	.ps-xxl-1 {
		padding-left: 0.25rem !important;
	}
	.ps-xxl-2 {
		padding-left: 0.5rem !important;
	}
	.ps-xxl-3 {
		padding-left: 1rem !important;
	}
	.ps-xxl-4 {
		padding-left: 1.5rem !important;
	}
	.ps-xxl-5 {
		padding-left: 3rem !important;
	}
	.text-xxl-start {
		text-align: left !important;
	}
	.text-xxl-end {
		text-align: right !important;
	}
	.text-xxl-center {
		text-align: center !important;
	}
}

@media (min-width: 1200px) {
	.fs-1 {
		font-size: 4.5rem !important;
	}
	.fs-2 {
		font-size: 3.6rem !important;
	}
	.fs-3 {
		font-size: 3.15rem !important;
	}
	.fs-4 {
		font-size: 2.7rem !important;
	}
	.fs-5 {
		font-size: 2.25rem !important;
	}
	.fs-6 {
		font-size: 1.8rem !important;
	}
}

@media print {
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-grid {
		display: grid !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: flex !important;
	}
	.d-print-inline-flex {
		display: inline-flex !important;
	}
	.d-print-none {
		display: none !important;
	}
}

@font-face {
	font-family: "icomoon";
	src: url("../fonts/icomoon//icomoon.ttf?upzwh2") format("truetype"), url("../fonts/icomoon//icomoon.woff?upzwh2") format("woff"), url("../fonts/icomoon//icomoon.svg?upzwh2#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-reuse:before {
	content: "";
	color: #198098;
}

.icon-fix:before {
	content: "";
	color: #198098;
}

.icon-upload:before {
	content: "";
	color: #249dbd;
}

.icon-layers .path1:before {
	content: "";
	color: white;
}

.icon-layers .path2:before {
	content: "";
	margin-left: -0.74609375em;
	color: #249dbd;
}

.icon-layers .path3:before {
	content: "";
	margin-left: -0.74609375em;
	color: #249dbd;
}

.icon-arrow-down:before {
	content: "";
	color: #094d69;
}

.icon-home:before {
	content: "";
	color: #094d69;
}

.icon-target:before {
	content: "";
	color: #249dbd;
}

.icon-world:before {
	content: "";
	color: #094d69;
}

.icon-question-mark:before {
	content: "";
	color: #249dbd;
}

.icon-gear:before {
	content: "";
	color: #094d69;
}

.icon-map-pin:before {
	content: "";
	color: #094d69;
}

.icon-gasstaions .path1:before {
	content: "";
	color: #9e6831;
}

.icon-gasstaions .path2:before {
	content: "";
	margin-left: -1.6318359375em;
	color: white;
}

.icon-gasstaions .path3:before {
	content: "";
	margin-left: -1.6318359375em;
	color: #2859bc;
}

.icon-gasstaions .path4:before {
	content: "";
	margin-left: -1.6318359375em;
	color: #6d7878;
}

.icon-gasstaions .path5:before {
	content: "";
	margin-left: -1.6318359375em;
	color: #3fd055;
}

.icon-gasstaions .path6:before {
	content: "";
	margin-left: -1.6318359375em;
	color: white;
}

.icon-gasstaions .path7:before {
	content: "";
	margin-left: -1.6318359375em;
	color: #fddd19;
}

.icon-search:before {
	content: "";
	color: #249dbd;
}

@font-face {
	font-family: 'ProximaNova';
	src: url("../fonts/subset-ProximaNova-Semibold.woff2") format("woff2"), url("../fonts/subset-ProximaNova-Semibold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ProximaNova';
	src: url("../fonts/subset-ProximaNova-Medium.woff2") format("woff2"), url("../fonts/subset-ProximaNova-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ProximaNova';
	src: url("../fonts/proxima_nova_extrabold.woff2") format("woff2"), url("../fonts/proxima_nova_extrabold.woff") format("woff");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ProximaNova';
	src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

html {
	font-size: 10px;
}

@media (max-width: 1339.98px) {
	html {
		font-size: 9px;
	}
}

@media (max-width: 991.98px) {
	html {
		font-size: 8px;
	}
}

@media (max-width: 767.98px) {
	html {
		font-size: 10px;
	}
}

@media (max-width: 374.98px) {
	html {
		font-size: 9px;
	}
}

body {
	margin: 0;
	min-width: 320px;
	background: #024d69;
	color: #000;
	font: 100 1.8rem/1.6666666667 "ProximaNova", sans-serif;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	flex-direction: column;
}

body.menu-opened {
	overflow: hidden;
}

a {
	text-decoration: none;
	color: inherit;
	transition: all 0.3s ease;
}

a:hover {
	text-decoration: none;
	color: #094d69;
}

button,
input[type="search"],
input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: none;
	cursor: pointer;
	transition: all 0.3s ease;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
	border: none;
	padding: 0;
}

.wrapper {
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
	display: flex;
	flex-direction: column;
}

#main {
	flex-grow: 1;
}

.menu-opened #main {
	filter: blur(0.5rem);
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	font-family: "ProximaNova", sans-serif;
	color: #4e5a5a;
	position: relative;
	line-height: normal;
}

h1, .h1 {
	font-size: 6rem;
}

h2, .h2 {
	font-size: 4.5rem;
}

h3, .h3 {
	font-size: 2.3rem;
}

h4, .h4 {
	font-size: 2rem;
}

h5, .h5 {
	font-size: 1.8rem;
}

h6, .h6 {
	font-size: 1.4rem;
}

p {
	margin: 0;
}

input[type="submit"],
input[type="button"],
button,
.button,
.btn {
	display: inline-block;
	border: none;
	color: #249dbd;
	transition: all .3s ease-in;
	padding: 1.2rem 2rem;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: capitalize;
}

input[type="submit"]:hover,
input[type="button"],
button:hover,
.button:hover,
.btn:hover {
	background-color: #dbdada;
	color: #fff;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
textarea,
select {
	transition: all .3s ease-in;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

img {
	max-width: 100%;
	height: auto;
}

@media (min-width: 768px) {
	.col-md-20 {
		flex: 0 0 20%;
		width: 20%;
	}
}

.row-16 {
	margin-left: -8px;
	margin-right: -8px;
}

.row-16 > [class^="col"] {
	padding-left: 8px;
	padding-right: 8px;
}

@media (max-width: 767.98px) {
	.row-16 {
		margin-left: -5px;
		margin-right: -5px;
	}
	.row-16 > [class^="col"] {
		padding-left: 5px;
		padding-right: 5px;
	}
}

@media (max-width: 767.98px) {
	.hidden-header-mobile #header {
		display: none;
	}
}

@media (max-width: 767.98px) {
	.container {
		padding-left: 20px;
		padding-right: 20px;
	}
}

/* zoom-menu  */
.zoom-menu {
	position: fixed;
	bottom: 4.5rem;
	right: 3.5rem;
	z-index: 20;
	background-color: #fff;
	display: flex;
	border-radius: 99rem;
	padding: 0.2rem;
}

@media (max-width: 767.98px) {
	.zoom-menu {
		display: none;
	}
}

.zoom-menu .btn {
	width: 5.4rem;
	height: 5.4rem;
	border-radius: 50%;
	position: relative;
	text-indent: -9999px;
	overflow: hidden;
	padding: 0;
	margin: 0.3rem;
}

.zoom-menu .btn::after, .zoom-menu .btn::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-bottom: 0.3rem solid;
	height: 0;
	width: 2.6rem;
	border-radius: 0.3rem;
}

.zoom-menu .btn.zoom-out::after {
	display: none;
}

.zoom-menu .btn.zoom-in::after {
	transform: translate(-50%, -50%) rotate(90deg);
}

.menu-opened .zoom-menu {
	filter: blur(0.5rem);
}

/* buttons */
.btn {
	white-space: normal;
	border-radius: 99rem;
	border: none;
	font-size: 2.1rem;
	line-height: 1.4285714286;
	letter-spacing: 0;
	padding: 0.8rem 2.1rem;
	font-weight: 500;
}

.btn .fa {
	margin-left: 5px;
}

.btn-secondary {
	color: #158098;
	background-color: #f8f8f8;
}

.btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover {
	background-color: #efefef;
	color: #024d69;
}

.btn-upload {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5.4rem;
	height: 5.4rem;
	border-radius: 50%;
	position: relative;
	color: #249dbd;
}

.btn-upload::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	background: #fff;
	transition: all 0.3s ease-out;
}

.btn-upload::after {
	position: absolute;
	content: "";
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-family: "icomoon" !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 2.8rem;
	z-index: 3;
}

.btn-upload:hover {
	color: #094d69;
}

.btn-upload:hover::before {
	left: -0.5rem;
	top: -0.5rem;
	right: -0.5rem;
	bottom: -0.5rem;
}

@media (max-width: 767.98px) {
	.btn-upload {
		background-size: 20px auto;
	}
	.btn-upload.type2-sm {
		color: #fff;
	}
	.btn-upload.type2-sm::before {
		background: #198098;
	}
	.btn-upload.type2-sm:hover {
		color: #fff;
	}
}

.btn-location {
	display: flex;
	align-items: center;
	width: 171px;
	border: 1px solid #fff;
	border-radius: 99px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1.8rem;
	line-height: 1;
	padding: 5px 30px 5px 23px;
	color: #fff;
	text-decoration: none !important;
	position: relative;
	min-height: 54px;
}

@media (max-width: 767.98px) {
	.btn-location {
		font-size: 14px;
		line-height: 16px;
		min-height: 48px;
		width: 135px;
		padding-left: 15px;
	}
}

.btn-location::before {
	position: absolute;
	content: "";
	right: 10px;
	top: 50%;
	transform: translate(0, -50%);
	font-family: "icomoon" !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 3.2rem;
}

@media (max-width: 767.98px) {
	.btn-location::before {
		font-size: 28px;
	}
}

.btn-location:hover {
	background: #fff;
}

/* header */
#header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	right: 0;
}

@media (max-width: 991.98px) {
	#header {
		left: -7px;
	}
}

@media (max-width: 767.98px) {
	#header {
		left: 0;
	}
}

#header .header-link {
	padding: 1.3rem 5.7rem 1.4rem 2.6rem;
	border-radius: 4.2rem 0 0 4.2rem;
	background-color: #fff;
	font-size: 2.8rem;
	line-height: 1.2857142857;
	font-weight: 600;
	text-transform: uppercase;
	display: flex;
	color: #094d69;
	position: absolute;
	top: 5rem;
	right: -3rem;
	transition: all 0.3s ease-out;
}

@media (max-width: 767.98px) {
	#header .header-link {
		display: none;
	}
}

#header .header-link [class*="icon-"] {
	align-self: center;
	margin: -0.2rem 1.7rem -0.2rem 0;
	font-size: 1.1428571429em;
}

#header .header-link [class*="icon-"] span::before {
	transition: all 0.3s ease;
}

#header .header-link:hover {
	right: 0;
}

.menu-opened #header .header-link {
	filter: blur(0.5rem);
}

#header .open-menu {
	position: absolute;
	top: 5.5rem;
	left: 6.2rem;
	z-index: 150;
	width: 5.4rem;
	height: 5.4rem;
	border-radius: 50%;
	color: #249dbd;
}

#header .open-menu::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	background-color: #f8f8f8;
	transition: all 0.3s ease-out;
}

@media (max-width: 767.98px) {
	#header .open-menu {
		display: none;
	}
}

#header .open-menu span {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 42%;
	transform: translate(-50%, -50%);
	background: #249dbd;
	height: 3px;
	transition: all 0.3s ease;
	border-radius: 99px;
	z-index: 3;
}

#header .open-menu span:nth-child(1) {
	margin-top: -0.9rem;
}

#header .open-menu span:nth-child(3) {
	margin-top: 0.9rem;
}

.menu-opened #header .open-menu span {
	width: 50%;
}

.menu-opened #header .open-menu span:nth-child(1) {
	margin-top: 0;
	transform: translate(-50%, -50%) rotate(45deg);
}

.menu-opened #header .open-menu span:nth-child(2) {
	display: none;
}

.menu-opened #header .open-menu span:nth-child(3) {
	margin-top: 0;
	transform: translate(-50%, -50%) rotate(-45deg);
}

#header .open-menu:hover {
	color: #094d69;
}

#header .open-menu:hover span {
	background: #094d69;
}

#header .open-menu:hover::before {
	left: -0.5rem;
	top: -0.5rem;
	right: -0.5rem;
	bottom: -0.5rem;
}

/* .search-form */
.search-form {
	position: absolute;
	top: 5rem;
	left: 5.6rem;
	width: 38.5rem;
}

@media (max-width: 767.98px) {
	.search-form {
		top: 1.6rem;
		left: 1.6rem;
		width: auto;
		right: 1.6rem;
		z-index: 100;
		transition: filter 0.3s ease-out;
	}
	.open-filter .search-form {
		filter: blur(3px);
	}
}

.search-form .search-field .form-control {
	padding-left: 7.5rem;
}

@media (max-width: 767.98px) {
	.search-form .search-field .form-control {
		padding-left: 2.3rem;
	}
}

.menu-opened .search-form {
	transition: all 0.3s ease;
	opacity: 0;
}

.hidden-search .search-form {
	display: none;
}

.search-field {
	display: flex;
	background-color: #fff;
	border-radius: 99rem;
	position: relative;
}

.search-field.filled .btn-clear {
	opacity: 1;
	visibility: visible;
}

.search-field.filled .search-field .btn-submit-holder::before {
	opacity: 1;
}

.search-field .form-control {
	appearance: none;
	font-size: 2.4rem;
	font-weight: 500;
	display: block;
	height: auto;
	color: #094d69;
	background: none;
	border: none;
	height: 6.4rem;
	padding: 1.7rem 10rem 1.7rem 2rem;
	border-radius: 99rem;
}

@media (max-width: 767.98px) {
	.search-field .form-control {
		height: 5.4rem;
		font-size: 2.2rem;
		padding: 1.2rem 6rem 1.2rem 2rem;
	}
}

.search-field .form-control::placeholder {
	opacity: 0.3;
	color: #249dbd;
}

.search-field .btn-submit-holder {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 6.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 2.8rem;
	color: #249dbd;
}

.search-field .btn-submit-holder input[type="submit"] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-indent: -9999px;
	overflow: hidden;
	opacity: 0;
	transition: all 0.3s ease-out;
}

.search-field .btn-submit-holder::before {
	position: absolute;
	content: '';
	width: 1px;
	background: #efefef;
	left: 0;
	top: 10px;
	bottom: 10px;
	transition: all 0.3s ease-out;
	opacity: 0;
}

.search-field input[type="search"]::-webkit-search-decoration, .search-field input[type="search"]::-webkit-search-cancel-button, .search-field input[type="search"]::-webkit-search-results-button, .search-field input[type="search"]::-webkit-search-results-decoration {
	appearance: none;
}

.search-field .btn-clear {
	position: absolute;
	top: 50%;
	right: 6.2rem;
	width: 4rem;
	height: 4.5rem;
	border-right: 1px solid #efefef;
	transform: translateY(-50%);
	color: #bad9e0;
	opacity: 0;
	visibility: hidden;
}

.search-field .btn-clear::after, .search-field .btn-clear::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	width: 2.8rem;
	height: 0;
	border-bottom: 0.3rem solid;
	transform: translateY(-50%);
}

.search-field .btn-clear::before {
	transform: translateY(-50%) rotate(45deg);
}

.search-field .btn-clear::after {
	transform: translateY(-50%) rotate(-45deg);
}

.search-field .btn-clear input[type="reset"] {
	padding: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	opacity: 0;
	text-indent: -999px;
	overflow: hidden;
	border: none;
}

/* main-nav */
.main-nav-block {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
	width: 43.4rem;
	background-color: #fff;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	padding: 155px 6.2rem 30px;
	transform: translateX(-100%);
	transition: transform 0.3s;
}

@media (max-width: 767.98px) {
	.main-nav-block {
		display: none;
	}
}

.main-nav-block .main-nav {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 2.4rem;
	line-height: 1.25;
	font-weight: 500;
}

.main-nav-block .main-nav li {
	margin-bottom: 1.9rem;
}

.main-nav-block .main-nav a {
	display: block;
	padding: 12px 11px;
	color: #249dbd;
	display: flex;
	border-radius: 99rem;
	align-items: center;
}

.main-nav-block .main-nav a:not(:hover) [class*=icon-]::before {
	color: inherit;
}

.main-nav-block .main-nav a:not(:hover) [class*=icon-] span::before {
	color: inherit;
}

.main-nav-block .main-nav a:hover {
	color: #094d69;
	background-color: #f4f4f4;
	text-decoration: none;
}

.main-nav-block .main-nav a:hover .icon-question-mark::before {
	color: inherit;
}

.main-nav-block .main-nav [class*=icon-] {
	font-size: inherit;
	flex-shrink: 0;
	line-height: 1;
	min-width: 4rem;
	text-align: center;
	margin-right: 1.9rem;
	margin: -0.4rem 1.9rem -0.4rem 0;
}

.main-nav-block .main-nav [class*=icon-]::before {
	transition: all 0.3s ease;
}

.main-nav-block .main-nav [class*=icon-] span::before {
	transition: all 0.3s ease;
}

.menu-opened .main-nav-block {
	transform: translateX(0);
}

/* config-form */
.config-form {
	border-top: 0.1rem solid #dedede;
	margin: 1.6rem 0;
	padding: 3.6rem 0;
}

.config-form label {
	display: inline-block;
	vertical-align: top;
	color: #249dbd;
	text-transform: uppercase;
	font-size: 1.8rem;
	line-height: 1.3333333333;
	padding: 0 0.4rem;
	letter-spacing: 0.05555555556em;
	margin-bottom: 2.7rem;
}

.config-form .search-field {
	border: 1px solid #249dbd;
	color: #249dbd;
}

.config-form .search-field .form-control {
	height: 5.2rem;
	padding: 1.1rem 10rem 1.1rem 7.1rem;
}

.config-form .search-field .align-left {
	position: absolute;
	top: 50%;
	left: 1.5rem;
	transform: translateY(-50%);
	font-size: 2.9rem;
}

.config-form .search-field .align-left::before {
	color: inherit;
}

.select-location {
	border: 1px solid;
	border-radius: 99rem;
	background-color: #fff;
	display: flex;
	position: relative;
	color: #249dbd;
	font-size: 2.4rem;
	font-weight: 500;
	margin-bottom: 2.3rem;
	transition: all 0.3s ease-out;
}

.select-location select {
	border: none;
	padding: 0 5rem 0 6.7rem;
	margin: 0;
	background: none;
	border-radius: 99rem;
	appearance: none;
	font-size: inherit;
	font-weight: inherit;
	height: 5.2rem;
	color: inherit;
}

.select-location select::-ms-expand {
	display: none;
}

.select-location select:focus {
	color: inherit;
}

.select-location [class*=icon-] {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: inherit;
	z-index: 5;
	pointer-events: none;
}

.select-location [class*=icon-]::before {
	color: inherit;
}

.select-location .align-left {
	left: 1.7rem;
}

.select-location .align-right {
	right: 2.1rem;
}

.select-location.filled {
	color: #094d69;
}

.select-location:hover {
	color: #094d69;
}

.fader {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 95;
	background-color: #024d69;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
}

.menu-opened .fader {
	opacity: 0.7;
	visibility: visible;
}

@media (max-width: 767.98px) {
	.active-search .fader {
		opacity: 0.7;
		visibility: visible;
	}
}

/* footer */
#footer {
	position: fixed;
	width: 100%;
	left: 0;
	bottom: 0;
	z-index: 10;
	pointer-events: none;
}

.menu-opened #footer {
	filter: blur(0.5rem);
}

#footer [class*=col-] {
	position: relative;
}

#footer .footer-inf {
	background-color: #fff;
	border-radius: 2.5rem 2.5rem 0 0;
	bottom: 0;
	pointer-events: auto;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 80vh;
}

@media (min-width: 1340px) {
	#footer .footer-inf {
		margin: 0 3.6rem;
	}
}

@media (max-width: 1339.98px) {
	#footer .footer-inf {
		position: absolute;
		max-width: calc(100% - 180px);
	}
}

@media (max-width: 991.98px) {
	#footer .footer-inf {
		max-width: calc(100% - 140px);
	}
}

@media (max-width: 767.98px) {
	#footer .footer-inf {
		display: none;
	}
}

#footer .footer-inf .hold {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 1.7rem 2.5rem;
}

#footer .footer-inf .logos {
	list-style: none;
	padding: 0;
	margin: 0 0 0 -1.9rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

@media (max-width: 991.98px) {
	#footer .footer-inf .logos {
		margin-bottom: 10px;
	}
}

#footer .footer-inf .logos li {
	margin: 1rem 1.9rem;
}

#footer .footer-inf .logos figure {
	margin: 0;
}

#footer .footer-inf .logos img {
	opacity: 0;
	visibility: hidden;
}

#footer .footer-inf .logos img.lazyloaded {
	opacity: 1;
	visibility: visible;
}

#footer .footer-inf .more-logos {
	padding: 3rem 6rem;
}

@media (max-width: 991.98px) {
	#footer .footer-inf .more-logos {
		padding: 2rem;
	}
}

#footer .footer-inf .more-logos ul {
	margin: -6px -10px;
	padding: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	text-align: center;
	justify-content: center;
}

#footer .footer-inf .more-logos ul li {
	flex-shrink: 0;
	width: 25%;
	padding: 6px 10px;
}

#footer .footer-inf .more-logos ul li a {
	display: inline-block;
	vertical-align: top;
	text-decoration: none;
}

#footer .footer-inf .more-logos ul li a:hover {
	opacity: 0.7;
}

#footer .footer-inf .more-logos ul li img {
	display: block;
	max-width: 100%;
	height: auto;
}

/* zoom-grid */
.zoom-grid {
	width: 100%;
	height: 100%;
	overflow: auto;
	position: absolute;
	top: 0;
	left: 0;
}

.zoom-grid .block-grid {
	padding: 1.3rem;
	display: flex;
	flex-wrap: wrap;
	width: 380.6rem;
}

@media (max-width: 767.98px) {
	.zoom-grid .block-grid {
		padding: 1rem;
	}
}

.box-item {
	width: 19.4rem;
	height: 29.5rem;
	border-radius: 1.5rem;
	margin: 0.9rem;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 767.98px) {
	.box-item {
		width: 14.6rem;
		height: 22.3rem;
		margin: 0.6rem;
	}
}

.box-item figure {
	margin: 0;
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-radius: 1.5rem;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}

.box-item figure > img {
	position: absolute;
	top: -1rem;
	left: -1rem;
	right: -1rem;
	bottom: -1rem;
	width: calc(100% + 2rem);
	max-width: calc(100% + 2rem);
	height: calc(100% + 2rem);
	opacity: 0;
	visibility: hidden;
	object-fit: contain;
	z-index: 1;
}

.box-item figure > img.lazyloaded {
	opacity: 1;
	visibility: visible;
}

.box-item figure figcaption {
	max-width: 100%;
	text-align: center;
	font-size: 2rem;
	line-height: 1.1;
	color: #fff;
	font-weight: 600;
	padding: 1rem;
	position: relative;
	z-index: 5;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
}

@media (max-width: 767.98px) {
	.box-item figure figcaption {
		font-size: 1.8rem;
	}
}

.box-item img {
	vertical-align: top;
	transition: all 0.3s ease;
}

.box-item.blue .img, .box-item.blue {
	background-color: #249dbd;
}

.box-item.red .img, .box-item.red {
	background-color: #d62c2d;
}

.box-item.yellow .img, .box-item.yellow {
	background-color: #fddd19;
}

.box-item.gray .img, .box-item.gray {
	background-color: #bebebc;
}

.box-item.brown .img, .box-item.brown {
	background-color: #9e6831;
}

.box-item.green .img, .box-item.green {
	background-color: #3fd055;
}

.box-item.orange .img, .box-item.orange {
	background-color: #fca614;
}

.box-item.dark-green .img, .box-item.dark-green {
	background-color: #006f3f;
}

.box-item.light-green .img, .box-item.light-green {
	background-color: #b6e43b;
}

.box-item.dark-gray .img, .box-item.dark-gray {
	background-color: #2f3939;
}

.box-item .product-name-desc {
	opacity: 0;
	transition: opacity .3s ease-in;
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.box-item .product-name-desc h3, .box-item .product-name-desc .h3 {
	width: 70%;
	font-size: 2remx;
	line-height: 2.2rem;
	color: #fff;
	text-align: center;
}

.box-item:hover .product-name-desc {
	opacity: 1;
}

.box-item:hover figure > img {
	filter: blur(1rem);
	opacity: 0.2;
}

.box-item:hover figure figcaption {
	opacity: 1;
	visibility: visible;
}

.box-item.type2 {
	margin: 0 0 2rem;
	width: auto;
	height: auto;
	background: none !important;
	border-radius: 0;
}

.box-item.type2 figure {
	display: block;
	margin: 0;
	border-radius: 0;
}

.box-item.type2 .img {
	padding-bottom: 149%;
	position: relative;
	border-radius: 1.5rem;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}

.box-item.type2 .img > img {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	max-width: 100%;
	object-fit: cover;
	border-radius: 1.5rem;
	filter: none !important;
	transition: all 0.5s ease-out;
}

.box-item.type2 .img .not-img {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translate(0, -50%);
	text-transform: uppercase;
	font-weight: 600;
	padding: 5px 2rem;
	text-align: center;
	font-size: 2rem;
	line-height: 1.2;
	color: #fff;
	opacity: 0.5;
}

.box-item.type2 .img .not-img::after, .box-item.type2 .img .not-img::before {
	position: absolute;
	content: '';
	width: 2px;
	height: 9rem;
	background: #fff;
	left: 50%;
	transform: rotate(32deg);
}

.box-item.type2 .img .not-img::before {
	bottom: 100%;
	margin: 0 0 0 18px;
	transform-origin: 0 100%;
}

.box-item.type2 .img .not-img::after {
	top: 100%;
	margin: 0 0 0 -20px;
	transform-origin: 0 0;
}

.box-item.type2 .img.type2 {
	background: #fff;
}

.box-item.type2 .img.type2 .hold {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	padding: 10px 15px;
}

.box-item.type2 .img.type2 .ico {
	margin: 0 0 12px;
}

.box-item.type2 .img.type2 .txt {
	text-transform: uppercase;
	font-size: 1.6rem;
	line-height: 1;
	color: #198098;
	font-weight: 800;
}

@media (max-width: 374.98px) {
	.box-item.type2 .img.type2 .txt {
		font-size: 14px;
	}
}

.box-item.type2 .img.type2 .txt p {
	margin: 0;
}

.box-item.type2 .img.type2 ~ figcaption {
	font-weight: 800;
}

.box-item.type2 figcaption {
	opacity: 1;
	visibility: visible;
	text-align: left;
	padding: 5px 0;
}

@media (max-width: 767.98px) {
	.box-item.type2 figcaption {
		font-size: 20px;
	}
}

@media (max-width: 374.98px) {
	.box-item.type2 figcaption {
		font-size: 18px;
	}
}

.box-item.type2:not(.group):hover .img img {
	transform: scale(1.1);
}

.box-item.type2.group {
	padding-bottom: 16px;
}

@media (max-width: 767.98px) {
	.box-item.type2.group {
		padding-bottom: 11px;
	}
}

.box-item.type2.group figcaption {
	text-align: center;
}

@media (max-width: 767.98px) {
	.box-item.type2.group figcaption {
		text-align: left;
	}
}

.box-item.type2.group .img {
	background: none !important;
	position: relative;
}

.box-item.type2.group .img .ico {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 5.6rem;
	height: 5.6rem;
	border-radius: 50%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-out;
	font-size: 2.6rem;
	z-index: 10;
}

@media (max-width: 767.98px) {
	.box-item.type2.group .img .ico {
		font-size: 3rem;
	}
}

.box-item.type2.group .img .ico span {
	display: block;
}

.box-item.type2.group .img .ico span::before {
	color: inherit;
	margin: 0;
}

.box-item.type2.group .img .ico img {
	display: block;
	max-width: 100%;
	height: auto;
	min-width: 4.2rem;
	transition: all 0.3s ease-out;
}

@media (max-width: 767.98px) {
	.box-item.type2.group .img .ico img {
		width: 32px;
		min-width: 1px;
	}
}

.box-item.type2.group:hover .img .ico {
	color: #fff !important;
	transform: translate(-50%, -50%) scale(1.7);
}

.box-item.type2.group.blue .img .ico {
	color: #2859bc;
}

.box-item.type2.group.blue:hover .img .ico {
	background: #2859bc;
}

.box-item.type2.group.red .img .ico {
	color: #d62c2d;
}

.box-item.type2.group.red:hover .img .ico {
	background: #d62c2d;
}

.box-item.type2.group.yellow .img .ico {
	color: #fddd19;
}

.box-item.type2.group.yellow:hover .img .ico {
	background: #fddd19;
}

.box-item.type2.group.gray .img .ico {
	color: #6d7878;
}

.box-item.type2.group.gray:hover .img .ico {
	background: #6d7878;
}

.box-item.type2.group.dark-gray .img .ico {
	color: #2f3939;
}

.box-item.type2.group.dark-gray:hover .img .ico {
	background: #464646;
}

.box-item.type2.group.brown .img .ico {
	color: #9e6831;
}

.box-item.type2.group.brown:hover .img .ico {
	background: #9e6831;
}

.box-item.type2.group.green .img .ico {
	color: #3fd055;
}

.box-item.type2.group.green:hover .img .ico {
	background: #3fd055;
}

.box-item.type2.group.light-green .img .ico {
	color: #b7e53b;
}

.box-item.type2.group.light-green:hover .img .ico {
	background: #b7e53b;
}

.box-item.type2.group.orange .img .ico {
	color: #fca614;
}

.box-item.type2.group.orange:hover .img .ico {
	background: #fca614;
}

.box-item.type2.group.dark-green .img .ico {
	color: #006f3f;
}

.box-item.type2.group.dark-green:hover .img .ico {
	background: #006f3f;
}

.box-item.type2.group.dark-green:hover .img .ico img {
	filter: brightness(0) invert(1);
}

/* mobile-menu */
.mobile-menu {
	position: fixed;
	bottom: 4.4rem;
	left: 1.6rem;
	right: 1.6rem;
	justify-content: center;
	z-index: 100;
	display: none;
}

@media (max-width: 767.98px) {
	.mobile-menu {
		display: flex;
	}
}

.mobile-menu > ul {
	margin: 0;
	padding: 6px 5px;
	list-style: none;
	background: #fff;
	border-radius: 99px;
	display: flex;
	transition: all 0.3s ease-out;
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06);
}

.mobile-menu > ul.open-submenu {
	background: none;
}

.mobile-menu > ul.open-submenu > li:not(.open) {
	opacity: 0;
	visibility: hidden;
}

.mobile-menu > ul > li {
	flex-grow: 1;
	flex-basis: 0;
	min-width: 60px;
	margin: 0 2px;
	position: relative;
	transition: all 0.3s ease-out;
}

.mobile-menu > ul > li > a {
	display: block;
	text-align: center;
	border-radius: 99px;
	font-size: 2.4rem;
	line-height: 2.6rem;
	padding: 0.8rem;
	color: #249dbd;
	position: relative;
	z-index: 3;
}

.mobile-menu > ul > li > a::before {
	position: absolute;
	content: '';
	left: 50%;
	top: 50%;
	width: 58px;
	height: 58px;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	transition: all 0.3s ease-out;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}

.mobile-menu > ul > li > a *::before,
.mobile-menu > ul > li > a *::after,
.mobile-menu > ul > li > a * {
	color: inherit;
}

.mobile-menu > ul > li .opener {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	cursor: pointer;
}

.mobile-menu > ul > li:not(.active):not(.open) > a .icon-gasstaions > span::before {
	color: inherit;
}

.mobile-menu > ul > li.active > a {
	background: #f4f4f4;
	color: #094d69;
}

.mobile-menu > ul > li.open > a::before {
	opacity: 1;
	visibility: visible;
}

.mobile-menu > ul > li.open > ul {
	opacity: 1;
	pointer-events: auto;
}

.mobile-menu > ul > li ul {
	margin: 0;
	padding: 0;
	list-style: none;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	transition: all 0.3s ease-out;
	opacity: 0;
	pointer-events: none;
}

@media (max-width: 374.98px) {
	.mobile-menu > ul > li ul {
		transform: scale(0.8);
	}
}

.mobile-menu > ul > li ul::before {
	position: fixed;
	content: '';
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(2, 77, 105, 0.7);
}

@media (max-width: 374.98px) {
	.mobile-menu > ul > li ul::before {
		left: -9999px;
		top: -9999px;
		right: -9999px;
		bottom: -9999px;
	}
}

.mobile-menu > ul > li ul li .ico {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	width: 36px;
	height: 36px;
	border-radius: 50%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-out;
	font-size: 20px;
	z-index: 10;
}

.mobile-menu > ul > li ul li .ico a {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
}

.mobile-menu > ul > li ul li .ico span {
	display: block;
}

.mobile-menu > ul > li ul li .ico span::before {
	color: inherit;
	margin: 0;
}

.mobile-menu > ul > li ul li .ico img {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	max-width: 100%;
	height: auto;
	transition: all 0.3s ease-out;
}

.mobile-menu > ul > li ul li .ico-yellow {
	color: #fddd19;
}

.mobile-menu > ul > li ul li .ico-yellow:hover {
	background: #fddd19;
	color: #fff;
}

.mobile-menu > ul > li ul li .ico-green {
	color: #3fd055;
}

.mobile-menu > ul > li ul li .ico-green:hover {
	background: #3fd055;
	color: #fff;
}

.mobile-menu > ul > li ul li .ico-blue {
	color: #2859bc;
}

.mobile-menu > ul > li ul li .ico-blue:hover {
	background: #2859bc;
	color: #fff;
}

.mobile-menu > ul > li ul li .ico-brown {
	color: #9e6831;
}

.mobile-menu > ul > li ul li .ico-brown:hover {
	background: #9e6831;
	color: #fff;
}

.mobile-menu > ul > li ul li .ico-gray {
	color: #6d7878;
}

.mobile-menu > ul > li ul li .ico-gray:hover {
	background: #6d7878;
	color: #fff;
}

.mobile-menu > ul > li ul li .ico-red {
	color: #d62c2d;
}

.mobile-menu > ul > li ul li .ico-red:hover {
	background: #d62c2d;
	color: #fff;
}

.mobile-menu > ul > li ul li .ico-light-green {
	color: #b7e53b;
}

.mobile-menu > ul > li ul li .ico-light-green:hover {
	background: #b7e53b;
	color: #fff;
}

.mobile-menu > ul > li ul li .ico-orange {
	color: #fca614;
}

.mobile-menu > ul > li ul li .ico-orange:hover {
	background: #fca614;
	color: #fff;
}

.mobile-menu > ul > li ul li .ico-dark-gray {
	color: #2f3939;
}

.mobile-menu > ul > li ul li .ico-dark-gray:hover {
	background: #2f3939;
	color: #fff;
}

.mobile-menu > ul > li ul li .ico-dark-green {
	color: #006f3f;
}

.mobile-menu > ul > li ul li .ico-dark-green:hover {
	background: #006f3f;
	color: #fff;
}

.mobile-menu > ul > li ul li .ico:hover {
	transform: translate(-50%, 0) scale(1.3);
}

.mobile-menu > ul > li ul li .ico:hover img {
	filter: brightness(0) invert(1);
}

.mobile-menu > ul > li ul li .ico:hover a,
.mobile-menu > ul > li ul li .ico:hover ~ .text {
	opacity: 1;
	visibility: visible;
}

.mobile-menu > ul > li ul li .text {
	position: absolute;
	font-weight: bold;
	font-size: 32px;
	left: -104px;
	bottom: 209px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #fff;
	transition: all 0.3s ease-out;
	opacity: 0;
	visibility: hidden;
}

.mobile-menu > ul > li ul li:nth-child(1) .ico {
	margin: 0 0 0 -122px;
}

.mobile-menu > ul > li ul li:nth-child(2) .ico {
	margin: 0 0 43px -114px;
}

.mobile-menu > ul > li ul li:nth-child(3) .ico {
	margin: 0 0 81px -93px;
}

.mobile-menu > ul > li ul li:nth-child(4) .ico {
	margin: 0 0 110px -59px;
}

.mobile-menu > ul > li ul li:nth-child(5) .ico {
	margin: 0 0 125px -17px;
}

.mobile-menu > ul > li ul li:nth-child(6) .ico {
	margin: 0 0 125px 26px;
}

.mobile-menu > ul > li ul li:nth-child(7) .ico {
	margin: 0 0 110px 67px;
}

.mobile-menu > ul > li ul li:nth-child(8) .ico {
	margin: 0 0 81px 101px;
}

.mobile-menu > ul > li ul li:nth-child(9) .ico {
	margin: 0 0 44px 122px;
}

.mobile-menu > ul > li ul li:nth-child(10) .ico {
	margin: 0 0 0 130px;
}

/* content-section */
.content-section {
	padding: 25.2rem 0 15rem;
	color: #fff;
}

@media (max-width: 991.98px) {
	.content-section {
		padding: 20rem 0 18rem;
	}
}

@media (max-width: 767.98px) {
	.content-section {
		padding: 4.5rem 0 10rem;
	}
}

.content-section h1, .content-section .h1 {
	color: inherit;
	font-weight: bold;
}

@media (max-width: 767.98px) {
	.content-section h1, .content-section .h1 {
		font-size: 32px;
		margin-bottom: 12px;
	}
}

.content-section .holder {
	position: relative;
	margin: 0 3.5rem;
}

@media (max-width: 1339.98px) {
	.content-section .holder {
		margin: 0 10rem;
	}
}

@media (max-width: 991.98px) {
	.content-section .holder {
		margin: 0;
	}
}

.content-section .holder.columns-4 {
	max-width: 696px;
	margin: 0 auto;
}

@media (min-width: 768px) {
	.content-section .holder.columns-4 .col-md-20 {
		flex: 0 0 25%;
		width: 25%;
	}
}

@media (min-width: 1340px) {
	.content-section .holder.columns-6 {
		max-width: 696px;
		margin: 0 auto;
	}
}

@media (min-width: 1340px) {
	.content-section .holder.columns-6 > .row {
		margin: 0 -186px;
	}
}

@media (min-width: 1340px) {
	.content-section .holder.columns-6 .col-md-20 {
		flex: 0 0 16.6666%;
		width: 16.6666%;
	}
}

.content-section .holder.type2 {
	max-width: 636px;
	margin: -7.4rem auto 0;
}

@media (max-width: 1339.98px) {
	.content-section .holder.type2 {
		margin-top: 0;
	}
}

@media (max-width: 991.98px) {
	.content-section .holder.type2 {
		max-width: 560px;
		padding-top: 20px;
	}
}

@media (max-width: 767.98px) {
	.content-section .holder.type2 {
		max-width: none;
		padding-top: 0;
	}
}

.content-section .top-panel {
	position: absolute;
	left: 0;
	bottom: 100%;
	right: 0;
	display: flex;
	align-items: flex-end;
	padding: 0 0 1.8rem;
}

@media (max-width: 991.98px) {
	.content-section .top-panel {
		position: relative;
		margin-top: -7rem;
		bottom: auto;
	}
}

@media (max-width: 767.98px) {
	.content-section .top-panel {
		margin-top: -4rem;
		padding-bottom: 10px;
		display: block;
	}
}

.content-section .top-panel .btn-upload {
	margin-left: auto;
	margin-bottom: 1.2rem;
}

@media (max-width: 767.98px) {
	.content-section .top-panel .btn-upload {
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 20;
		margin: 25px 10px 0 0;
	}
}

.content-section .top-panel .btns-group {
	margin-left: auto;
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.2rem;
}

@media (max-width: 767.98px) {
	.content-section .top-panel .btns-group {
		margin-bottom: 0;
	}
}

.content-section .top-panel .btns-group .btn-upload {
	margin-left: 12px;
	margin-bottom: 0;
}

.content-section .top-panel .breadcrumbs {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 3.2rem;
	font-weight: bold;
}

.content-section .top-panel .breadcrumbs li {
	opacity: 0.5;
	transition: all 0.3s ease-out;
	padding: 0 0 1.2rem;
}

@media (max-width: 767.98px) {
	.content-section .top-panel .breadcrumbs li {
		padding-bottom: 3px;
	}
}

.content-section .top-panel .breadcrumbs li a {
	color: inherit;
	display: inline-flex;
	vertical-align: top;
	align-items: center;
}

@media (max-width: 767.98px) {
	.content-section .top-panel .breadcrumbs li a {
		display: flex;
		line-height: 1.2;
		position: relative;
	}
}

.content-section .top-panel .breadcrumbs li a .ico {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5.4rem;
	height: 5.4rem;
	border-radius: 50%;
	background: #fff;
	margin: 0 1.6rem 0 0;
	font-size: 2.4rem;
	color: #249dbd;
}

@media (max-width: 767.98px) {
	.content-section .top-panel .breadcrumbs li a .ico {
		display: none;
	}
}

.content-section .top-panel .breadcrumbs li a .ico::before {
	color: inherit;
}

.content-section .top-panel .breadcrumbs li a .ico.icon-gasstaions-single {
	font-size: inherit;
}

.content-section .top-panel .breadcrumbs li a .ico.icon-gasstaions-single span::before {
	margin: 0;
	color: inherit;
}

.content-section .top-panel .breadcrumbs li a .ico img {
	display: block;
	max-width: 100%;
	height: auto;
	min-width: 4.2rem;
}

@media (max-width: 767.98px) {
	.content-section .top-panel .breadcrumbs li a .ico img {
		width: 32px;
		min-width: 1px;
	}
}

.content-section .top-panel .breadcrumbs li a .ico-yellow {
	color: #fddd19;
}

.content-section .top-panel .breadcrumbs li a .ico-green {
	color: #3fd055;
}

.content-section .top-panel .breadcrumbs li a .ico-blue {
	color: #2859bc;
}

.content-section .top-panel .breadcrumbs li a .ico-brown {
	color: #9e6831;
}

.content-section .top-panel .breadcrumbs li a .ico-gray {
	color: #6d7878;
}

.content-section .top-panel .breadcrumbs li a .ico-red {
	color: #d62c2d;
}

.content-section .top-panel .breadcrumbs li a .ico-light-green {
	color: #b7e53b;
}

.content-section .top-panel .breadcrumbs li a .ico-orange {
	color: #fca614;
}

.content-section .top-panel .breadcrumbs li a .ico-dark-gray {
	color: #2f3939;
}

.content-section .top-panel .breadcrumbs li a .ico-dark-green {
	color: #006f3f;
}

.content-section .top-panel .breadcrumbs li:hover {
	opacity: 1;
}

.content-section .top-panel .breadcrumbs li.active {
	opacity: 1;
}

.content-section .top-panel .breadcrumbs li.active a {
	pointer-events: none;
}

@media (max-width: 767.98px) {
	.content-section .top-panel .breadcrumbs li.active a {
		padding-right: 60px;
	}
	.content-section .top-panel .breadcrumbs li.active a .ico {
		display: flex;
		position: absolute;
		right: 0;
		top: 3px;
		margin: 0;
		width: 40px;
		height: 40px;
		font-size: 24px;
	}
}

/* details */
.details {
	padding-bottom: 3rem;
}

@media (max-width: 767.98px) {
	.details {
		margin-left: -20px;
		margin-right: -20px;
	}
}

.details .block {
	display: flex;
	position: relative;
}

@media (max-width: 767.98px) {
	.details .block {
		display: block;
		max-width: 420px;
		margin-left: auto;
		margin-right: auto;
	}
}

.details .block + .block {
	margin-top: 31px;
}

@media (max-width: 767.98px) {
	.details .block + .block {
		margin-top: 0;
	}
}

.details .block .sub-bg {
	position: absolute;
	z-index: -1;
	left: 2px;
	top: 5px;
	bottom: 5px;
	right: 2px;
	transform: skewY(-21.5deg);
	transform-origin: 0 0;
	border-radius: 10px;
}

@media (max-width: 1339.98px) {
	.details .block .sub-bg {
		transform: skewY(-20deg);
	}
}

@media (max-width: 991.98px) {
	.details .block .sub-bg {
		transform: skewY(-21.3deg);
	}
}

@media (max-width: 767.98px) {
	.details .block .sub-bg {
		display: none;
	}
}

.details .block .cell {
	min-height: 22.2rem;
	border-radius: 10px;
	flex-shrink: 0;
	width: 100%;
	position: relative;
	z-index: 1;
}

@media (max-width: 991.98px) {
	.details .block .cell {
		min-height: 23.6rem;
	}
}

@media (max-width: 767.98px) {
	.details .block .cell {
		min-height: 1px;
	}
}

.details .block .cell .bg {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
}

@media (max-width: 767.98px) {
	.details .block .cell .bg {
		left: 0;
		right: 0;
	}
}

.details .block-1 .cell-1 {
	width: 60%;
}

@media (max-width: 767.98px) {
	.details .block-1 .cell-1 {
		width: auto;
		padding: 0 0 40px;
	}
}

.details .block-1 .cell-1 .bg-1 {
	left: 0;
	right: 50px;
	border-radius: 10px 0 0 10px;
}

@media (max-width: 767.98px) {
	.details .block-1 .cell-1 .bg-1 {
		right: 0;
		bottom: 70px;
		border-radius: 20px 20px 0 0;
	}
}

.details .block-1 .cell-1 .bg-2 {
	right: 0;
	width: 50px;
	mask-image: url("../images/bg-cell-01.svg");
	mask-position: 100% 50%;
	mask-size: auto 100%;
	mask-repeat: no-repeat;
}

@media (max-width: 767.98px) {
	.details .block-1 .cell-1 .bg-2 {
		width: auto;
		height: 70px;
		bottom: 0;
		top: auto;
		mask-image: url("../images/bg-cell-03.svg");
		mask-position: 50% 100%;
		mask-size: 100% auto;
	}
}

.details .block-1 .cell-2 {
	width: 40%;
}

@media (max-width: 767.98px) {
	.details .block-1 .cell-2 {
		width: auto;
	}
}

@media (max-width: 767.98px) {
	.details .block-1 .cell-2 .headline {
		top: 57px;
	}
}

.details .block-1 .cell-2 .bg-1 {
	right: 0;
	left: 40px;
	border-radius: 0 10px 10px 0;
}

@media (max-width: 767.98px) {
	.details .block-1 .cell-2 .bg-1 {
		left: 0;
		top: 30px;
		right: 0;
		bottom: 0;
		border-radius: 0;
	}
}

.details .block-1 .cell-2 .bg-2 {
	left: -15px;
	width: 55px;
	mask-image: url("../images/bg-cell-02.svg");
	mask-position: 0 50%;
	mask-size: auto 100%;
	mask-repeat: no-repeat;
}

@media (max-width: 767.98px) {
	.details .block-1 .cell-2 .bg-2 {
		display: none;
	}
}

.details .block-1 .cell-2 .bg-3 {
	left: 0;
	top: -30px;
	bottom: auto;
	right: 0;
	height: 60px;
	mask-image: url("../images/bg-cell-04.svg");
	mask-position: 50% 0;
	mask-size: 100% auto;
}

@media (max-width: 767.98px) {
	.details .block-2 .cell {
		background: none !important;
		padding-bottom: 40px;
	}
}

.details .block-2 .cell .headline {
	left: -1.6rem;
}

@media (max-width: 767.98px) {
	.details .block-2 .cell .headline {
		left: 0;
	}
}

@media (max-width: 767.98px) {
	.details .block-2 .cell .bg-1 {
		right: 0;
		left: 0;
		top: 0;
		bottom: 70px;
	}
}

@media (max-width: 767.98px) {
	.details .block-2 .cell .bg-2 {
		height: 70px;
		bottom: 0;
		top: auto;
		mask-image: url("../images/bg-cell-03.svg");
		mask-position: 50% 100%;
		mask-size: 100% auto;
		mask-repeat: no-repeat;
	}
}

.details .block-3 .cell-1 {
	width: 28%;
	z-index: 5;
}

@media (max-width: 767.98px) {
	.details .block-3 .cell-1 {
		width: auto;
	}
}

.details .block-3 .cell-1 .headline {
	left: -1.6rem;
	right: auto;
	white-space: nowrap;
}

@media (max-width: 767.98px) {
	.details .block-3 .cell-1 .headline {
		left: 0;
		top: 50px;
	}
}

.details .block-3 .cell-1 .bg-1 {
	left: 0;
	right: 50px;
	border-radius: 10px 0 0 10px;
}

@media (max-width: 767.98px) {
	.details .block-3 .cell-1 .bg-1 {
		display: none;
	}
}

.details .block-3 .cell-1 .bg-2 {
	right: 0;
	width: 50px;
	mask-image: url("../images/bg-cell-01.svg");
	mask-position: 100% 50%;
	mask-size: auto 100%;
	mask-repeat: no-repeat;
}

@media (max-width: 767.98px) {
	.details .block-3 .cell-1 .bg-2 {
		display: none;
	}
}

.details .block-3 .cell-2 {
	width: 72%;
}

@media (max-width: 767.98px) {
	.details .block-3 .cell-2 {
		width: auto;
		padding-top: 118px;
		padding-bottom: 60px;
	}
}

.details .block-3 .cell-2 .bg-1 {
	right: 40px;
	left: 40px;
}

@media (max-width: 767.98px) {
	.details .block-3 .cell-2 .bg-1 {
		left: 0;
		right: 0;
		top: 30px;
		bottom: 70px;
	}
}

.details .block-3 .cell-2 .bg-2 {
	left: -15px;
	width: 55px;
	mask-image: url("../images/bg-cell-02.svg");
	mask-position: 0 50%;
	mask-size: auto 100%;
	mask-repeat: no-repeat;
}

@media (max-width: 767.98px) {
	.details .block-3 .cell-2 .bg-2 {
		left: 0;
		top: -30px;
		bottom: auto;
		right: 0;
		height: 60px;
		mask-image: url("../images/bg-cell-04.svg");
		mask-position: 50% 0;
		mask-size: 100% auto;
		width: auto;
	}
}

.details .block-3 .cell-2 .bg-3 {
	right: -10px;
	width: 50px;
	mask-image: url("../images/bg-cell-01.svg");
	mask-position: 100% 50%;
	mask-size: auto 100%;
	mask-repeat: no-repeat;
}

@media (max-width: 767.98px) {
	.details .block-3 .cell-2 .bg-3 {
		height: 70px;
		bottom: 0;
		right: 0;
		left: 0;
		width: auto;
		top: auto;
		mask-image: url("../images/bg-cell-03.svg");
		mask-position: 50% 100%;
		mask-size: 100% auto;
		mask-repeat: no-repeat;
	}
}

.details .item-info {
	margin: 0;
	padding: 1.1rem;
	display: flex;
	color: #fff;
}

@media (max-width: 767.98px) {
	.details .item-info {
		padding: 28px 20px 20px;
		flex-wrap: wrap;
	}
}

.details .item-info .img {
	flex-shrink: 0;
	width: 13.1rem;
	margin: 0 1.3rem 0 0;
	position: relative;
	align-self: flex-start;
}

@media (max-width: 767.98px) {
	.details .item-info .img {
		width: 162px;
		margin: 0;
	}
}

@media (max-width: 374.98px) {
	.details .item-info .img {
		width: 130px;
	}
}

.details .item-info .img > img {
	display: block;
	width: 100%;
	height: auto;
	border-radius: 5px;
}

.details .item-info .img .lbl {
	position: absolute;
	bottom: 10px;
	right: 10px;
	max-width: 43px;
	z-index: 5;
}

.details .item-info .img .lbl img {
	max-width: 100%;
	display: block;
	height: auto;
}

.details .item-info h3, .details .item-info .h3 {
	font-size: 2.2rem;
	line-height: 1;
	font-weight: bold;
	color: inherit;
}

@media (max-width: 767.98px) {
	.details .item-info h3, .details .item-info .h3 {
		font-size: 32px;
		margin-bottom: 19px;
		flex: 0 0 100%;
		max-width: 100%;
		padding-right: 70px;
		min-height: 64px;
	}
}

.details .item-info figcaption {
	flex-grow: 1;
	width: 1%;
	min-width: 0;
	display: flex;
	flex-direction: column;
	padding-right: 20px;
}

@media (max-width: 767.98px) {
	.details .item-info figcaption {
		padding-left: 16px;
		padding-right: 0;
	}
}

@media (max-width: 767.98px) {
	.details .item-info figcaption h3, .details .item-info figcaption .h3 {
		display: none;
	}
}

.details .item-info figcaption p {
	margin: auto 0 8px;
	font-weight: 600;
	font-size: 1.8rem;
	line-height: 1.112;
}

@media (max-width: 767.98px) {
	.details .item-info figcaption p {
		margin-top: 0;
		font-size: 1.8rem;
		line-height: 20px;
	}
}

.details .item-info figcaption .btn-location {
	margin-top: 12px;
}

.details .headline {
	position: absolute;
	font-size: 1.8rem;
	line-height: 2rem;
	padding: 5px 9px;
	background: #fff;
	border-radius: 99px;
	text-transform: uppercase;
	top: 2rem;
	right: -1.6rem;
	font-weight: bold;
	text-align: center;
}

@media (max-width: 767.98px) {
	.details .headline {
		border-radius: 0 99px 99px 0;
		left: 0;
		right: auto;
		padding: 15px 18px 15px 11px;
	}
}

.details .plus {
	position: absolute;
	width: 4.3rem;
	height: 4.3rem;
	border-radius: 50%;
	border: 3px solid;
	top: 50%;
	right: 0;
	transform: translate(50%, -50%);
	text-decoration: none !important;
}

.details .plus i:nth-child(2),
.details .plus i:nth-child(1) {
	position: absolute;
	content: '';
	width: 18px;
	height: 3px;
	left: 50%;
	top: 50%;
	margin: -1.5px 0 0 -9px;
	border-radius: 4px;
}

.details .plus i:nth-child(2) {
	transform: rotate(90deg);
}

@media (max-width: 767.98px) {
	.details .plus {
		display: none;
	}
}

.details .category {
	padding: 2.5rem 2rem 1rem;
	text-align: center;
	position: relative;
}

@media (max-width: 767.98px) {
	.details .category {
		position: relative;
		padding: 13rem 20px 10px;
		text-align: left;
		font-size: 16px;
		line-height: 20px;
	}
}

.details .category .ico {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	font-size: 7rem;
	margin: 0 auto 1.2rem;
	position: relative;
	transition: all 0.3s ease-out;
}

@media (max-width: 991.98px) {
	.details .category .ico {
		width: 110px;
		height: 110px;
	}
}

@media (max-width: 767.98px) {
	.details .category .ico {
		width: 98px;
		height: 98px;
		font-size: 5.2rem;
		position: absolute;
		top: 14px;
		right: 24px;
	}
}

.details .category .ico img {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 80%;
}

.details .category .ico span::before {
	display: block;
	margin: 0;
	color: inherit;
}

.details .category h3, .details .category .h3 {
	display: block;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 2.2rem;
	color: inherit;
	margin: 0;
	line-height: 1.2;
	transition: all 0.3s ease-out;
}

@media (max-width: 767.98px) {
	.details .category h3, .details .category .h3 {
		font-size: 2.4rem;
		text-transform: none;
		margin: 0 0 10px;
	}
}

.details .category .text {
	font-size: 1.4rem;
	line-height: 1.3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 0.3s ease-out;
	opacity: 0;
	visibility: hidden;
	padding: 4rem 3rem 0;
	cursor: default;
}

@media (max-width: 767.98px) {
	.details .category .text {
		font-size: 16px;
		line-height: 20px;
		display: block;
		position: static;
		opacity: 1;
		visibility: visible;
		padding: 0;
	}
}

@media (min-width: 768px) {
	.details .category.hover h3, .details .category.hover .h3,
	.details .category.hover .ico {
		opacity: 0.2;
		filter: blur(5px);
	}
	.details .category.hover .text {
		opacity: 1;
		visibility: visible;
	}
}

.details .process {
	margin: 0 -18px;
	padding: 5.8rem 0 0.8rem;
	list-style: none;
	display: flex;
	justify-content: center;
	text-align: center;
}

@media (max-width: 767.98px) {
	.details .process {
		display: block;
		margin: 0;
		padding: 80px 20px 2rem;
		text-align: left;
	}
}

.details .process > li {
	flex-shrink: 0;
	width: 50%;
	padding: 0 30px;
	position: relative;
	display: flex;
	flex-direction: column;
}

@media (max-width: 767.98px) {
	.details .process > li {
		width: auto;
		padding: 0;
	}
}

@media (max-width: 767.98px) {
	.details .process > li + li {
		padding-top: 3.2rem;
	}
}

.details .process > li + li .arrow {
	position: absolute;
	width: 54px;
	height: 54px;
	top: 3.8rem;
	left: 0;
	transform: translate(-50%, 0);
	mask-image: url("../images/arrow-01.svg");
	mask-position: 50% 50%;
	mask-size: 54px 54px;
	mask-repeat: no-repeat;
}

@media (max-width: 767.98px) {
	.details .process > li + li .arrow {
		display: none;
	}
}

.details .process > li figure {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;
	transition: all 0.3s ease-out;
}

@media (max-width: 767.98px) {
	.details .process > li figure {
		display: block;
	}
}

.details .process > li figure .img {
	margin: 0 0 1rem;
	position: relative;
}

.details .process > li figure .img .plus {
	margin-top: 8px;
}

.details .process > li figure .img img {
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
	height: auto;
}

.details .process > li figure figcaption {
	color: inherit;
}

.details .process > li figure figcaption h3, .details .process > li figure figcaption .h3 {
	font-weight: bold;
	line-height: 1.2;
	color: inherit;
	font-size: inherit;
}

@media (max-width: 767.98px) {
	.details .process > li figure figcaption h3, .details .process > li figure figcaption .h3 {
		font-size: 24px;
		padding-top: 2px;
	}
}

@media (max-width: 767.98px) {
	.details .process > li figure figcaption h3 br, .details .process > li figure figcaption .h3 br {
		display: none;
	}
}

.details .process > li figure figcaption p {
	font-size: 16px;
	line-height: 20px;
	margin: 0;
}

.details .process > li .text {
	font-size: 1.4rem;
	line-height: 1.2857;
	padding: 0 6rem;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 0.3s ease-out;
	opacity: 0;
	visibility: hidden;
}

@media (max-width: 767.98px) {
	.details .process > li .text {
		padding: 0 0 20px;
		font-size: 16px;
		line-height: 20px;
		position: static;
		opacity: 1;
		visibility: visible;
		display: block;
	}
}

.details .process > li .text p {
	margin: 0;
}

@media (min-width: 768px) {
	.details .process > li.hover > figure {
		opacity: 0.2;
		filter: blur(5px);
	}
	.details .process > li.hover .text {
		opacity: 1;
		visibility: visible;
	}
}

.details .transform-items {
	margin: 0;
	padding: 6rem 2rem 3rem;
	list-style: none;
	display: flex;
	justify-content: center;
}

@media (max-width: 767.98px) {
	.details .transform-items {
		display: block;
		padding-top: 0;
		padding-bottom: 18px;
	}
}

.details .transform-items > li {
	flex-shrink: 0;
	width: 50%;
	padding: 0 15px;
}

@media (max-width: 767.98px) {
	.details .transform-items > li {
		width: auto;
		padding: 0;
	}
}

.details .transform {
	margin: 0;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

@media (max-width: 767.98px) {
	.details .transform {
		text-align: left;
		display: block;
	}
}

.details .transform .img {
	margin: 0 0 1rem;
	position: relative;
	transition: all 0.3s ease-out;
}

.details .transform .img img {
	display: inline-block;
	vertical-align: top;
	max-width: 100px;
	height: auto;
}

@media (max-width: 767.98px) {
	.details .transform .img img {
		min-width: 163px;
		max-width: 100%;
	}
}

.details .transform .img .plus {
	margin-top: 10px;
}

.details .transform h3, .details .transform .h3 {
	color: inherit;
	font-weight: bold;
	line-height: 1.2;
	font-size: inherit;
	margin: 0;
	font-family: inherit;
	transition: all 0.3s ease-out;
}

@media (max-width: 767.98px) {
	.details .transform h3, .details .transform .h3 {
		font-size: 2.4rem;
		margin-bottom: 6px;
	}
}

.details .transform .text {
	font-size: 1.4rem;
	line-height: 1.2857;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 0.3s ease-out;
	opacity: 0;
	visibility: hidden;
}

@media (max-width: 767.98px) {
	.details .transform .text {
		font-size: 16px;
		line-height: 20px;
		display: block;
		position: static;
		opacity: 1;
		visibility: visible;
	}
}

.details .transform p {
	margin: 0;
}

@media (min-width: 768px) {
	.details .transform.hover h3, .details .transform.hover .h3,
	.details .transform.hover .img {
		opacity: 0.2;
		filter: blur(5px);
	}
	.details .transform.hover .text {
		opacity: 1;
		visibility: visible;
	}
}

.details.type2 .item-info {
	align-items: center;
	padding: 2.8rem;
	color: inherit;
	margin-top: 6px;
}

@media (max-width: 767.98px) {
	.details.type2 .item-info {
		display: block;
		padding: 70px 20px 10px;
	}
}

.details.type2 .item-info .img {
	width: 20.9rem;
	margin-right: 1.5rem;
	padding-top: 10px;
}

@media (max-width: 767.98px) {
	.details.type2 .item-info .img {
		width: 196px;
		padding-top: 0;
		margin: 0;
	}
}

@media (max-width: 767.98px) {
	.details.type2 .item-info figcaption {
		width: auto;
		padding-left: 0;
		padding-right: 0;
	}
}

.details.type2 .item-info figcaption h3, .details.type2 .item-info figcaption .h3 {
	font-size: 2.6rem;
	line-height: 1.1;
}

@media (max-width: 767.98px) {
	.details.type2 .item-info figcaption h3, .details.type2 .item-info figcaption .h3 {
		display: block;
		padding-right: 0;
	}
}

.details.type2 .block-1 .cell-1 {
	width: 90%;
	display: flex;
	align-items: center;
}

@media (max-width: 767.98px) {
	.details.type2 .block-1 .cell-1 {
		width: auto;
	}
}

.details.type2 .block-1 .cell-2 {
	width: 10%;
}

@media (max-width: 767.98px) {
	.details.type2 .block-1 .cell-2 {
		width: auto;
		min-height: 40px;
	}
}

.details.type2 .block-1 .headline {
	right: auto;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	background: #198098;
	top: 0;
	padding-left: 20px;
	padding-right: 20px;
}

@media (max-width: 767.98px) {
	.details.type2 .block-1 .headline {
		transform: none;
		left: 0;
		top: 25px;
		padding-left: 13px;
	}
}

.details.type2 .block-2 .headline {
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%);
	right: auto;
	padding-left: 40px;
	padding-right: 40px;
}

@media (max-width: 767.98px) {
	.details.type2 .block-2 .headline {
		left: 0;
		top: 58px;
		transform: none;
		padding-left: 13px;
		padding-right: 20px;
	}
}

.details.type2 .block-2 .item-info {
	padding: 4.6rem 4rem;
}

@media (max-width: 991.98px) {
	.details.type2 .block-2 .item-info {
		padding: 3.6rem 4rem;
	}
}

@media (max-width: 767.98px) {
	.details.type2 .block-2 .item-info {
		position: relative;
		padding: 123px 20px 15px;
		margin: 0;
	}
}

.details.type2 .block-2 .item-info figcaption {
	order: -1;
	width: 50%;
	padding-left: 2rem;
	padding-right: 0;
}

@media (max-width: 767.98px) {
	.details.type2 .block-2 .item-info figcaption {
		padding-left: 0;
		width: auto;
	}
}

.details.type2 .block-2 .item-info figcaption h3, .details.type2 .block-2 .item-info figcaption .h3 {
	font-size: 1.8rem;
	line-height: 1.25;
}

.details.type2 .block-2 .item-info .img {
	width: 50%;
	margin: 0;
	text-align: center;
	padding-top: 0;
}

@media (max-width: 767.98px) {
	.details.type2 .block-2 .item-info .img {
		position: absolute;
		top: -6px;
		right: 70px;
		width: auto;
	}
}

.details.type2 .block-2 .item-info .img img {
	width: auto;
	display: inline-block;
	vertical-align: top;
}

.details.type2 .block-3 .cell-1 {
	width: 13%;
}

@media (max-width: 767.98px) {
	.details.type2 .block-3 .cell-1 {
		width: auto;
	}
}

.details.type2 .block-3 .cell-2 {
	width: 87%;
}

@media (max-width: 767.98px) {
	.details.type2 .block-3 .cell-2 {
		width: auto;
	}
}

.details.type2 .block-3 .headline {
	right: auto;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	background: #198098;
	top: 0;
	padding-left: 20px;
	padding-right: 20px;
}

@media (max-width: 767.98px) {
	.details.type2 .block-3 .headline {
		left: 0;
		top: 88px;
		transform: none;
		padding-left: 13px;
		padding-right: 20px;
	}
}

.details.type2 .block-3 .item-info {
	padding: 5.5rem 3rem 4rem 5px;
}

@media (max-width: 767.98px) {
	.details.type2 .block-3 .item-info {
		position: relative;
		padding: 38px 20px 8px;
		margin: 0;
	}
}

.details.type2 .block-3 .item-info figcaption {
	width: 50%;
	padding-left: 0;
	padding-right: 0;
}

@media (max-width: 767.98px) {
	.details.type2 .block-3 .item-info figcaption {
		width: auto;
		max-width: 242px;
	}
}

.details.type2 .block-3 .item-info figcaption h3, .details.type2 .block-3 .item-info figcaption .h3 {
	font-size: 1.8rem;
	line-height: 1.25;
}

@media (max-width: 767.98px) {
	.details.type2 .block-3 .item-info figcaption h3, .details.type2 .block-3 .item-info figcaption .h3 {
		margin-bottom: 0;
	}
}

.details.type2 .block-3 .item-info .img {
	width: 50%;
	margin: 0;
	text-align: center;
	padding-top: 0;
	padding-left: 10px;
}

@media (max-width: 767.98px) {
	.details.type2 .block-3 .item-info .img {
		position: absolute;
		top: -87px;
		right: 40px;
		width: auto;
	}
}

.details.type2 .block-3 .item-info .img img {
	width: auto;
	display: inline-block;
	vertical-align: top;
}

/* map-area */
.map-area {
	width: 100%;
	height: 100vh;
}

.map-area .map-block {
	width: 100%;
	height: 100%;
}

@media (max-width: 991.98px) {
	.map-area .map-block {
		height: calc(100% + 20rem);
	}
}

@media (max-width: 767.98px) {
	.map-area .map-block {
		transition: filter 0.3s ease-out;
	}
	.open-filter .map-area .map-block {
		filter: blur(3px);
	}
}

/* map-menu  */
.map-menu {
	position: fixed;
	left: 4.7rem;
	bottom: 4.2rem;
	display: flex;
	z-index: 10;
}

@media (max-width: 1339.98px) {
	.map-menu {
		left: 4rem;
		bottom: 13rem;
	}
}

@media (max-width: 991.98px) {
	.map-menu {
		bottom: 4.5rem;
	}
	.map-menu ~ #footer {
		display: none;
	}
}

@media (max-width: 767.98px) {
	.map-menu {
		top: 8rem;
		bottom: auto;
		left: auto;
		right: 16px;
		z-index: 110;
	}
}

.map-menu .map-overlay {
	display: none;
}

@media (max-width: 767.98px) {
	.map-menu .map-overlay {
		display: block;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		background-color: #094d69;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease;
		cursor: pointer;
	}
	.open-filter .map-menu .map-overlay {
		opacity: 0.5;
		visibility: visible;
	}
}

.map-menu .opener {
	background-color: #fff;
	width: 5.4rem;
	height: 5.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #249dbd;
	font-size: 3.4rem;
	position: relative;
}

@media (max-width: 767.98px) {
	.map-menu .opener {
		transition: filter 0.3s ease-out;
	}
	.open-filter .map-menu .opener {
		filter: blur(3px);
	}
}

.map-menu .opener::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #fff;
	transform: translate(-50%, -50%);
	transition: all 0.3s;
}

.map-menu .opener [class*='icon-'] {
	position: relative;
	z-index: 5;
}

.map-menu .opener [class*='icon-']::before {
	color: inherit;
}

.map-menu .opener [class*='icon-'] span::before {
	color: inherit;
}

.map-menu .opener [class*='icon-'] .path1 {
	opacity: 0;
	visibility: hidden;
}

.map-menu .opener:hover {
	color: #094d69;
}

.map-menu .opener:hover::before {
	transform: translate(-50%, -50%) scale(1.1851851852);
}

.map-menu .drop {
	background-color: #fff;
	border-radius: 2.7rem;
	width: 44.4rem;
	position: absolute;
	bottom: 100%;
	left: 0;
	list-style: none;
	padding: 1.4rem 0;
	margin: 0 0 1.7rem 0.3rem;
	transition: all 0.3s ease-out;
	opacity: 0;
	visibility: hidden;
}

@media (max-width: 767.98px) {
	.map-menu .drop {
		bottom: 0;
		top: auto;
		left: 0;
		right: 0;
		margin: 0;
		width: 100vw;
		border-radius: 2.7rem 2.7rem 0 0;
		position: fixed;
		z-index: 20;
	}
}

.open-filter .map-menu .drop {
	opacity: 1;
	visibility: visible;
}

.map-menu .drop li {
	position: relative;
}

.map-menu .drop li + li {
	margin-top: 1rem;
}

.map-menu .drop li + li::before {
	content: "";
	position: absolute;
	top: -0.4rem;
	left: 2.2rem;
	right: 2.2rem;
	height: 0;
	border-top: 0.1rem solid #ddd;
}

.map-menu .drop .holder {
	display: flex;
	align-items: center;
	padding: 1rem 2.2rem;
	color: #198099;
	font-size: 1.6rem;
	line-height: 1.7rem;
	font-weight: 500;
}

.map-menu .drop input {
	position: absolute;
	left: -9999px;
	opacity: 0;
}

.map-menu .drop input:checked ~ .sign {
	background-color: #198098;
}

.map-menu .drop input:checked ~ .sign::before {
	left: 100%;
	margin-left: -1.8rem;
}

.map-menu .drop .ico {
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
	flex-grow: 0;
	flex-shrink: 0;
	font-size: 2.3rem;
	color: #198098;
	border: 0.2rem solid;
}

.map-menu .drop .ico img {
	max-width: 80%;
	max-height: 80%;
	vertical-align: top;
}

.map-menu .drop .ico img.img-fullwidth {
	max-width: 100%;
	max-height: 100%;
}

.map-menu .drop .ico [class*='icon-']::before {
	color: inherit;
}

.map-menu .drop .ico [class*='icon-'] span::before {
	color: inherit;
}

.map-menu .drop .text {
	flex-grow: 1;
}

.map-menu .drop .text strong {
	display: block;
	font-size: 2rem;
	font-weight: 700;
	line-height: 1;
}

.map-menu .drop .text span {
	display: block;
}

.map-menu .drop .sign {
	width: 4rem;
	height: 2rem;
	border-radius: 99rem;
	position: relative;
	background-color: #efefef;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0 1.1rem;
	transition: background 0.3s;
}

.map-menu .drop .sign::before {
	content: "";
	position: absolute;
	top: 0.2rem;
	left: 0.2rem;
	width: 1.6rem;
	height: 1.6rem;
	border-radius: 50%;
	background-color: #fff;
	transition: left 0.3s;
}

.map-menu .drop .active .sign {
	background-color: #198098;
}

.map-menu .drop .active .sign::before {
	left: 100%;
	margin-left: -1.8rem;
}

.map-labels {
	position: fixed;
	flex-wrap: wrap;
	left: 10rem;
	right: 10rem;
	bottom: 11rem;
	display: flex;
	justify-content: center;
}

@media (max-width: 1339.98px) {
	.map-labels {
		bottom: 12rem;
	}
}

@media (max-width: 991.98px) {
	.map-labels {
		bottom: 3.5rem;
		left: 12rem;
		right: auto;
		max-width: calc(100% - 240px);
		justify-content: flex-start;
	}
}

@media (max-width: 767.98px) {
	.map-labels {
		display: none;
	}
}

.map-labels .label-el {
	margin: 0 0.5rem 1rem;
	background-color: #fff;
	display: none;
	align-items: center;
	border-radius: 99rem;
	color: #094d69;
	font-size: 1.8rem;
	line-height: 2.2rem;
	font-weight: 500;
	padding: 0.8rem 2rem 0.8rem 0.8rem;
}

.map-labels .label-el.active {
	display: flex;
}

.map-labels .label-el .ico {
	width: 3.8rem;
	height: 3.8rem;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 0.6rem;
	color: #198098;
	font-size: 2.3rem;
}

.map-labels .label-el .ico img {
	max-width: 80%;
	max-height: 80%;
	vertical-align: top;
}

.map-labels .label-el .ico img.img-fullwidth {
	max-width: 100%;
	max-height: 100%;
}

.map-labels .label-el .ico [class*='icon-']::before {
	color: inherit;
}

.map-labels .label-el .ico [class*='icon-'] span::before {
	color: inherit;
}

.info-window {
	width: 387px;
	background: #fff;
	border-radius: 25px;
	padding: 21px 27px;
	color: #6ecde6;
	font-family: "ProximaNova", sans-serif;
	font-weight: 500;
	position: relative;
}

@media (max-width: 991.98px) {
	.info-window {
		width: 300px;
		padding: 20px;
		border-radius: 20px;
	}
}

@media (max-width: 767.98px) {
	.info-window {
		width: 100%;
		border-radius: 10px;
	}
}

@media (max-width: 374.98px) {
	.info-window {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.info-window-type2 {
	color: #198099;
}

.info-window::before {
	position: absolute;
	content: '';
	width: 52px;
	height: 37px;
	left: 0;
	top: 100%;
	background: url("../images/arrow-info-window.svg") no-repeat;
	margin: -8px 0 0 38px;
}

@media (max-width: 767.98px) {
	.info-window::before {
		left: 50%;
		margin-left: -26px;
	}
}

.info-window .info-window-top {
	font-size: 16px;
	line-height: 18px;
	padding: 0 0 10px;
}

@media (max-width: 991.98px) {
	.info-window .info-window-top {
		font-size: 14px;
		padding-bottom: 5px;
	}
}

@media (max-width: 374.98px) {
	.info-window .info-window-top {
		font-size: 12px;
	}
}

.info-window .info-window-top .ttl {
	display: block;
	text-transform: uppercase;
	font-weight: bold;
	color: #094d69;
	margin: 0 0 15px;
	font-size: 22px;
	line-height: 1.2;
	padding-right: 20px;
}

@media (max-width: 991.98px) {
	.info-window .info-window-top .ttl {
		font-size: 20px;
		margin-bottom: 10px;
	}
}

@media (max-width: 767.98px) {
	.info-window .info-window-top .ttl {
		font-size: 16px;
	}
}

@media (max-width: 374.98px) {
	.info-window .info-window-top .ttl {
		font-size: 14px;
	}
}

.info-window .info-window-top p {
	margin: 0 0 15px;
}

.info-window .info-window-bottom {
	border-top: 1px solid #ddd;
	padding: 22px 0 5px;
	display: flex;
	align-items: center;
	font-size: 18px;
	line-height: 1;
}

@media (max-width: 991.98px) {
	.info-window .info-window-bottom {
		font-size: 14px;
	}
}

@media (max-width: 374.98px) {
	.info-window .info-window-bottom {
		font-size: 12px;
	}
}

.info-window .info-window-bottom .icon {
	flex-shrink: 0;
	width: 53px;
	height: 53px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 2px solid;
	margin-right: 14px;
	padding: 5px;
}

@media (max-width: 991.98px) {
	.info-window .info-window-bottom .icon {
		width: 50px;
		height: 50px;
	}
}

@media (max-width: 374.98px) {
	.info-window .info-window-bottom .icon {
		width: 40px;
		height: 40px;
	}
}

.info-window .info-window-bottom .icon img {
	display: block;
	max-width: 100%;
	height: auto;
	border-radius: 50%;
}

.gm-style-iw-c {
	background: none !important;
	padding: 0 !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	max-height: none !important;
	overflow: visible !important;
	transform: translate(-16%, -110%) !important;
}

@media (max-width: 767.98px) {
	.gm-style-iw-c {
		transform: translate(-50%, -110%) !important;
	}
}

.gm-style-iw-c > button {
	top: 10px !important;
	right: 10px !important;
}

@media (max-width: 767.98px) {
	.gm-style-iw-c > button {
		top: 5px !important;
		right: 5px !important;
	}
}

.gm-style-iw-c > button img {
	width: 20px !important;
	height: 20px !important;
	margin: 5px !important;
}

.gm-style-iw-t::after {
	display: none !important;
}

.gm-style-iw-d {
	overflow: visible !important;
	max-height: none !important;
}

.gm-style > div > div > div:nth-child(3)::before {
	content: '';
	position: absolute;
	top: -9999px;
	right: -9999px;
	left: -9999px;
	bottom: -9999px;
	background: #6ecde6;
	z-index: -1;
	opacity: 0.5;
	mix-blend-mode: multiply;
}
