/* zoom-grid */
.zoom-grid {
	width: 100%;
	height: 100%;
	overflow: auto;
	position: absolute;
	top: 0;
	left: 0;

	.block-grid {
		padding: 1.3rem;
		display: flex;
		flex-wrap: wrap;
		width: 380.6rem;

		@include media-breakpoint-down(md) {
			padding: 1rem;
		}
	}
}

.box-item {
	width: 19.4rem;
	height: 29.5rem;
	border-radius: 1.5rem;
	margin: 0.9rem;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	background-color: rgba($black, 0.5);

	@include media-breakpoint-down(md) {
		width: 14.6rem;
		height: 22.3rem;
		margin: 0.6rem;
	}

	figure {
		margin: 0;
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		border-radius: 1.5rem;
		-webkit-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);

		> img {
			position: absolute;
			top: -1rem;
			left: -1rem;
			right: -1rem;
			bottom: -1rem;
			width: calc(100% + 2rem);
			max-width: calc(100% + 2rem);
			height: calc(100% + 2rem);
			opacity: 0;
			visibility: hidden;
			object-fit: contain;
			z-index: 1;

			&.lazyloaded {
				opacity: 1;
				visibility: visible;
			}
		}

		figcaption {
			max-width: 100%;
			text-align: center;
			font-size: 2rem;
			line-height: 1.1;
			color: $white;
			font-weight: 600;
			padding: 1rem;
			position: relative;
			z-index: 5;
			opacity: 0;
			visibility: hidden;
			transition: $transition;

			@include media-breakpoint-down(md) {
				font-size: 1.8rem;
			}
		}
	}

	// @include media-breakpoint-down(sm) {
	// 	width: 14.7rem;
	// 	height: 22.3rem;
	// }

	img {
		vertical-align: top;
		transition: $transition;
	}

	&.blue .img,
	&.blue {
		background-color: $blue;
	}

	&.red .img,
	&.red {
		background-color: $red;
	}

	&.yellow .img,
	&.yellow {
		background-color: $yellow;
	}

	&.gray .img,
	&.gray {
		background-color: $light-gray;
	}

	&.brown .img,
	&.brown {
		background-color: $brown;
	}

	&.green .img,
	&.green {
		background-color: $middle-green;
	}

	&.orange .img,
	&.orange {
		background-color: $orange-3;
	}

	&.dark-green .img,
	&.dark-green {
		background-color: $dark-green-2;
	}

	&.light-green .img,
	&.light-green {
		background-color: $light-green;
	}

	&.dark-gray .img,
	&.dark-gray {
		background-color: $dark-gray;
	}

	.product-name-desc {
		opacity: 0;
		transition: opacity .3s ease-in;
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		h3 {
			width: 70%;
			font-size: 2remx;
			line-height: 2.2rem;
			color: $white;
			text-align: center;
		}
	}

	&:hover {
		.product-name-desc {
			opacity: 1;
		}

		figure > img {
			filter: blur(1rem);
			opacity: 0.2;
		}

		figure {
			figcaption {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&.type2 {
		margin: 0 0 2rem;
		width: auto;
		height: auto;
		background: none !important;
		border-radius: 0;

		figure {
			display: block;
			margin: 0;
			border-radius: 0;
		}

		.img {
			padding-bottom: 149%;
			position: relative;
			border-radius: 1.5rem;
			overflow: hidden;
			-webkit-backface-visibility: hidden;
			-webkit-transform: translate3d(0, 0, 0);

			> img {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				max-width: 100%;
				object-fit: cover;
				border-radius: 1.5rem;
				filter: none !important;
				transition: all 0.5s ease-out;
			}

			.not-img {
				position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				transform: translate(0, -50%);
				text-transform: uppercase;
				font-weight: 600;
				padding: 5px 2rem;
				text-align: center;
				font-size: 2rem;
				line-height: 1.2;
				color: $white;
				opacity: 0.5;

				&::after,
				&::before {
					position: absolute;
					content: '';
					width: 2px;
					height: 9rem;
					background: $white;
					left: 50%;
					transform: rotate(32deg);
				}

				&::before {
					bottom: 100%;
					margin: 0 0 0 18px;
					transform-origin: 0 100%;
				}

				&::after {
					top: 100%;
					margin: 0 0 0 -20px;
					transform-origin: 0 0;
				}
			}

			&.type2 {
				background: $white;

				.hold {
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					text-align: center;
					padding: 10px 15px;
				}

				.ico {
					margin: 0 0 12px;
				}

				.txt {
					text-transform: uppercase;
					font-size: 1.6rem;
					line-height: 1;
					color: $blue-3;
					font-weight: 800;

					@include media-breakpoint-down(sm) {
						font-size: 14px;
					}

					p {
						margin: 0;
					}
				}

				& ~ figcaption {
					font-weight: 800;
				}
			}
		}

		figcaption {
			opacity: 1;
			visibility: visible;
			text-align: left;
			padding: 5px 0;

			@include media-breakpoint-down(md) {
				font-size: 20px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 18px;
			}
		}

		&:not(.group):hover {
			.img {
				img {
					transform: scale(1.1);
				}
			}
		}

		&.group {
			padding-bottom: 16px;

			@include media-breakpoint-down(md) {
				padding-bottom: 11px;
			}

			figcaption {
				text-align: center;

				@include media-breakpoint-down(md) {
					text-align: left;
				}
			}

			.img {
				background: none !important;
				position: relative;

				.ico {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 5.6rem;
					height: 5.6rem;
					border-radius: 50%;
					background: $white;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: all 0.3s ease-out;
					font-size: 2.6rem;
					z-index: 10;

					@include media-breakpoint-down(md) {
						font-size: 3rem;
					}

					span {
						display: block;

						&::before {
							color: inherit;
							margin: 0;
						}
					}

					img {
						display: block;
						max-width: 100%;
						height: auto;
						min-width: 4.2rem;
						transition: all 0.3s ease-out;

						@include media-breakpoint-down(md) {
							width: 32px;
							min-width: 1px;
						}
					}
				}
			}

			&:hover {
				.img {
					.ico {
						color: $white !important;
						transform: translate(-50%, -50%) scale(1.7);
					}
				}
			}

			&.blue {
				.img {
					.ico {
						color: $blue-2;
					}
				}

				&:hover {
					.img {
						.ico {
							background: $blue-2;
						}
					}
				}
			}

			&.red {
				.img {
					.ico {
						color: $red;
					}
				}

				&:hover {
					.img {
						.ico {
							background: $red;
						}
					}
				}
			}

			&.yellow {
				.img {
					.ico {
						color: $yellow;
					}
				}

				&:hover {
					.img {
						.ico {
							background: $yellow;
						}
					}
				}
			}

			&.gray {
				.img {
					.ico {
						color: $gray-2;
					}
				}

				&:hover {
					.img {
						.ico {
							background: $gray-2;
						}
					}
				}
			}

			&.dark-gray {
				.img {
					.ico {
						color: $dark-gray;
					}
				}

				&:hover {
					.img {
						.ico {
							background: $gray-3;
						}
					}
				}
			}

			&.brown {
				.img {
					.ico {
						color: $brown;
					}
				}

				&:hover {
					.img {
						.ico {
							background: $brown;
						}
					}
				}
			}

			&.green {
				.img {
					.ico {
						color: $middle-green;
					}
				}

				&:hover {
					.img {
						.ico {
							background: $middle-green;
						}
					}
				}
			}

			&.light-green {
				.img {
					.ico {
						color: $green-light;
					}
				}

				&:hover {
					.img {
						.ico {
							background: $green-light;
						}
					}
				}
			}

			&.orange {
				.img {
					.ico {
						color: $orange-3;
					}
				}

				&:hover {
					.img {
						.ico {
							background: $orange-3;
						}
					}
				}
			}

			&.dark-green {
				.img {
					.ico {
						color: $dark-green-2;
					}
				}

				&:hover {
					.img {
						.ico {
							background: $dark-green-2;

							img {
								filter: brightness(0) invert(1);
							}
						}
					}
				}
			}
		}
	}
}