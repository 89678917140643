/* mobile-menu */
.mobile-menu {
	position: fixed;
	bottom: 4.4rem;
	left: 1.6rem;
	right: 1.6rem;
	justify-content: center;
	z-index: 100;
	display: none;

	@include media-breakpoint-down(md) {
		display: flex;
	}

	> ul {
		margin: 0;
		padding: 6px 5px;
		list-style: none;
		background: $white;
		border-radius: 99px;
		display: flex;
		transition: all 0.3s ease-out;
		box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06);

		&.open-submenu {
			background: none;

			> li:not(.open) {
				opacity: 0;
				visibility: hidden;
			}
		}

		> li {
			flex-grow: 1;
			flex-basis: 0;
			min-width: 60px;
			margin: 0 2px;
			position: relative;
			transition: all 0.3s ease-out;

			> a {
				display: block;
				text-align: center;
				border-radius: 99px;
				font-size: 2.4rem;
				line-height: 2.6rem;
				padding: 0.8rem;
				color: $blue;
				position: relative;
				z-index: 3;

				&::before {
					position: absolute;
					content: '';
					left: 50%;
					top: 50%;
					width: 58px;
					height: 58px;
					border-radius: 50%;
					transform: translate(-50%, -50%);
					background: $white;
					transition: all 0.3s ease-out;
					opacity: 0;
					visibility: hidden;
					z-index: -1;
				}

				*::before,
				*::after,
				* {
					color: inherit;
				}
			}

			.opener {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 10;
				cursor: pointer;
			}

			&:not(.active):not(.open) {
				> a {
					.icon-gasstaions {
						> span {
							&::before {
								color: inherit;
							}
						}
					}
				}
			}

			&.active {
				> a {
					background: $xlight-gray;
					color: $dark-blue;
				}
			}

			&.open {
				> a {
					&::before {
						opacity: 1;
						visibility: visible;
					}
				}

				> ul {
					opacity: 1;
					pointer-events: auto;
				}
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				transition: all 0.3s ease-out;
				opacity: 0;
				pointer-events: none;

				@include media-breakpoint-down(sm) {
					transform: scale(0.8);
				}

				&::before {
					position: fixed;
					content: '';
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					background-color: rgba($dark-blue2, 0.7);

					@include media-breakpoint-down(sm) {
						left: -9999px;
						top: -9999px;
						right: -9999px;
						bottom: -9999px;
					}
				}

				li {
					.ico {
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translate(-50%, 0);
						width: 36px;
						height: 36px;
						border-radius: 50%;
						background: $white;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: all 0.3s ease-out;
						font-size: 20px;
						z-index: 10;

						a {
							opacity: 0;
							visibility: hidden;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: 10;
						}

						span {
							display: block;

							&::before {
								color: inherit;
								margin: 0;
							}
						}

						img {
							position: absolute;
							left: 0;
							top: 0;
							right: 0;
							bottom: 0;
							margin: auto;
							max-width: 100%;
							height: auto;
							transition: all 0.3s ease-out;
						}

						&-yellow {
							color: $yellow;

							&:hover {
								background: $yellow;
								color: $white;
							}
						}

						&-green {
							color: $middle-green;

							&:hover {
								background: $middle-green;
								color: $white;
							}
						}

						&-blue {
							color: $blue-2;

							&:hover {
								background: $blue-2;
								color: $white;
							}
						}

						&-brown {
							color: $brown-2;

							&:hover {
								background: $brown-2;
								color: $white;
							}
						}

						&-gray {
							color: $gray-2;

							&:hover {
								background: $gray-2;
								color: $white;
							}
						}

						&-red {
							color: $red-2;

							&:hover {
								background: $red-2;
								color: $white;
							}
						}

						&-light-green {
							color: $green-light;

							&:hover {
								background: $green-light;
								color: $white;
							}
						}

						&-orange {
							color: $orange-2;

							&:hover {
								background: $orange-2;
								color: $white;
							}
						}

						&-dark-gray {
							color: $dark-gray;

							&:hover {
								background: $dark-gray;
								color: $white;
							}
						}

						&-dark-green {
							color: $dark-green;

							&:hover {
								background: $dark-green;
								color: $white;
							}
						}

						&:hover {
							transform: translate(-50%, 0) scale(1.3);

							img {
								filter: brightness(0) invert(1);
							}

							a,
							& ~ .text {
								opacity: 1;
								visibility: visible;
							}
						}
					}

					.text {
						position: absolute;
						font-weight: bold;
						font-size: 32px;
						left: -104px;
						bottom: 209px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						color: $white;
						transition: all 0.3s ease-out;
						opacity: 0;
						visibility: hidden;
					}

					&:nth-child(1) {
						.ico {
							margin: 0 0 0 -122px;
						}
					}

					&:nth-child(2) {
						.ico {
							margin: 0 0 43px -114px;
						}
					}

					&:nth-child(3) {
						.ico {
							margin: 0 0 81px -93px;
						}
					}

					&:nth-child(4) {
						.ico {
							margin: 0 0 110px -59px;
						}
					}

					&:nth-child(5) {
						.ico {
							margin: 0 0 125px -17px;
						}
					}

					&:nth-child(6) {
						.ico {
							margin: 0 0 125px 26px;
						}
					}

					&:nth-child(7) {
						.ico {
							margin: 0 0 110px 67px;
						}
					}

					&:nth-child(8) {
						.ico {
							margin: 0 0 81px 101px;
						}
					}

					&:nth-child(9) {
						.ico {
							margin: 0 0 44px 122px;
						}
					}

					&:nth-child(10) {
						.ico {
							margin: 0 0 0 130px;
						}
					}
				}
			}
		}
	}
}