/* footer */
#footer {
	position: fixed;
	width: 100%;
	left: 0;
	bottom: 0;
	z-index: 10;
	pointer-events: none;

	.menu-opened & {
		filter: blur(0.5rem);
	}

	[class*=col-] {
		position: relative;
	}

	.footer-inf {
		background-color: $white;
		border-radius: 2.5rem 2.5rem 0 0;
		//position: absolute;
		bottom: 0;
		pointer-events: auto;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 80vh;

		@include media-breakpoint-up(xl) {
			margin: 0 3.6rem;
		}

		@include media-breakpoint-down(xl) {
			position: absolute;
			max-width: calc(100% - 180px);
		}

		@include media-breakpoint-down(lg) {
			max-width: calc(100% - 140px);
		}

		@include media-breakpoint-down(md) {
			display: none;
		}

		.hold {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 1.7rem 2.5rem;
		}

		.logos {
			list-style: none;
			padding: 0;
			margin: 0 0 0 -1.9rem;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			@include media-breakpoint-down(lg) {
				margin-bottom: 10px;
			}

			li {
				margin: 1rem 1.9rem;
			}

			figure {
				margin: 0;
			}

			img {
				opacity: 0;
				visibility: hidden;

				&.lazyloaded {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.more-logos {
			padding: 3rem 6rem;

			@include media-breakpoint-down(lg) {
				padding: 2rem;
			}

			ul {
				margin: -6px -10px;
				padding: 0;
				list-style: none;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				text-align: center;
				justify-content: center;

				li {
					flex-shrink: 0;
					width: 25%;
					padding: 6px 10px;

					a {
						display: inline-block;
						vertical-align: top;
						text-decoration: none;

						&:hover {
							opacity: 0.7;
						}
					}

					img {
						display: block;
						max-width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}
