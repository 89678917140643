/* content-section */
.content-section {
	padding: 25.2rem 0 15rem;
	color: $white;

	@include media-breakpoint-down(lg) {
		padding: 20rem 0 18rem;
	}

	@include media-breakpoint-down(md) {
		padding: 4.5rem 0 10rem;
	}

	h1 {
		color: inherit;
		font-weight: bold;

		@include media-breakpoint-down(md) {
			font-size: 32px;
			margin-bottom: 12px;
		}
	}

	.holder {
		position: relative;
		margin: 0 3.5rem;

		@include media-breakpoint-down(xl) {
			margin: 0 10rem;
		}

		@include media-breakpoint-down(lg) {
			margin: 0;
		}

		&.columns-4 {
			max-width: 696px;
			margin: 0 auto;

			.col-md-20 {
				@include media-breakpoint-up(md) {
					flex: 0 0 25%;
					width: 25%;
				}
			}
		}

		&.columns-6 {
			@include media-breakpoint-up(xl) {
				max-width: 696px;
				margin: 0 auto;
			}

			> .row {
				@include media-breakpoint-up(xl) {
					margin: 0 -186px;
				}
			}

			.col-md-20 {
				@include media-breakpoint-up(xl) {
					flex: 0 0 16.6666%;
					width: 16.6666%;
				}
			}
		}

		&.type2 {
			max-width: 636px;
			margin: -7.4rem auto 0;

			@include media-breakpoint-down(xl) {
				margin-top: 0;
			}

			@include media-breakpoint-down(lg) {
				max-width: 560px;
				padding-top: 20px;
			}

			@include media-breakpoint-down(md) {
				max-width: none;
				padding-top: 0;
			}
		}
	}

	.top-panel {
		position: absolute;
		left: 0;
		bottom: 100%;
		right: 0;
		display: flex;
		align-items: flex-end;
		padding: 0 0 1.8rem;

		@include media-breakpoint-down(lg) {
			position: relative;
			margin-top: -7rem;
			bottom: auto;
		}

		@include media-breakpoint-down(md) {
			margin-top: -4rem;
			padding-bottom: 10px;
			display: block;
		}

		.btn-upload {
			margin-left: auto;
			margin-bottom: 1.2rem;

			@include media-breakpoint-down(md) {
				position: absolute;
				right: 0;
				top: 100%;
				z-index: 20;
				margin: 25px 10px 0 0;
			}
		}

		.btns-group {
			margin-left: auto;
			display: flex;
			align-items: flex-start;
			margin-bottom: 1.2rem;

			@include media-breakpoint-down(md) {
				margin-bottom: 0;
			}

			.btn-upload {
				margin-left: 12px;
				margin-bottom: 0;
			}
		}

		.breadcrumbs {
			margin: 0;
			padding: 0;
			list-style: none;
			font-size: 3.2rem;
			font-weight: bold;

			li {
				opacity: 0.5;
				transition: all 0.3s ease-out;
				padding: 0 0 1.2rem;

				@include media-breakpoint-down(md) {
					padding-bottom: 3px;
				}

				a {
					color: inherit;
					display: inline-flex;
					vertical-align: top;
					align-items: center;

					@include media-breakpoint-down(md) {
						display: flex;
						line-height: 1.2;
						position: relative;
					}

					.ico {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 5.4rem;
						height: 5.4rem;
						border-radius: 50%;
						background: $white;
						margin: 0 1.6rem 0 0;
						font-size: 2.4rem;
						color: $blue;

						@include media-breakpoint-down(md) {
							display: none;
						}

						&::before {
							color: inherit;
						}

						&.icon-gasstaions {
							&-single {
								font-size: inherit;

								span {
									&::before {
										margin: 0;
										color: inherit;
									}
								}
							}
						}

						img {
							display: block;
							max-width: 100%;
							height: auto;
							min-width: 4.2rem;

							@include media-breakpoint-down(md) {
								width: 32px;
								min-width: 1px;
							}
						}

						&-yellow {
							color: $yellow;
						}

						&-green {
							color: $middle-green;
						}

						&-blue {
							color: $blue-2;
						}

						&-brown {
							color: $brown-2;
						}

						&-gray {
							color: $gray-2;
						}

						&-red {
							color: $red-2;
						}

						&-light-green {
							color: $green-light;
						}

						&-orange {
							color: $orange-2;
						}

						&-dark-gray {
							color: $dark-gray;
						}

						&-dark-green {
							color: $dark-green;
						}
					}
				}

				&:hover {
					opacity: 1;
				}

				&.active {
					opacity: 1;

					a {
						pointer-events: none;

						@include media-breakpoint-down(md) {
							padding-right: 60px;

							.ico {
								display: flex;
								position: absolute;
								right: 0;
								top: 3px;
								margin: 0;
								width: 40px;
								height: 40px;
								font-size: 24px;
							}
						}
					}
				}
			}
		}
	}
}