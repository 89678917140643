/* header */
#header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	//width: 100%;
	right: 0;

	@include media-breakpoint-down(lg) {
		left: -7px;
	}

	@include media-breakpoint-down(md) {
		left: 0;
	}

	.header-link {
		padding: 1.3rem 5.7rem 1.4rem 2.6rem;
		border-radius: 4.2rem 0 0 4.2rem;
		background-color: $white;
		font-size: 2.8rem;
		line-height: 1.2857142857;
		font-weight: 600;
		text-transform: uppercase;
		display: flex;
		color: $dark-blue;
		position: absolute;
		top: 5rem;
		right: -3rem;
		transition: all 0.3s ease-out;

		@include media-breakpoint-down(md) {
			display: none;
		}

		[class*="icon-"] {
			align-self: center;
			margin: -0.2rem 1.7rem -0.2rem 0;
			font-size: 1.1428571429em;

			span {
				&::before {
					transition: $transition;
				}
			}
		}

		&:hover {
			//background-color: $xlight-gray;
			//color: $dark-blue;
			right: 0;

			[class*="icon-"] {
				span {
					&::before {
						//color: inherit;
					}
				}
			}
		}

		.menu-opened & {
			filter: blur(0.5rem);
		}
	}

	.open-menu {
		position: absolute;
		top: 5.5rem;
		left: 6.2rem;
		z-index: 150;
		width: 5.4rem;
		height: 5.4rem;
		border-radius: 50%;
		color: $blue;

		&::before {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border-radius: 50%;
			background-color: $xlight-gray2;
			transition: all 0.3s ease-out;
		}

		@include media-breakpoint-down(md) {
			display: none;
		}

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 42%;
			transform: translate(-50%, -50%);
			//border-bottom: 0.3rem solid;
			background: $blue;
			height: 3px;
			//height: 0;
			transition: $transition;
			border-radius: 99px;
			z-index: 3;

			@for $i from 1 through 3 {
				&:nth-child(#{$i}) {
					@if ($i == 1) {
						margin-top: -0.9rem;
					}
					@if ($i == 3) {
						margin-top: 0.9rem;
					}
				}
			}

			.menu-opened & {
				width: 50%;
				@for $i from 1 through 3 {
					&:nth-child(#{$i}) {
						@if ($i == 1) {
							margin-top: 0;
							transform: translate(-50%, -50%) rotate(45deg);
						}
						@if ($i == 3) {
							margin-top: 0;
							transform: translate(-50%, -50%) rotate(-45deg);
						}
						@if ($i == 2) {
							display: none;
						}
					}
				}
			}
		}

		&:hover {
			color: $dark-blue;

			span {
				background: $dark-blue;
			}

			&::before {
				left: -0.5rem;
				top: -0.5rem;
				right: -0.5rem;
				bottom: -0.5rem;
			}
		}
	}
}

/* .search-form */
.search-form {
	position: absolute;
	top: 5rem;
	left: 5.6rem;
	width: 38.5rem;

	@include media-breakpoint-down(md) {
		top: 1.6rem;
		left: 1.6rem;
		width: auto;
		right: 1.6rem;
		z-index: 100;
		transition: filter 0.3s ease-out;

		.open-filter & {
			filter: blur(3px);
		}
	}

	.search-field {
		.form-control {
			padding-left: 7.5rem;

			@include media-breakpoint-down(md) {
				padding-left: 2.3rem;
			}
		}
	}

	.menu-opened & {
		transition: $transition;
		opacity: 0;
	}

	.hidden-search & {
		display: none;
	}
}

.search-field {
	display: flex;
	background-color: $white;
	border-radius: 99rem;
	position: relative;

	&.filled {
		.btn-clear {
			opacity: 1;
			visibility: visible;
		}

		.search-field .btn-submit-holder::before {
			opacity: 1;
		}
	}

	.form-control {
		appearance: none;
		font-size: 2.4rem;
		font-weight: 500;
		display: block;
		height: auto;
		color: $dark-blue;
		background: none;
		border: none;
		height: 6.4rem;
		padding: 1.7rem 10rem 1.7rem 2rem;
		border-radius: 99rem;

		@include media-breakpoint-down(md) {
			height: 5.4rem;
			font-size: 2.2rem;
			padding: 1.2rem 6rem 1.2rem 2rem;
		}

		&::placeholder {
			opacity: 0.3;
			color: $blue;
		}
	}

	.btn-submit-holder {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 6.4rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 2.8rem;
		color: $blue;

		input[type="submit"] {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			text-indent: -9999px;
			overflow: hidden;
			opacity: 0;
			transition: all 0.3s ease-out;
		}

		&::before {
			position: absolute;
			content: '';
			width: 1px;
			background: $xlight-gray3;
			left: 0;
			top: 10px;
			bottom: 10px;
			transition: all 0.3s ease-out;
			opacity: 0;
		}
	}

	input[type="search"] {
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			appearance:none;
		}
	}

	.btn-clear {
		position: absolute;
		top: 50%;
		right: 6.2rem;
		width: 4rem;
		height: 4.5rem;
		border-right: 1px solid $xlight-gray3;
		transform: translateY(-50%);
		color: $light-blue2;
		opacity: 0;
		visibility: hidden;

		&::after,
		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			width: 2.8rem;
			height: 0;
			border-bottom: 0.3rem solid;
			transform: translateY(-50%);
		}

		&::before {
			transform: translateY(-50%) rotate(45deg);
		}

		&::after {
			transform: translateY(-50%) rotate(-45deg);
		}

		input[type="reset"] {
			padding: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			opacity: 0;
			text-indent: -999px;
			overflow: hidden;
			border: none;
		}
	}
}

/* main-nav */
.main-nav-block {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
	width: 43.4rem;
	background-color: $white;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	padding: 155px 6.2rem 30px;
	transform: translateX(-100%);
	transition: transform 0.3s;

	@include media-breakpoint-down(md) {
		display: none;
	}

	.main-nav {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 2.4rem;
		line-height: 1.25;
		font-weight: 500;

		li {
			margin-bottom: 1.9rem;
		}

		a {
			display: block;
			padding: 12px 11px;
			color: $blue;
			display: flex;
			border-radius: 99rem;
			align-items: center;

			&:not(:hover) {
				[class*=icon-] {
					&::before {
						color: inherit;
					}

					span {
						&::before {
							color: inherit;
						}
					}
				}
			}

			&:hover {
				color: $dark-blue;
				background-color: $xlight-gray;
				text-decoration: none;

				.icon-question-mark {
					&::before {
						color: inherit;
					}
				}
			}
		}

		[class*=icon-] {
			font-size: inherit;
			flex-shrink: 0;
			line-height: 1;
			min-width: 4rem;
			text-align: center;
			margin-right: 1.9rem;
			margin: -0.4rem 1.9rem -0.4rem 0;

			&::before {
				transition: $transition;
			}

			span {
				&::before {
					transition: $transition;
				}
			}
		}
	}

	.menu-opened & {
		transform: translateX(0);
	}
}

/* config-form */
.config-form {
	border-top: 0.1rem solid $xlight-gray4;
	margin: 1.6rem 0;
	padding: 3.6rem 0;

	label {
		display: inline-block;
		vertical-align: top;
		color: $blue;
		text-transform: uppercase;
		font-size: 1.8rem;
		line-height: 1.3333333333;
		padding: 0 0.4rem;
		letter-spacing: 0.05555555556em;
		margin-bottom: 2.7rem;
	}

	.search-field  {
		border: 1px solid $blue;
		color: $blue;

		.form-control {
			height: 5.2rem;
			padding: 1.1rem 10rem 1.1rem 7.1rem;
		}

		.align-left {
			position: absolute;
			top: 50%;
			left: 1.5rem;
			transform: translateY(-50%);
			font-size: 2.9rem;

			&::before {
				color: inherit;
			}
		}

		// &.filled-selected {
		// 	.form-control {
		// 		padding-right: 5rem;
		// 	}

		// 	.btn-submit-holder {
		// 		opacity: 0;
		// 		visibility: hidden;
		// 	}

		// 	.btn-clear {
		// 		border-right: none;
		// 		right: 0;
		// 	}
		// }
	}
}

.select-location {
	border: 1px solid;
	border-radius: 99rem;
	background-color: $white;
	display: flex;
	position: relative;
	color: $blue;
	font-size: 2.4rem;
	font-weight: 500;
	margin-bottom: 2.3rem;
	transition: all 0.3s ease-out;

	select {
		border: none;
		padding: 0 5rem 0 6.7rem;
		margin: 0;
		background: none;
		border-radius: 99rem;
		appearance: none;
		font-size: inherit;
		font-weight: inherit;
		height: 5.2rem;
		color: inherit;

		&::-ms-expand { display: none; }

		&:focus {
			color: inherit;
		}
	}

	[class*=icon-] {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: inherit;
		z-index: 5;
		pointer-events: none;

		&::before {
			color: inherit;
		}
	}

	.align-left {
		left: 1.7rem;
	}

	.align-right {
		right: 2.1rem;
	}

	&.filled {
		color: $dark-blue;
	}

	&:hover {
		color: $dark-blue;
	}
}

.fader {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 95;
	background-color: $dark-blue2;
	opacity: 0;
	visibility: hidden;
	transition: $transition;

	.menu-opened & {
		opacity: 0.7;
		visibility: visible;
	}
}


// /* header */
// #header {
// 	.nav-block {
// 		background-color: $white;
// 		height: 100vh;
// 		z-index: 10;
// 		padding: 15.5rem 6.1rem 6.1rem 6.5rem;
// 		display: none;
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		.menu-opened & {
// 			display: block;
// 		}
// 	}
// 	.main-nav {
// 		display: -webkit-flex;
// 		display: -ms-flex;
// 		display: flex;
// 		flex-direction: column;
// 		width: 31rem;
// 		margin-bottom: 3.2rem;
// 		ul {
// 			display: -webkit-flex;
// 			display: -ms-flex;
// 			display: flex;
// 			flex-direction: column;
// 			list-style: none;
// 			color: $blue;
// 			padding: 0;
// 			margin: 0;
// 			padding-bottom: 1.8rem;
// 			border-bottom: 1px solid $gray;
// 			li {
// 				margin-bottom: 1.1rem;
// 				font-size: 2.4rem;
// 				font-weight: bold;
// 			}
// 			a {
// 				display: -webkit-flex;
// 				display: -ms-flex;
// 				display: flex;
// 				width: 100%;
// 				justify-content: flex-start;
// 				align-items: center;
// 				padding: 1.2rem 2rem;
// 				padding-left: 0.9rem;
// 				border-radius: 4.2rem;
// 				span {
// 					margin-left: 2.4rem;
// 				}
// 				figure {
// 					width: 4.2rem;
// 					display: -webkit-flex;
// 					display: -ms-flex;
// 					display: flex;
// 					justify-content: center;
// 					margin: 0;
// 				}
// 				&:hover {
// 					background: $xlight-gray;
// 					color: $dark-blue;
// 					.svg-html path {
// 						fill: $dark-blue;
// 					}
// 					figure.gasstations .svg-html {
// 						#Path_7 path {
// 							fill: $yellow;
// 						}
// 						#Path_1 path {
// 							fill: $brown;
// 						}
// 						#Path_3 path {
// 							fill: $deep-blue;
// 						}
// 						#Path_4 path {
// 							fill: $middle-gray;
// 						}
// 						#Path_5 path {
// 							fill: $green;
// 						}
// 						#Path_2, #Path_6 {
// 							fill: $white;
// 						}
// 					}

// 				}
// 			}

// 		}
// 	}
// 	.search-box  {
// 		position: relative;
// 		width: 31rem;
// 		h5 {
// 			text-transform: uppercase;
// 			color: $blue;
// 			font-weight: bold;
// 			margin-bottom: 2.5rem;
// 			margin-left: 1rem;
// 		}
// 		.search-city,
// 		.select-region {
// 			position: relative;
// 			.svg-html {
// 				position: absolute;
// 				left: 2rem;
// 				top: 1.6rem;
// 			}
// 			&:hover {
// 				.svg-html g {
// 					stroke: $dark-blue;
// 				}
// 			}
// 		}
// 		.select-region {
// 			margin-bottom: 2rem;
// 		}
// 		select,
// 		input[type=search] {
// 			padding: 0.6rem 2rem 0.9rem;
// 			border-radius: 42px;
// 			border: 1px solid $blue;
// 			width: 100%;
// 			appearance: none;
// 			padding-left: 6.9rem;
// 			font-size: 2.4rem;
// 			font-weight: bold;
// 			color: $blue;
// 			cursor: pointer;
// 			&:focus {
// 				outline: none;
// 			}
// 		}

// 		.select-region{
// 			position: relative;
// 			&:after {
// 				content: '';
// 				position: absolute;
// 				padding: 0.7rem;
// 				border: solid $blue;
// 				border-width: 0 3px 3px 0;
// 				transform: rotate(45deg);
// 				transition: all .3s ease-in;
// 				top: 15px;
// 				right: 26px;
// 				pointer-events: none;
// 			}
// 			&:hover {
// 				.svg-html g {
// 					stroke: $dark-blue;
// 				}
// 				select {
// 					color: $dark-blue;
// 				}
// 			}
// 			&.select-focus {
// 				&:after {
// 					transform: rotate(225deg);
// 				}
// 			}
// 		}
// 		input[type=search] {
// 			&::placeholder {
// 				color: rgba($blue, 0.5);
// 			}
// 			&:focus {
// 				outline: none;
// 				text-transform: capitalize;
// 				color: $dark-blue;
// 			}
// 			&::-webkit-search-cancel-button {
// 				width: 2rem;
// 				height: 2rem;
// 				font-size: 2.5rem;
// 				position: relative;
// 				right: 3.5rem;
// 			}
// 		}

// 		.btn-search {
// 			padding: 0;
// 		}
// 		.btn-parent {
// 			position: absolute;
// 			z-index: 10;
// 			right: 1.7rem;
// 			top: 0.7rem;
// 			.btn-search {
// 				width: 0;
// 				height: 0;
// 			}
// 		}
// 		.btn-parent:after {
// 			content: "\f002";
// 			display: inline-block;
// 			font-style: normal;
// 			font-variant: normal;
// 			text-rendering: auto;
// 			-webkit-font-smoothing: antialiased;
// 			font-family: "Font Awesome 5 Free";
// 			font-weight: 900;
// 			font-size: 2.7rem;
// 			color: $blue;
// 			transform: rotate(90deg);
// 			cursor: pointer;
// 		}
// 	}
// 	.bottom-bar {
// 		display: none;
// 	}
// }

// @include media-breakpoint-down(sm) {
// 	#header {
// 		justify-content: center;
// 		padding-top: 6.4rem;
// 		.search-residue {
// 			width: 92%;
// 			margin: 0;
// 			input[type="search"] {
// 				padding: 1.2rem 2.3rem 0.9rem;
// 			}
// 			.btn-parent {
// 				right: 2rem;
// 				top: 0.9rem;
// 			}
// 		}
// 		.header-link {
// 			display: none;
// 		}
// 		.bottom-bar {
// 			display: block;
// 			position: fixed;
// 			z-index: 30;
// 			padding: 5px 10px;
// 			bottom: 43px;
// 			width: 71%;
// 			border-radius: 43px;
// 			background-color: $white;
// 			ul {
// 				padding: 0;
// 				margin: 0;
// 				list-style: none;
// 				display: -webkit-flex;
// 				display: -ms-flex;
// 				display: flex;
// 				align-items: center;
// 				justify-content: space-between;
// 				li {
// 					padding: 0.4rem 2rem 0.8rem;
// 					width: 23%;
// 					border-radius: 3.5rem;
// 					// background-color: $xlight-gray;
// 					display: -webkit-flex;
// 					display: -ms-flex;
// 					display: flex;
// 					justify-content: center;
// 					align-items: center;
// 					figure {
// 						margin-bottom: 0;
// 					}
// 					&:hover {
// 						background-color: $xlight-gray;
// 						.svg-html path {
// 							fill: $dark-blue;
// 						}
// 						figure.gasstations .svg-html {
// 							#Path_7 path {
// 								fill: $yellow;
// 							}
// 							#Path_1 path {
// 								fill: $brown;
// 							}
// 							#Path_3 path {
// 								fill: $deep-blue;
// 							}
// 							#Path_4 path {
// 								fill: $middle-gray;
// 							}
// 							#Path_5 path {
// 								fill: $green;
// 							}
// 							#Path_2, #Path_6 {
// 								fill: $white;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 		.open-btm-menu {
// 			display: none;
// 			position: fixed;
// 			z-index: 30;
// 			top: 50%;
// 			width: 80%;
// 			left: 50%;
// 			transform: translate(-50%, -50%);
// 			background: $white;
// 			padding: 2rem;
// 			color: $blue;
// 			font-size: 2.4rem;
// 			font-weight: bold;
// 			&.block-opened {
// 				display: block;
// 			}
// 			.question {
// 				display: -webkit-flex;
// 				display: -ms-flex;
// 				display: flex;
// 				padding-bottom: 2rem;
// 				margin-bottom: 2rem;
// 				border-bottom: 1px solid $middle-gray;
// 				span {
// 					margin-left: 3rem;
// 				}
// 			}
// 			.search-box-btm {
// 				width: 100%;
// 			}
// 			.search-box {
// 				input[type=search] {
// 					&::placeholder {
// 						color: rgba($blue, 0.5);
// 					}
// 					&:focus {
// 						outline: none;
// 						text-transform: capitalize;
// 						color: $dark-blue;
// 					}
// 					&::-webkit-search-cancel-button {
// 						width: 2rem;
// 						height: 2rem;
// 						font-size: 2.5rem;
// 						position: relative;
// 						right: 3.5rem;
// 					}
// 				}

// 				.btn-search-btm {
// 					padding: 0;
// 				}
// 				.btn-parent {
// 					position: absolute;
// 					z-index: 10;
// 					right: 1.7rem;
// 					top: 0.7rem;
// 					.btn-search-btm {
// 						width: 0;
// 						height: 0;
// 					}
// 				}
// 				.btn-parent:after {
// 					content: "\f002";
// 					display: inline-block;
// 					font-style: normal;
// 					font-variant: normal;
// 					text-rendering: auto;
// 					-webkit-font-smoothing: antialiased;
// 					font-family: "Font Awesome 5 Free";
// 					font-weight: 900;
// 					font-size: 2.7rem;
// 					color: $blue;
// 					transform: rotate(90deg);
// 					cursor: pointer;
// 				}
// 			}
// 		}
// 	}

// }
// .fader {
// 	position: fixed;
// 	z-index: 5;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	background: rgba($black, .6);
// 	visibility: hidden;
// 	opacity: 0;
// 	transition: opacity .3s ease-out, visibility .3s ease-out;
// }
// .fader {
// 	.menu-opened & {
// 		visibility: visible;
// 		opacity: 1;
// 		z-index: 5;
// 	}
// 	.fader-active & {
// 		visibility: visible;
// 		opacity: 1;
// 	}
// }

.active-search {
	@include media-breakpoint-down(md) {
		.fader {
			opacity: 0.7;
			visibility: visible;
		}
	}
}