/* map-area */
.map-area {
	width: 100%;
	height: 100vh;

	.map-block {
		width: 100%;
		height: 100%;

		@include media-breakpoint-down(lg) {
			height: calc(100% + 20rem);
		}

		@include media-breakpoint-down(md) {
			transition: filter 0.3s ease-out;

			.open-filter & {
				filter: blur(3px);
			}
		}
	}
}

/* map-menu  */
.map-menu {
	position: fixed;
	left: 4.7rem;
	bottom: 4.2rem;
	display: flex;
	z-index: 10;

	@include media-breakpoint-down(xl) {
		//top: 15rem;
		left: 4rem;
		bottom: 13rem;
	}

	@include media-breakpoint-down(lg) {
		bottom: 4.5rem;

		& ~ #footer {
			display: none;
		}
	}

	@include media-breakpoint-down(md) {
		top: 8rem;
		bottom: auto;
		left: auto;
		right: 16px;
		z-index: 110;
	}

	.map-overlay {
		display: none;

		@include media-breakpoint-down(md) {
			display: block;
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 10;
			background-color: $dark-blue;
			opacity: 0;
			visibility: hidden;
			transition: $transition;
			cursor: pointer;

			.open-filter & {
				opacity: 0.5;
				visibility: visible;
			}
		}
	}

	.opener {
		background-color: $white;
		width: 5.4rem;
		height: 5.4rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		color: $blue;
		font-size: 3.4rem;
		position: relative;

		@include media-breakpoint-down(md) {
			transition: filter 0.3s ease-out;

			.open-filter & {
				filter: blur(3px);
			}
		}

		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 1;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background-color: $white;
			transform: translate(-50%, -50%);
			transition: all 0.3s;
		}

		[class*='icon-'] {
			position: relative;
			z-index: 5;

			&::before {
				color: inherit;
			}

			span {
				&::before {
					color: inherit;
				}
			}
			.path1 {
				opacity: 0;
				visibility: hidden;
			}
		}

		&:hover {
			color: $dark-blue;

			&::before {
				transform: translate(-50%, -50%) scale(1.1851851852);
			}
		}
	}

	.drop {
		background-color: $white;
		border-radius: 2.7rem;
		width: 44.4rem;
		position: absolute;
		bottom: 100%;
		left: 0;
		list-style: none;
		padding: 1.4rem 0;
		margin: 0 0 1.7rem 0.3rem;
		transition: all 0.3s ease-out;
		opacity: 0;
		visibility: hidden;

		@include media-breakpoint-down(xxl) {
			//bottom: auto;
			//top: 100%;
			//margin-bottom: 0;
			//margin-top: 1.7rem;
		}

		@include media-breakpoint-down(md) {
			bottom: 0;
			top: auto;
			left: 0;
			right: 0;
			margin: 0;
			width: 100vw;
			border-radius: 2.7rem 2.7rem 0 0;
			position: fixed;
			z-index: 20;
		}

		.open-filter & {
			opacity: 1;
			visibility: visible;
		}

		li {
			position: relative;


			+ li {
				margin-top: 1rem;

				&::before {
					content: "";
					position: absolute;
					top: -0.4rem;
					left: 2.2rem;
					right: 2.2rem;
					height: 0;
					border-top: 0.1rem solid $xlight-gray5;
				}
			}
		}

		.holder {
			display: flex;
			align-items: center;
			padding: 1rem 2.2rem;
			color: $dark-blue3;
			font-size: 1.6rem;
			line-height: 1.7rem;
			font-weight: 500;
		}

		input {
			position: absolute;
			left: -9999px;
			opacity: 0;

			&:checked {
				& ~ .sign {
					background-color: $blue-3;
	
					&::before {
						left: 100%;
						margin-left: -1.8rem;
					}
				}
			}
		}

		.ico {
			width: 4rem;
			height: 4rem;
			border-radius: 50%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-right: 1rem;
			flex-grow: 0;
			flex-shrink: 0;
			font-size: 2.3rem;
			color: $blue-3;
			border: 0.2rem solid ;

			img {
				max-width: 80%;
				max-height: 80%;
				vertical-align: top;

				&.img-fullwidth {
					max-width: 100%;
					max-height: 100%;
				}
			}

			[class*='icon-'] {
				&::before {
					color: inherit;
				}

				span {
					&::before {
						color: inherit;
					}
				}
			}
		}

		.text {
			flex-grow: 1;

			strong {
				display: block;
				font-size: 2rem;
				font-weight: 700;
				line-height: 1;
			}

			span {
				display: block;
			}
		}

		.sign {
			width: 4rem;
			height: 2rem;
			border-radius: 99rem;
			position: relative;
			background-color: $xlight-gray3;
			flex-grow: 0;
			flex-shrink: 0;
			margin: 0 1.1rem;
			transition: background 0.3s;

			&::before {
				content: "";
				position: absolute;
				top: 0.2rem;
				left: 0.2rem;
				width: 1.6rem;
				height: 1.6rem;
				border-radius: 50%;
				background-color: $white;
				transition: left 0.3s;
			}
		}

		.active {
			.sign {
				background-color: $blue-3;

				&::before {
					left: 100%;
					margin-left: -1.8rem;
				}
			}
		}
	}
}
// map-labels
.map-labels {
	position: fixed;
	flex-wrap: wrap;
	left: 10rem;
	right: 10rem;
	bottom: 11rem;
	display: flex;
	justify-content: center;

	@include media-breakpoint-down(xl) {
		bottom: 12rem;
	}

	@include media-breakpoint-down(lg) {
		bottom: 3.5rem;
		left: 12rem;
		right: auto;
		max-width: calc(100% - 240px);
		justify-content: flex-start;
	}

	@include media-breakpoint-down(md) {
		display: none;
	}

	.label-el {
		margin: 0 0.5rem 1rem;
		background-color: $white;
		display: none;
		//display: flex;
		align-items: center;
		border-radius: 99rem;
		color: $dark-blue;
		font-size: 1.8rem;
		line-height: 2.2rem;
		font-weight: 500;
		padding: 0.8rem 2rem 0.8rem 0.8rem;
		//min-width: 24.4rem;

		&.active {
			display: flex;
		}

		.ico {
			width: 3.8rem;
			height: 3.8rem;
			border-radius: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex-grow: 0;
			flex-shrink: 0;
			margin-right: 0.6rem;
			color: $blue-3;
			font-size: 2.3rem;

			img {
				max-width: 80%;
				max-height: 80%;
				vertical-align: top;

				&.img-fullwidth {
					max-width: 100%;
					max-height: 100%;
				}
			}

			[class*='icon-'] {
				&::before {
					color: inherit;
				}

				span {
					&::before {
						color: inherit;
					}
				}
			}
		}
	}
}
// info-window
.info-window {
	width: 387px;
	background: $white;
	border-radius: 25px;
	padding: 21px 27px;
	color: $blue-4;
	font-family: $proximaNova;
	font-weight: 500;
	position: relative;

	@include media-breakpoint-down(lg) {
		width: 300px;
		padding: 20px;
		border-radius: 20px;
	}

	@include media-breakpoint-down(md) {
		width: 100%;
		border-radius: 10px;
	}

	@include media-breakpoint-down(sm) {
		padding-left: 15px;
		padding-right: 15px;
	}

	&-type2 {
		color: $dark-blue3;
	}

	&::before {
		position: absolute;
		content: '';
		width: 52px;
		height: 37px;
		left: 0;
		top: 100%;
		background: url('#{$images}arrow-info-window.svg') no-repeat;
		margin: -8px 0 0 38px;

		@include media-breakpoint-down(md) {
			left: 50%;
			margin-left: -26px;
		}
	}

	.info-window-top {
		font-size: 16px;
		line-height: 18px;
		padding: 0 0 10px;

		@include media-breakpoint-down(lg) {
			font-size: 14px;
			padding-bottom: 5px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 12px;
		}

		.ttl {
			display: block;
			text-transform: uppercase;
			font-weight: bold;
			color: $dark-blue;
			margin: 0 0 15px;
			font-size: 22px;
			line-height: 1.2;
			padding-right: 20px;

			@include media-breakpoint-down(lg) {
				font-size: 20px;
				margin-bottom: 10px;
			}

			@include media-breakpoint-down(md) {
				font-size: 16px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 14px;
			}
		}

		p {
			margin: 0 0 15px;
		}
	}

	.info-window-bottom {
		border-top: 1px solid $xlight-gray5;
		padding: 22px 0 5px;
		display: flex;
		align-items: center;
		font-size: 18px;
		line-height: 1;

		@include media-breakpoint-down(lg) {
			font-size: 14px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 12px;
		}

		.icon {
			flex-shrink: 0;
			width: 53px;
			height: 53px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			border: 2px solid;
			margin-right: 14px;
			padding: 5px;

			@include media-breakpoint-down(lg) {
				width: 50px;
				height: 50px;
			}

			@include media-breakpoint-down(sm) {
				width: 40px;
				height: 40px;
			}

			img {
				display: block;
				max-width: 100%;
				height: auto;
				border-radius: 50%;
				//min-width: 51px;
			}
		}
	}
}

.gm-style-iw-c {
	background: none !important;
	padding: 0 !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	max-height: none !important;
	overflow: visible !important;
	transform: translate(-16%, -110%) !important;

	@include media-breakpoint-down(md) {
		transform: translate(-50%, -110%) !important;
	}

	> button {
		top: 10px !important;
		right: 10px !important;

		@include media-breakpoint-down(md) {
			top: 5px !important;
			right: 5px !important;
		}

		img {
			width: 20px !important;
			height: 20px !important;
			margin: 5px !important;
		}
	}
}

.gm-style-iw-t {
	&::after {
		display: none !important;
	}
}

.gm-style-iw-d {
	overflow: visible !important;
	max-height: none !important;
}
// gm-style
.gm-style {
	> div {
		> div {
			> div:nth-child(3) {
				&::before {
					content: '';
					position: absolute;
					top: -9999px;
					right: -9999px;
					left: -9999px;
					bottom: -9999px;
					background: $blue-4;
					z-index: -1;
					opacity: 0.5;
					mix-blend-mode: multiply;
				}
			}
		}
	}
}