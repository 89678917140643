@import "_vars";
// @import "fontawesome/fontawesome";
// @import "fontawesome/brands";
// @import "fontawesome/solid";
// @import "fontawesome/regular";
@import "icomoon/_icomoon.scss";

@font-face {
	font-family: 'ProximaNova';
	src: url('#{$fonts}subset-ProximaNova-Semibold.woff2') format('woff2'),
		 url('#{$fonts}subset-ProximaNova-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('#{$fonts}subset-ProximaNova-Medium.woff2') format('woff2'),
		 url('#{$fonts}subset-ProximaNova-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('#{$fonts}proxima_nova_extrabold.woff2') format('woff2'),
		 url('#{$fonts}proxima_nova_extrabold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('#{$fonts}ProximaNova-Bold.woff2') format('woff2'),
		 url('#{$fonts}ProximaNova-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

// dev2
// dev3
// dev4
