/* buttons */
.btn {
	white-space: normal;
	border-radius: 99rem;
	border: none;
	font-size: 2.1rem;
	line-height: 1.4285714286;
	letter-spacing: 0;
	padding: 0.8rem 2.1rem;
	font-weight: 500;

	.fa {
		margin-left: 5px;
	}

	&-block {
		
	}

	&-sm {
		
	}

	&-lg {
		
	}
}

.btn-secondary {
	color: $light-blue;
	background-color: $xlight-gray2;

	&:active,
	&:focus,
	&:hover {
		background-color: $xlight-gray3;
		color: $dark-blue2;
	}
}

// dev2
.btn-upload {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5.4rem;
	height: 5.4rem;
	border-radius: 50%;
	//background: $white url('#{$images}ico-upload.svg') no-repeat 50% 50%;
	position: relative;
	color: $blue;

	&::before {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 50%;
		background: $white;
		transition: all 0.3s ease-out;
	}

	&::after {
		position: absolute;
		content: $icon-upload;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-family: "icomoon" !important;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		font-size: 2.8rem;
		z-index: 3;
	}

	&:hover {
		color: $dark-blue;

		&::before {
			left: -0.5rem;
			top: -0.5rem;
			right: -0.5rem;
			bottom: -0.5rem;
		}
	}

	@include media-breakpoint-down(md) {
		background-size: 20px auto;

		&.type2-sm {
			color: $white;
			//background: $blue-3 url('#{$images}ico-upload-white.svg') no-repeat 50% 50%;
			&::before {
				background: $blue-3;
			}

			&:hover {
				color: $white;
			}
		}
	}
}
// btn-location
.btn-location {
	display: flex;
	align-items: center;
	width: 171px;
	border: 1px solid $white;
	border-radius: 99px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1.8rem;
	line-height: 1;
	padding: 5px 30px 5px 23px;
	color: $white;
	text-decoration: none !important;
	position: relative;
	min-height: 54px;

	@include media-breakpoint-down(md) {
		font-size: 14px;
		line-height: 16px;
		min-height: 48px;
		width: 135px;
		padding-left: 15px;
	}

	&::before {
		position: absolute;
		content: $icon-map-pin;
		//width: 24px;
		//height: 33px;
		//background: url('#{$images}ico-loc.svg') no-repeat;
		right: 10px;
		top: 50%;
		transform: translate(0, -50%);
		font-family: "icomoon" !important;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		font-size: 3.2rem;

		@include media-breakpoint-down(md) {
			font-size: 28px;
		}
	}

	&:hover {
		background: $white;
	}
}